import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { BLUE, CLEAR_BLUE, WHITE } from "../../constants/cts_colors";
import i18next from "i18next";
import { MOBILE_WIDTH } from "../../constants/cts_sizes";
import RoundedButton from "../../components/RoundedButton";
import PassButton from "../../components/PassButton";
import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";
import { IoMdAdd } from "react-icons/io";
import { GoPerson } from "react-icons/go";
import { useAuthentication } from "../../common/contexts/authenticationContext";
import { useUser } from "../../common/contexts/userContext";
import { useNavigate } from "react-router-dom";
import PATH from "../../constants/cts_routes";
import ob1 from "../../assets/images/onBoarding/ob_1.jpg";
import ob2 from "../../assets/images/onBoarding/ob_2.jpg";
import ob3 from "../../assets/images/onBoarding/ob_3.jpg";
import ob4 from "../../assets/images/onBoarding/ob_4.jpg";
// import ob1 from "../../assets/images/onBoarding/ob_1.jpg";

const OnBoarding = () => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  // --- USER ---
  const { onChangeProfileImage, onEditOnBoarding } = useUser();
  const { user } = useAuthentication();

  // PROFILE IMAGE
  const [newProfileImage, _setNewProfileImage] = useState<File | null>(null);

  // TOUCH EVENTS
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const minSwipeDistance = 50;

  // STAGES
  let [actualStage, _setActualStage] = useState<number>(0);

  // ON BOARDING STAGES INFOS
  const onBoardingStages = [
    {
      title: "Du e-learning de qualité",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      library: false,
      profile: false,
      image: ob1,
    },
    {
      title: "Un suivi personnalisé",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      library: false,
      profile: false,
      image: ob2,
    },
    {
      title: "Une bibliothèque de contenus",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      library: true,
      profile: false,
      image: ob3,
    },
    {
      title: "Une communauté bienveillante",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      library: false,
      profile: true,
      image: ob4,
    },
  ];

  // --- USE EFFECTS ---
  useEffect(() => {
    document.title = t("title", { ns: "onBoardingPage" });
  }, [i18next.language]);

  useEffect(() => {
    // console.log("NEW ", newProfileImage);
    if (newProfileImage) changeProfileImage();
  }, [newProfileImage]);

  // --- FUNCTIONS ---
  // CHANGE STAGE
  const handleChangeStage = (arrowType: string) => {
    arrowType === "left"
      ? _setActualStage(actualStage - 1)
      : _setActualStage(actualStage + 1);
  };

  // RESPONSIVE TOUCH EVENTS
  const onTouchStart = (e: any) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e: any) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    if (isLeftSwipe && actualStage < onBoardingStages.length - 1)
      handleChangeStage("right");
    if (isRightSwipe && actualStage > 0) handleChangeStage("left");
  };

  // CHANGE PROFILE IMAGE
  const changeProfileImage = () => {
    if (newProfileImage) {
      // console.log("SEND PROFILE IMAGE", newProfileImage);
      onChangeProfileImage({ userId: user.id, newProfileImage });
    }
  };

  // PASS BUTTON
  const onPassOnBoarding = () => {
    onEditOnBoarding({ userId: user.id, onBoarding: true })
      .then(() => {
        // redirect to home
        navigate('/'+i18n.language+'/'+PATH.home);
      })
      .catch((error) => console.error(error));
  };

  return (
    <Wrapper>
      {/* <img src={ob1} /> */}
      {/* IMAGES BACKGROUND */}
      <BackgroundCarousel
        style={{
          backgroundImage: `url(${onBoardingStages[actualStage].image})`,
        }}
      ></BackgroundCarousel>

      {/* ON BOARDING */}
      <OnBoardingBackground
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        {/* LES CLEFS LOGO */}
        {/* <OnBoardingLogo>logo</OnBoardingLogo> */}

        {/* MIDDLE DIV */}
        <OnBoardingInfos>
          {/* LEFT */}
          <LeftArrow>
            {(actualStage > 0 && (
              <button
                onClick={() => {
                  handleChangeStage("left");
                }}
              >
                <RiArrowLeftSLine color={BLUE} size={32} />
              </button>
            )) || <ArrowPlaceholder />}
          </LeftArrow>

          {/* MIDDLE */}
          <div>
            {/* DOTS */}
            <DotsDiv>
              {onBoardingStages.map((stage, key) => {
                return (
                  <div
                    key={key}
                    className={actualStage === key ? "active" : ""}
                  ></div>
                );
              })}
            </DotsDiv>

            {onBoardingStages.map((stage, key) => {
              return (
                actualStage === key && (
                  <div key={key}>
                    {/* TEXT */}
                    <div>
                      <h3>{stage.title}</h3>
                      <p>{stage.content}</p>
                    </div>
                    {/* PROFILE PICTURE */}
                    {stage.profile && (
                      <ProfileDiv>
                        <h4>Ajouter votre photo pour compléter votre profil</h4>

                        <ImageContainer>
                          <ImageFrame>
                            {!newProfileImage ? (
                              user.image ? (
                                <img src={user.image} alt="profile" />
                              ) : (
                                <div className="profile">
                                  <GoPerson size={78} />
                                </div>
                              )
                            ) : (
                              <img src={URL.createObjectURL(newProfileImage)} />
                            )}
                          </ImageFrame>
                          <ChangeImage htmlFor="profileImage">
                            <IoMdAdd size={20} />
                          </ChangeImage>
                          <input
                            type="file"
                            hidden
                            id="profileImage"
                            onChange={(e) =>
                              _setNewProfileImage(
                                e.target.files && e.target.files.length > 0
                                  ? e.target.files[0]
                                  : null
                              )
                            }
                            multiple={false}
                          />
                        </ImageContainer>
                      </ProfileDiv>
                    )}
                  </div>
                )
              );
            })}

            {/* BUTTONS */}
            <ButtonsDiv>
              <RoundedButton
                text={
                  actualStage !== onBoardingStages.length - 1
                    ? "Continuer"
                    : "Commencer"
                }
                onClick={() => {
                  actualStage !== onBoardingStages.length - 1
                    ? handleChangeStage("right")
                    : onPassOnBoarding();
                }}
              />
              <PassButton text="Passer" onClick={onPassOnBoarding} />
            </ButtonsDiv>
          </div>

          {/* RIGHT */}
          <RightArrow>
            {(actualStage < onBoardingStages.length - 1 && (
              <button
                onClick={() => {
                  handleChangeStage("right");
                }}
              >
                <RiArrowRightSLine color={BLUE} size={32} />
              </button>
            )) || <ArrowPlaceholder />}
          </RightArrow>
        </OnBoardingInfos>
      </OnBoardingBackground>
    </Wrapper>
  );
};

export default OnBoarding;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  overflow: none;
`;

const BackgroundCarousel = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 250ms;
  overflow: hidden;

  @media (max-width: ${MOBILE_WIDTH}px) {
    background-position: left;
  }
`;

const OnBoardingBackground = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  overflow: auto;
`;

const OnBoardingLogo = styled.div`
  margin: auto;
`;

const OnBoardingInfos = styled.div`
  background-color: ${CLEAR_BLUE};
  border-radius: 16px;
  padding: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
  text-align: center;
  max-width: 40%;
  margin: auto auto;
  transition: all 250ms;

  @media (max-width: 1045px) {
    max-width: 55%;
  }

  @media (max-width: ${MOBILE_WIDTH}px) {
    background-color: rgba(0, 0, 0, 0);
    align-items: flex-end;
    height: 100%;
    width: 100%;
    min-width: 50%;
    padding: 0px;
  }

  h3 {
    font-weight: bold;
    font-size: 24px;
    transition: all 250ms;
    line-height: 1;
    margin-bottom: 8px;

    @media (max-width: ${MOBILE_WIDTH}px) {
      color: ${WHITE};
    }
  }

  p {
    max-width: 350px;
    line-height: 1;
    font-weight: 300;
    transition: all 250ms;

    @media (max-width: ${MOBILE_WIDTH}px) {
      color: ${WHITE};
    }
  }

  :nth-child(1) {
    padding-right: 24px;
    @media (max-width: ${MOBILE_WIDTH}px) {
      padding-right: 0px;
    }
  }

  :nth-child(3) {
    padding-left: 24px;
    @media (max-width: ${MOBILE_WIDTH}px) {
      padding-left: 0px;
    }
  }
`;

const DotsDiv = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;

  div {
    background-color: ${BLUE};
    transition: all 250ms;

    @media (max-width: ${MOBILE_WIDTH}px) {
      background-color: ${WHITE};
    }

    &.active {
      opacity: 1;
    }

    opacity: 0.2;
    width: 8px;
    height: 8px;
    border-radius: 100%;
  }
`;

const ProfileDiv = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  h4 {
    font-size: 16px;
    font-weight: bold;
    color: ${BLUE};
    line-height: 1;
  }
`;

const ImageContainer = styled.div`
  margin-top: 32px;
  position: relative;
  min-width: 80px;
  max-width: 112px;
  border-radius: 50%;
  background-color: ${CLEAR_BLUE};

  .profile {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background-color: ${WHITE};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const ImageFrame = styled.div`
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;

    // prevent dragging
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
`;

const ChangeImage = styled.label`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 28px;
  height: 28px;
  border: 1px solid ${BLUE};
  color: ${WHITE};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${BLUE};
  cursor: pointer;
  transition: 250ms background-color;

  &:hover {
    background-color: ${WHITE};
    color: ${BLUE};
  }

  img {
    display: block;
    width: 20px;
    height: 20px;

    // prevent dragging
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    // shadow
    -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
`;

const LeftArrow = styled.div`
  @media (max-width: ${MOBILE_WIDTH}px) {
    display: none;
  }

  button {
  }

  display: block;
  margin-right: 24px;
`;

const RightArrow = styled.div`
  @media (max-width: ${MOBILE_WIDTH}px) {
    display: none;
  }

  display: block;
  margin-left: 24px;
`;

const ArrowPlaceholder = styled.div`
  width: 32px;
  height: 32px;
`;

const ButtonsDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px 24px 16px 24px;

  @media (max-width: 1045px) {
    padding: 32px 0px 16px 0px;
  }

  @media (max-width: ${MOBILE_WIDTH}px) {
    padding: 32px 0px;
  }
`;
