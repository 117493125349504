import React, { useState, createContext, useCallback, useEffect } from "react";
import { IIaContext, IIaModel, IIaProfil, IIaUser } from "../../interfaces/ia";
import {
  completeMyProfilApi,
  converseApi,
  getQuestionApi,
  onAISignInApi,
  onAskMeProfilApi,
  onLoadProfilsApi,
  onModelListApi,
} from "../../api/ia.api";

const IaContext = createContext(null);

export function IaProvider(props: any) {
  const [iaUser, setIaUser] = useState<IIaUser | null>(null);
  const [modelList, setModelList] = useState<IIaModel[]>([]);
  const [profilList, setProfilList] = useState<IIaProfil[]>([]);
  const [modelSelected, setModelSelected] = useState<IIaModel | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const onAiSignIn = useCallback(async (email: string) => {
    setIsLoading(true);
    const user = await onAISignInApi(email);
    setIaUser(user);
    setIsLoading(false);
    return user;
  }, []);

  const onModelList = useCallback(async () => {
    setIsLoading(true);
    const list = await onModelListApi();
    setModelList(list);
    setIsLoading(false);
    return list;
  }, []);

  const onLoadProfils = useCallback(async (userId: number, modelId: number) => {
    setIsLoading(true);
    const list = await onLoadProfilsApi(userId, modelId);
    setProfilList(list);
    setIsLoading(false);
    return list;
  }, []);

  const onAskMeProfil = useCallback(async () => {
    setIsLoading(true);
    let response = null;
    if (iaUser && modelSelected) {
      response = await onAskMeProfilApi(iaUser.id, modelSelected.id);
    }
    setIsLoading(false);
    return response;
  }, [iaUser, modelSelected]);

  const completeMyProfil = useCallback(
    async (question: string, message: string) => {
      setIsLoading(true);
      let response = null;
      if (iaUser && modelSelected) {
        response = await completeMyProfilApi(
          iaUser.id,
          modelSelected.id,
          question,
          message
        );
      }
      setIsLoading(false);
      return response;
    },
    [iaUser, modelSelected]
  );

  const converse = useCallback(
    async (question: string, message: string) => {
      setIsLoading(true);
      let response = null;
      if (iaUser && modelSelected) {
        response = await converseApi(
          iaUser.id,
          modelSelected.id,
          question,
          message
        );
      }
      setIsLoading(false);
      return response;
    },
    [iaUser, modelSelected]
  );

  const getQuestion = useCallback(async () => {
    setIsLoading(true);
    let response = null;
    if (iaUser && modelSelected) {
      response = await getQuestionApi(iaUser.id, modelSelected.id);
    }
    setIsLoading(false);
    return response;
  }, [iaUser, modelSelected]);

  useEffect(() => {
    if (iaUser) {
      onModelList();
    }
  }, [iaUser]);

  useEffect(() => {
    setProfilList([]);

    if (iaUser && modelSelected) {
      onLoadProfils(iaUser.id, modelSelected.id);
    }
  }, [iaUser, modelSelected]);

  return (
    <IaContext.Provider
      {...props}
      value={{
        isLoading,
        modelSelected,
        iaUser,
        modelList,
        profilList,
        setModelSelected,
        onAiSignIn,
        onAskMeProfil,
        completeMyProfil,
        converse,
        getQuestion,
      }}
    />
  );
}

export const useIa = (): IIaContext => {
  const context = React.useContext(IaContext);
  if (!context) throw new Error("useAi must be used in IaProvider");

  return context;
};
