import React, { useState, useCallback, createContext } from "react";

import { useTranslation } from "react-i18next";
import { checkEmptyInput } from "../../utils/checkInputs";
import {
  EMPTY_FORMATION_TITLE,
  EMPTY_FORMATION_LANGUAGE,
  EMPTY_FORMATION_LINK,
} from "../../constants/cts_formErrors";
import {
  onCreateFormationApi,
  onDeleteFormationApi,
  onGetAllFormationsApi,
  onGetAllUserFormationsApi,
  onGetFormationApi,
  onUpdateFormationApi,
  onGetSearchFormationsApi,
  onGetTeachupTokenApi,
} from "../../api/formation.api";
import {
  IFormationsContext,
  IFormationsInfos,
} from "../../interfaces/formations";

const FormationsContext = createContext(null);

export function FormationsProvider(props: any) {
  const [isLoading, _setIsLoading] = useState(false);
  const { t } = useTranslation();

  const onCreateFormation = useCallback(
    ({ title, image, language, link, modules }: IFormationsInfos) => {
      if (!checkEmptyInput(title)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_FORMATION_TITLE}`, { ns: "errors" }));
        });
      }

      if (!checkEmptyInput(language)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_FORMATION_LANGUAGE}`, { ns: "errors" }));
        });
      }

      if (!checkEmptyInput(link)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_FORMATION_LINK}`, { ns: "errors" }));
        });
      }

      _setIsLoading(true);
      return onCreateFormationApi({
        title,
        image,
        language,
        link,
        modules,
      })
        .then((response: any) => {
          _setIsLoading(false);
          return response;
        })
        .catch((error: any) => {
          if (error.response) {
            throw new Error(error.response.data);
          } else {
            throw new Error(error.message);
          }
        })
        .then((response: any) => {
          _setIsLoading(false);
          return response;
        });
    },
    []
  );

  const onGetAllUserFormations = useCallback(() => {
    _setIsLoading(true);
    return onGetAllUserFormationsApi()
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      })
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      });
  }, []);

  const onGetAllFormations = useCallback(() => {
    _setIsLoading(true);
    return onGetAllFormationsApi()
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      })
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      });
  }, []);

  const onGetFormation = useCallback((id: number) => {
    _setIsLoading(true);
    return onGetFormationApi(id)
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  const onUpdateFormation = useCallback(
    ({
      id,
      title,
      image,
      language,
      link,
      modules,
    }: {
      id: number;
      title: string;
      image: string | null;
      language: string;
      link: string;
      modules: any[];
    }) => {
      if (!checkEmptyInput(title)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_FORMATION_TITLE}`, { ns: "errors" }));
        });
      }
      if (!checkEmptyInput(language)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_FORMATION_LANGUAGE}`, { ns: "errors" }));
        });
      }
      if (!checkEmptyInput(link)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_FORMATION_LINK}`, { ns: "errors" }));
        });
      }
      _setIsLoading(true);
      return onUpdateFormationApi({
        id,
        title,
        image,
        language,
        link,
        modules,
      })
        .then((response: any) => {
          _setIsLoading(false);
          return response;
        })
        .catch((error: any) => {
          if (error.response) {
            throw new Error(error.message.data);
          } else {
            throw new Error(error.message);
          }
        });
    },
    []
  );

  const onDeleteFormation = useCallback((id: number) => {
    _setIsLoading(true);
    return onDeleteFormationApi(id)
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  const onGetSearchFormations = useCallback((search: string) => {
    _setIsLoading(true);
    return onGetSearchFormationsApi(search)
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      })
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      });
  }, []);

  const onGetTeachupToken = useCallback(() => {
    _setIsLoading(true);
    return onGetTeachupTokenApi()
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      })
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      });
  }, []);

  return (
    <FormationsContext.Provider
      {...props}
      value={{
        isLoading,
        // function
        onCreateFormation,
        onGetAllFormations,
        onGetAllUserFormations,
        onGetFormation,
        onUpdateFormation,
        onDeleteFormation,
        onGetSearchFormations,
        onGetTeachupToken,
      }}
    />
  );
}

export const useFormations = (): IFormationsContext => {
  const context = React.useContext(FormationsContext);
  if (!context)
    throw new Error("useFormations must be used in FormationProvider");

  return context;
};
