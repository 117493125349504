import styled from "styled-components";
import { useIa } from "../../common/contexts/iaContext";
import Button from "../../components/Button";
import Input from "../../components/form/Input";
import { useEffect, useState } from "react";

const IaTrainingConversationComponent = () => {
  const { converse, getQuestion } = useIa();
  const [conversationList, setConversationList] = useState<
    { message: string; fromIA: boolean }[]
  >([]);
  const [responseToIA, setResponseToIA] = useState("");
  const [question, setQuestion] = useState<string | null>(null);

  const onSentResponse = async () => {
    if (!responseToIA) {
      alert("Vous devez saisir une réponse !");
      return;
    }

    const reponse = await converse(question || "", responseToIA);
    if (reponse) {
      setConversationList([
        ...conversationList,
        { message: responseToIA, fromIA: false },
        { message: reponse, fromIA: true },
      ]);
      setResponseToIA("");
    }
  };

  useEffect(() => {
    getQuestion().then(setQuestion);
  }, []);

  if (!question) {
    return <div>Chargement...</div>;
  }

  return (
    <Wrapper>
      <h1>
        <b>Conversation</b>
      </h1>
      <p>{question}</p>
      {conversationList.length !== 0 && (
        <ConversationZone>
          {conversationList.map((cs, index) => (
            <div key={index}>
              {cs.fromIA && (
                <p>
                  <b>Message du model</b>
                </p>
              )}
              {!cs.fromIA && (
                <p className="text-align-right">
                  <b>Message de vous</b>
                </p>
              )}
              {cs.message.split("\n").map((m, i) => (
                <p key={i} className={cs.fromIA ? "" : "text-align-right"}>
                  {m}
                </p>
              ))}
            </div>
          ))}
        </ConversationZone>
      )}
      <Input
        placeholder="Votre réponse"
        onChange={(e) => setResponseToIA(e.target.value)}
        value={responseToIA}
      />
      <Button text="Envoyer" onClick={onSentResponse} />
    </Wrapper>
  );
};

export default IaTrainingConversationComponent;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div`
  padding: 16px;
`;

const ConversationZone = styled.div`
  margin-top: 16px;
  padding-top: 8px;
  border-top: 1px solid black;
  margin-bottom: 32px;

  > div:first-child {
    margin-bottom: 16px;
  }
`;
