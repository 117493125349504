import React, { useState, useCallback, createContext } from "react";

import { useTranslation } from "react-i18next";
import { checkEmptyInput } from "../../utils/checkInputs";
import {
  EMPTY_MEMBER_FIRST_NAME,
  EMPTY_MEMBER_LAST_NAME,
  EMPTY_MEMBER_EMAIL,
  EMPTY_MEMBER_COMPANY,
  EMPTY_MEMBER_POSITION,
} from "../../constants/cts_formErrors";

import {
  onCreateMemberApi,
  onDeleteMemberApi,
  onGetAllMembersApi,
  onGetAllCommunityMembersApi,
  onGetAllAdminsApi,
  onGetMemberApi,
  onGetSearchMembersApi,
  onUpdateMemberApi,
  onSwitchToAdminAMember,
} from "../../api/member.api";
import { IMembersContext, IMembersInfos } from "../../interfaces/members";

const MembersContext = createContext(null);

export function MembersProvider(props: any) {
  const [isLoading, _setIsLoading] = useState(false);
  const { t } = useTranslation();

  const onCreateMember = useCallback(
    ({
      first_name,
      image,
      last_name,
      email,
      company,
      position,
      status,
      access_to_community,
      access_to_learning,
      access_to_events,
    }: IMembersInfos) => {
      if (!checkEmptyInput(first_name)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_MEMBER_FIRST_NAME}`, { ns: "errors" }));
        });
      }
      if (!checkEmptyInput(last_name)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_MEMBER_LAST_NAME}`, { ns: "errors" }));
        });
      }
      if (!checkEmptyInput(email)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_MEMBER_EMAIL}`, { ns: "errors" }));
        });
      }
      if (!checkEmptyInput(company)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_MEMBER_COMPANY}`, { ns: "errors" }));
        });
      }
      if (!checkEmptyInput(position)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_MEMBER_POSITION}`, { ns: "errors" }));
        });
      }

      _setIsLoading(true);
      return onCreateMemberApi({
        first_name,
        image,
        last_name,
        email,
        company,
        position,
        status,
        access_to_community,
        access_to_learning,
        access_to_events,
      })
        .then((response: any) => {
          _setIsLoading(false);
          return response;
        })
        .catch((error: any) => {
          if (error.response) {
            throw new Error(error.response.data);
          } else {
            throw new Error(error.message);
          }
        })
        .then((response: any) => {
          _setIsLoading(false);
          return response;
        });
    },
    []
  );

  const onGetAllAdmins = useCallback(() => {
    _setIsLoading(true);
    return onGetAllAdminsApi()
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      })
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      });
  }, []);

  const onGetAllMembers = useCallback(() => {
    _setIsLoading(true);
    return onGetAllMembersApi()
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      })
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      });
  }, []);

  const onGetAllCommunityMembers = useCallback(() => {
    _setIsLoading(true);
    return onGetAllCommunityMembersApi()
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      })
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      });
  }, []);

  const onGetMember = useCallback((id: number) => {
    // console.log("ON GET MEMBER : ", id);
    _setIsLoading(true);
    return onGetMemberApi(id)
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  const switchToAdminAMember = useCallback(
    ({ id, isAdmin }: { id: number; isAdmin: boolean }) => {
      _setIsLoading(true);
      return onSwitchToAdminAMember({
        id,
        isAdmin,
      })
        .then((response: any) => {
          _setIsLoading(false);
          return response;
        })
        .catch((error: any) => {
          if (error.response) {
            throw new Error(error.message.data);
          } else {
            throw new Error(error.message);
          }
        });
    },
    []
  );

  const onUpdateMember = useCallback(
    ({
      id,
      first_name,
      last_name,
      image,
      email,
      company,
      position,
      status,
      access_to_community,
      access_to_learning,
      access_to_events,
    }: {
      id: number;
      first_name: string;
      last_name: string;
      image: any;
      email: string;
      company: string;
      position: string;
      status: boolean;
      access_to_community: boolean;
      access_to_learning: boolean;
      access_to_events: boolean;
    }) => {
      if (!checkEmptyInput(first_name)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_MEMBER_FIRST_NAME}`, { ns: "errors" }));
        });
      }
      if (!checkEmptyInput(last_name)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_MEMBER_LAST_NAME}`, { ns: "errors" }));
        });
      }
      if (!checkEmptyInput(email)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_MEMBER_EMAIL}`, { ns: "errors" }));
        });
      }

      if (!checkEmptyInput(position)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_MEMBER_POSITION}`, { ns: "errors" }));
        });
      }
      _setIsLoading(true);
      return onUpdateMemberApi({
        id,
        first_name,
        image,
        last_name,
        email,
        company,
        position,
        status,
        access_to_community,
        access_to_learning,
        access_to_events,
      })
        .then((response: any) => {
          _setIsLoading(false);
          return response;
        })
        .catch((error: any) => {
          if (error.response) {
            throw new Error(error.message.data);
          } else {
            throw new Error(error.message);
          }
        });
    },
    []
  );

  const onDeleteMember = useCallback((id: number) => {
    _setIsLoading(true);
    return onDeleteMemberApi(id)
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  const onGetSearchMembers = useCallback((search: string) => {
    _setIsLoading(true);
    return onGetSearchMembersApi(search)
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      })
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      });
  }, []);

  return (
    <MembersContext.Provider
      {...props}
      value={{
        isLoading,
        // function
        onCreateMember,
        onGetAllMembers,
        onGetAllCommunityMembers,
        onGetMember,
        onUpdateMember,
        switchToAdminAMember,
        onDeleteMember,
        onGetSearchMembers,
        onGetAllAdmins,
      }}
    />
  );
}

export const useMembers = (): IMembersContext => {
  const context = React.useContext(MembersContext);
  if (!context) throw new Error("useMembers must be used in MemberProvider");

  return context;
};
