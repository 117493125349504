import React from "react";
import styled from "styled-components";
import { BLUE, WHITE } from "../constants/cts_colors";
import { MOBILE_WIDTH } from "../constants/cts_sizes";
const RoundedButton = ({
  text,
  disabled,
  onClick,
}: {
  text: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}) => {
  return (
    <RoundedButtonStyles disabled={disabled} onClick={onClick}>
      {text}
    </RoundedButtonStyles>
  );
};

export default RoundedButton;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const RoundedButtonStyles = styled.button`
  background-color: ${BLUE};
  color: ${WHITE};
  border-radius: 50px;
  padding: 13.5px;
  border: 1px solid ${WHITE};
  width: 100%;
  transition: 250ms;
  font-weight: bold;

  @media (max-width: ${MOBILE_WIDTH}px) {
    border: none;
  }

  :disabled {
    opacity: 0.5;
    :hover {
      background-color: ${BLUE};
      color: ${WHITE};
      border: 1px solid ${WHITE};

      @media (max-width: ${MOBILE_WIDTH}px) {
        border: none;
      }
    }
  }

  :hover {
    background-color: ${WHITE};
    border: 1px solid ${BLUE};
    color: ${BLUE};

    @media (max-width: ${MOBILE_WIDTH}px) {
      border: none;
    }
  }
`;
