import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getPageUrl } from "../locales/i18n";
import PATH from "../constants/cts_routes";
import { useAuthentication } from "../common/contexts/authenticationContext";
import {
  BLACK,
  BLUE,
  LIGHT_BLUE,
  LIGHT_GREY,
  WHITE,
} from "../constants/cts_colors";
import little_logo from "../assets/images/logo/logo-les-cles.png";
import { USER_ADMIN_ROLE } from "../constants/cts_user";
import { ImBooks } from "react-icons/im";
import { HiAcademicCap } from "react-icons/hi";
import { IoChatbubblesOutline } from "react-icons/io5";
import { GoHome } from "react-icons/go";
import { IoMdSettings } from "react-icons/io";
import { MdOutlineEvent } from "react-icons/md";

const Header = () => {
  const { user } = useAuthentication();
  const { t } = useTranslation();

  const [actualCategory, _setActualCategory] = useState<string>(
    window.location.pathname.split("/")[2]
  );

  useEffect(() => {
    _setActualCategory(window.location.pathname.split("/")[2]);
    // console.log(user);
  }, [window.location.pathname.split("/")[2]]);

  return (
    <Wrapper>
      <div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {/* <Link to={getPageUrl(PATH.home)}> */}
          <LogoContainer>
            <img src={little_logo} alt="Logo Les Clefs" />
          </LogoContainer>
          {/* </Link> */}
          <ItemContainer
            to={getPageUrl(PATH.home)}
            style={{
              backgroundColor: actualCategory === "dashboard" ? BLUE : WHITE,
            }}
          >
            <GoHome
              size={24}
              color={actualCategory === "dashboard" ? WHITE : BLACK}
            />
            <p
              style={{
                color: actualCategory === "dashboard" ? WHITE : BLACK,
              }}
            >
              {t("menu:home")}
            </p>
          </ItemContainer>
          <ItemContainer
            to={getPageUrl(PATH.library)}
            style={{
              backgroundColor: actualCategory === "library" ? BLUE : WHITE,
            }}
          >
            <ImBooks
              size={24}
              color={actualCategory === "library" ? WHITE : BLACK}
            />
            <p
              style={{
                color: actualCategory === "library" ? WHITE : BLACK,
              }}
            >
              {t("menu:library")}
            </p>
          </ItemContainer>
          {user && user.access_to_community && (
            <ItemContainer
              to={getPageUrl(PATH.community)}
              style={{
                backgroundColor: actualCategory === "community" ? BLUE : WHITE,
              }}
            >
              <IoChatbubblesOutline
                size={24}
                color={actualCategory === "community" ? WHITE : BLACK}
              />
              <p
                style={{
                  color: actualCategory === "community" ? WHITE : BLACK,
                }}
              >
                {t("menu:community")}
              </p>
            </ItemContainer>
          )}

          {user && user.access_to_learning && (
            <ItemContainer
              to={getPageUrl(PATH.teachup)}
              style={{
                backgroundColor: actualCategory === "teachup" ? BLUE : WHITE,
              }}
            >
              <HiAcademicCap
                size={24}
                color={actualCategory === "teachup" ? WHITE : BLACK}
              />
              <p
                style={{
                  color: actualCategory === "teachup" ? WHITE : BLACK,
                }}
              >
                {t("menu:academy")}
              </p>
            </ItemContainer>
          )}

          {user && user.access_to_events && (
            <ItemContainer
              to={getPageUrl(PATH.events)}
              style={{
                backgroundColor: actualCategory === "events" ? BLUE : WHITE,
              }}
            >
              <MdOutlineEvent
                size={24}
                color={actualCategory === "events" ? WHITE : BLACK}
              />
              <p
                style={{
                  color: actualCategory === "events" ? WHITE : BLACK,
                }}
              >
                {t("menu:events")}
              </p>
            </ItemContainer>
          )}

          {user && user?.role === USER_ADMIN_ROLE && (
            <ItemContainer
              to={getPageUrl(PATH.settings)}
              style={{
                backgroundColor: actualCategory === "settings" ? BLUE : WHITE,
              }}
            >
              <IoMdSettings
                size={24}
                color={actualCategory === "settings" ? WHITE : BLACK}
              />
              <p
                style={{
                  color: actualCategory === "settings" ? WHITE : BLACK,
                }}
              >
                Admin
              </p>
            </ItemContainer>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default Header;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.nav`
  width: 246px;

  > div {
    width: 246px;
    height: 100vh;
    background-color: ${WHITE};
    color: ${BLUE};
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: 1px solid ${LIGHT_GREY};
  }
`;

const LogoContainer = styled.div`
  margin: 40px 16px;

  img {
    height: 33px;
  }
`;

const ItemContainer = styled(Link)`
  padding: 16px;
  margin: 0 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 100px;

  :hover {
    background-color: ${LIGHT_BLUE};
  }

  p {
    margin-left: 16px;
    position: relative;
    top: 2px;
    color: ${BLACK};
  }
`;
