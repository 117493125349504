import axios from "axios";

// Axios instance
export const instanceAxios = axios.create({
  baseURL:
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "http://localhost:8081/api"
      : "/api",
});

export const httpError = (err: any) => {
  console.log("err", err);
  if (err.response) {
    throw new Error(err.response.data);
  } else {
    throw new Error(err.message);
  }
};
