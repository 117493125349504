import {
  SETTING_ALLOW_NEWSLETTERS,
  SETTING_ALLOW_POST,
  SETTING_ALLOW_RESOURCE,
  SETTING_ALLOW_TAGG,
} from "../constants/cts_settings";

export default {
  common: {
    multilingue: "Multilingual",
  },

  // alerts ----------------------------------------------------------------
  alerts: {
    // account
    pwdChanged: "Your password has been changed",
    accountEdited: "Your account informations had been changed",
    accountDeleted: "Your account has been deleted",
    // forms
    emptyInput: "Input(s) cannot be empty",
  },

  // confirm alerts
  confirmAlerts: {
    confirmDeleteAccount: "Do you want to delete your account?",
    confirmLogout: "Do you want to logout ?",
  },

  // info messages ---------------------------------------------------------
  infoMessages: {
    changeSaved: "The change has been saved",
    passwordChanged: "Your password has been changed",
  },

  // errors ----------------------------------------------------------------
  errors: {
    form: {
      // no match
      passwordNoMatch: "Passwords are not the same",
      // empty
      emptyPassword: "Please enter a password",
      emptyFirstName: "Please enter the first name",
      emptyLastName: "Please enter the last name",
      emptyFirstNameAndLastName: "Please enter the first name and last name",
      emptyToken: "Please enter the token",
      emptyContactMessage: "Please enter the message",
      emptyItemTitle: "Please enter the title",
      emptyItemDescription: "Please enter the description",
      emptyItemImage: "Please enter an image",
      emptyItemPrice: "Please enter the price",
      emptyItemStatus: "Please enter the status",
      // formations
      emptyFormationTitle: "Please enter a title",
      emptyFormationImage: "Please enter an image",
      emptyFormationLanguage: "Please enter a language",
      emptyFormationLink: "Please enter a link",
      // company
      emptyCompanyName: "Please enter a name",
      // members
      emptyMemberFirstName: "Please enter a first name",
      emptyMemberLastName: "Please enter a last name",
      emptyMemberEmail: "Please enter an email",
      emptyMemberCompany: "Please enter a company",
      emptyMemberPosition: "Please enter a position",
      // coachs
      emptyCoachFirstName: "Please enter a first name",
      emptyCoachLastName: "Please enter a last name",
      emptyCoachEmail: "Please enter an email",
      emptyCoachPhoneNumber: "Please enter a phone number",
      // groups
      emptyGroupName: "Please enter a name",
      emptyGroupMembers: "Please enter at least one member",
      // posts
      emptyPostContent: "Please enter a content for the post",
      // comments
      emptyCommentContent: "Please enter a contenu for the commentaries",
      // not valid
      notValidEmail: "Please enter a valid email (exemple@email.com)",
      notValidPassword:
        "Please enter a valid password (more than 6 characters)",
      notValidPhoneNumber: "Please enter a valid phone number (ex: 0612345678)",
    },
  },

  // complex translations --------------------------------------------------
  translation: {
    // reset password page
    resetPasswordPage: {
      form: {
        infos: {
          passwordChanged: `Your password has been changed. You can now <a href={{loginPath}}>connect</a> to your account.`,
        },
      },
    },
  },

  // simple translations ---------------------------------------------------
  timeUnits: {
    second: "second",
    minute: "minute",
    hour: "hour",
    day: "day",
    month: "month",
    year: "year",
    // multiple
    seconds: "seconds",
    minutes: "minutes",
    hours: "hours",
    days: "days",
    months: "months",
    years: "years",
  },

  // header
  header: {
    elements: {
      home: "Home",
      contact: "Contact",
      about: "About Us",
      legals: "Legals",
    },
    itemsLink: "Jobs",
    loginLink: "Log In",
    accountLink: "Account",
  },

  // settings header for admins
  settingsHeader: {
    users: "Users",
    members: "Members",
    groups: "Groups",
    coachs: "Coachs",
    companies: "Companies",
  },

  // Orderby settings translation
  orderBy: {
    sortBy: "Sort by",
    all: "All",
    firstName: "First Name",
    lastName: "Last Name",
    status: "Status",
    email: "Email",
    companies: "Company",
    position: "Position",
    phoneNumber: "Phone Number",
  },

  // footer
  footer: {
    elements: {
      aboutUs: "About us",
      legals: "Legal notices",
    },
  },

  // onboarding
  onBoardingPage: {
    title: "Les Clés - OnBoarding",
  },

  // login page
  loginPage: {
    title: "Les Clés - Log In",
    mainTitle: "Log In",
    description: "Sign in to your account",
    connectSSO: "Connecter to your account",
    welcome: "Welcome",
    privacyAndTerms: "Privacy & Terms",
    form: {
      labels: {
        email: "Email",
        password: "Password",
      },
      placeholders: {
        email: "Email",
        password: "Password",
      },
      links: {
        lostPassword: "Forgot your password ?",
        routeToLogin: "Don't have an account? Sign up here",
      },
    },
  },

  // signup page
  signupPage: {
    mainTitle: "SIGN UP",
    form: {
      labels: {
        firstName: "First Name",
        lastName: "Last Name",
        email: "Email",
        phoneNumber: "Phone number (optional)",
        password: "Password",
        repeatPassword: "Repeat password",
      },
      placeholders: {
        firstName: "First Name",
        lastName: "Last Name",
        email: "Email",
        phoneNumber: "Phone number (optional)",
        password: "Password",
        repeatPassword: "Repeat password",
      },
      links: {
        navigateToLogIn: "Already registered? Log in here",
      },
    },
  },

  // reset password page
  resetPasswordPage: {
    mainTitle: "Change password",
    form: {
      labels: {
        token: "Token",
        email: "Email",
        password: "Password",
        repeatPassword: "Repeat password",
      },
      placeholders: {
        token: "Token",
        email: "Email",
        password: "Password",
        repeatPassword: "Repeat password",
      },
    },
    passwordChangedInfo: {
      firstPartText: "Your password has been changed. You can now ",
      link: "connect",
      lastPartText: " to your account.",
    },
  },

  // lost password page
  lostPasswordPage: {
    title: "Les Clés - Password forgotten",
    mainTitle: "Forgot your password ?",
    send: "Send",
    redirectToLogInLink: "Back to login",
    form: {
      labels: {
        email: "Email",
      },
      placeholders: {
        email: "Email",
      },
      infos: {
        linkSent: "A link to reset your password has been sent to",
      },
    },
  },

  // topbar
  topbar: {
    profile: {
      editProfile: "Edit my profile",
      settings: "Settings",
      contact: "Contact support",
      logout: "Logout",
    },
    logoutModal: {
      text: "You will log out",
      buttonExitModal: "I stay connected",
      buttonConfirmLogout: "I log out",
    },
    favorites: {
      title: "Favorites",
    },
  },

  // account page
  accountPage: {
    pageTitle: "Les Clés - Your account",
    selectSection: {
      accountInfos: "Edit my profile",
      myFile: "My file",
    },
    accountInfos: {
      form: {
        labels: {
          name: "Full Name",
          email: "Email",
          password: "Password",
        },
        placeholders: {
          name: "Full Name",
          email: "Email address",
          password: "**********",
        },
        submitBtn: "Edit",
      },
      confirmNewProfileImage: {
        title: "Do you want to change your profile picture?",
        confirm: "Yes",
        cancel: "No",
      },
    },
    myFile: {
      form: {
        labels: {
          pitch: "Pitch",
          anecdote1: "Anecdote 1: My pleasures at work are",
          anecdote2: "Anecdote 2: The Key that I want to share",
          anecdote3: "Anecdote 3: To recharge my batteries, I like",
          position: "Job",
          linkedinProfileLink: "Insert your Linkedin profile link here",
        },
        placeholders: {
          pitch: "Pitch",
          anecdote1: "Text here",
          anecdote2: "Text here",
          anecdote3: "Text here",
          position: "Text here",
          linkedinProfileLink: "linkedin.com/username",
        },
        submitBtn: "Edit",
        saveBtn: "Save",
      },
    },
    changePasswordForm: {
      form: {
        labels: {
          password: "New password",
          repeatPassword: "Repeat the new password",
        },
        placeholders: {
          password: "New password",
          repeatPassword: "Repeat the new password",
        },
        submitBtn: "Change",
      },
    },
  },

  // account settings page
  accountSettingsPage: {
    title: "Activate notifications!",
    settings: {
      [SETTING_ALLOW_NEWSLETTERS]: {
        name: "I want to receive the newsletter",
      },
      [SETTING_ALLOW_POST]: {
        name: "I want to receive new podcasts",
      },
      [SETTING_ALLOW_RESOURCE]: {
        name: "I want to receive new resources",
      },
      [SETTING_ALLOW_TAGG]: {
        name: "I want to receive when I am tagged",
      },
    },
  },

  // legal notices page
  legalNoticesPage: {
    mainTitle: "Legal Notices",
  },

  // about page
  aboutPage: {
    mainTitle: "About us",
  },

  // contact page
  contactPage: {
    mainTitle: "CONTACT",
    form: {
      labels: {
        lastName: "Last Name",
        firstName: "First Name",
        email: "Email",
        phoneNumber: "Phone number (optional)",
        message: "Message",
      },
      placeholders: {
        lastName: "Last Name",
        firstName: "First Name",
        email: "Email",
        phoneNumber: "Phone number (optional)",
        message: "Your message...",
      },
      infos: {
        messageSent: "Your message has been sent",
      },
    },
  },

  // home page
  homePage: {
    mainTitle: "Les Clés - Dashboard",
    presentation: {
      title: {
        firstPart: "We Offer ",
        strongText: "25,000 Job",
        lastPart: " Vacancies Right Now!",
      },
      description:
        "The most complete field service software for IT & Mobile Support, Fire Services, Electrical, Maintenance, HVAC & Security Industries",
      button: "Sign Up Free",
      numbers: {
        users: "Users",
        downloads: "Downloads",
        likes: "Likes",
        rating: " 5 Star Rating",
      },
    },
    about: {
      title: {
        firstPart: "What Makes ",
        strongText: "Dear Job",
        lastPart: " Different?",
      },
      subtitle: "Your next level product development company assets",
      cards: {
        titles: {
          searchJob: "SEARCH A JOB",
          functional: "FULLY FUNCTIONAL",
          liveChat: "LIVE CHAT",
        },
      },
    },
    footer: {
      title: {
        strongText: "A COMPLETE FEATURE",
        lastPart: "STACK READY TO HELP YOU",
      },
      button: "Intro Demo Video",
    },
  },

  // circle page
  circlePage: {
    mainTitle: "Les Clés - Community",
    post: {
      write: "Write a post",
      updatePost: "Update a post",
      posted: "Posted on ",
      like: "Like",
      comment: "Comment",
      respond: "Answer",
      itWas: "ago",
      allComments: "All comments",
      send: "Publish",
      delete: "Delete",
      update: "Update",
      confirmDelete: "Do you want to delete this post ?",
      read: "Read",
      form: {
        labels: {
          dateToPublish: "Publication date :",
          tags: "All tags :",
          noTags: "No tag",
          selectedTags: "Selected Tags :",
          noTagsSelected: "No tag selected",
          noFiles: "No file",
          files: "Files:",
          companies: "Companies :",
          interSpan: "All companies",
        },
        placeholders: {
          title: "Title (optional)",
          addTag: "Add a tag",
        },
      },
    },
  },

  // community page
  communityPage: {
    mainTitle: "Les Clés - Community",
    community: "Community",
    titleAnnu: "Members",
    memberInfosSidebar: {
      linkedinBtn: "Connect on Linkedin",
      anecdotes: {
        anecdote1: "My pleasures at work are",
        anecdote2: "The Key that I want to share",
        anecdote3: "To recharge my batteries, I like",
        liked: "I like",
        notLiked: "Like this anecdote",
      },
      pitchTitle: "My pitch",
    },
  },

  // library page
  libraryPage: {
    mainTitle: "Les Clés - Library",
    podcasts: "Podcasts",
    resources: "Resources",
    back: "Back",
    explore: "Explore all",
    allEpisodes: "All episodes",
    listen: "Listen to this podcast",
  },

  // teachup page
  teachupPage: {
    mainTitle: "Les Clés - Academy",
    myeLearnings: "My e-learnings",
    formations: "E-learnings",
    journeyTracking: {
      title: "My tracking journey",
      downloadButton: "Download",
      downloadPDFName: "my_tracking_journey",
      downloadText: "Loading",
      placeholders: "• Enter your answers here",
      remember: "What I remember",
      implements: "What I want to implement",
      atelier: "Launch workshop : Self-confidence",
      module1: "Module 1 : Free myself from my beliefs",
      module2: "Module 2 : Setting my limits",
      module3: "Module 3 : Enhance myself as a manager",
      module4: "Module 4 : Building my journey",
      module5: "Module 5 : Talk about myself with impact",
      module6: "Module 6 : Create a network",
      noneModules: "None module",
    },
  },

  // favorite page
  favoritesPage: {
    mainTitle: "Les Clés - Favorites",
    podcastsFavoris: "Favorite Podcasts",
    resourcesFavoris: "Favorite Resources",
  },

  // settings page
  settingsPage: {
    mainTitle: "Les Clés - Settings",
    add: "Add",
    access: "Access",
    search: "Search",
    edit: "Edit",
    delete: "Delete",
    settings: "Administration",
    community: "Community",
    becomeAdmin: "Become administrator",
    formations: {
      noFormations: "No formations",
      confirmDelete: "Are you sur to delete this formations ",
    },
    members: {
      user: "User",
      status: "Status",
      email: "Email",
      company: "Compagny",
      poste: "Position",
      activity: "Activity",
      noMembers: "No members",
      access: "Access",
      confirmDelete: "Are you sur to delete ",
    },
    companies: {
      headerCompagnies: "Companies",
      headerMembers: "Members",
      noCompanies: "No companies",
      confirmDelete: "Are you sur to delete ",
      activity: "Activity",
      headerFormations: "Nb Formations",
      accessAdded: "Access added with success !",
      formation: "Add a formation",
      intraCompanies: "Intra Companies",
      interCompanies: "Inter Companies",
      allCompanies: "All Companies",
    },
    coachs: {
      user: "User",
      email: "Email",
      phoneNumber: "Phone number",
      noCoachs: "No coachs",
      confirmDelete: "Are you sur to delete ",
    },
    groups: {
      name: "Name",
      members: "Members",
      nbMembers: "Nb Members",
      nbFormations: "Nb Formations",
      activity: "Activity",
      noGroups: "No groups",
      confirmDelete: "Are you sur to delete ",
      accessAdded: "Access added with success !",
    },
    form: {
      nameFormulaireCreation: "Create a formation",
      nameFormulaireUpdate: "Update a formation",
      nameCompagniesCreation: "Create a company",
      nameCompagniesUpdate: "Update a company",
      nameMembersCreation: "Create a member",
      nameMembersUpdate: "Update a member",
      nameGroupsCreation: "Create a group",
      nameGroupsUpdate: "Update a groupe",
      nameCoachsCreation: "Create a coach",
      nameCoachsUpdate: "Update a coach",
      addAccess: "Add access",
      updateAccess: "Update access",
      labels: {
        title: "Title",
        image: "Image",
        language: "Language",
        link: "Link",
        name: "Name",
        first_name: "First Name",
        last_name: "Last Name",
        email: "Email",
        company: "Company",
        position: "Position",
        status: "Status",
        accessToCommunity: "Access to community",
        accessToFormations: "Access to learning",
        accessToEvents: "Access to events",
        phone_number: "Phone number",
        members: "Members",
        noMember: "No members",
        links: "Teachup Formation",
        module_title: "Module's name",
        left_subtitle: "Left's subtitle",
        right_subtitle: "Right's subtitle",
      },
      placeholders: {
        title: "Title",
        image: "Image",
        language: "Language",
        link: "Link",
        name: "Name",
        first_name: "First Name",
        last_name: "Last Name",
        email: "Email",
        company: "Company",
        position: "Position",
        status: "Status",
        phone_number: "Phone number",
        members: "Members",
        links: "Teachup Formation",
        is_intra: "Intra Company ?",
        module_title: "Module's name",
        left_subtitle: "Left's subtitle",
        right_subtitle: "Right's subtitle",
        formation: "Add a formation",
      },
      button: "Create",
      buttonUpdate: "Update",
      infos: {
        messageSent: "Formation created",
      },
    },
  },

  // items page
  itemsPage: {
    mainTitle: "JOBS",
    itemsButton: "APPLY NOW",
  },

  // Payment form
  paymentForm: {
    payButton: "Pay",
  },

  menu: {
    home: "Home",
    academy: "Academy",
    bord: "Dashboard",
    library: "Library",
    community: "Community",
    events: "Events",
  },

  // dashboard page
  dashboardPage: {
    mainTitle: "Les Clés - Home",
    podcasts: {
      title: "Podcasts & Resources",
      description: "Discover what's new in the Library",
      button: "See all",
    },
    post: {
      title: "Latest post in the Community",
      explore: "Explore all",
    },
  },

  eventsPage: {
    mainTitle: "Les Clés - Events",
    nextEvents: "Next events",
    presentiel: "In person",
    distance: "From a distance",
    registerEvent: "I register",
    quitEvent: "Unsubscribe",
    alreadyRegisteredEvent: "You are already registered for this event",
    fullEvent: "Full !",
    seeEvent: "See event",
    thanksForSubscription: "Thanks for your subscription !",
    rappelMail: "Confirmation will be sent to you by email.",
    addToAgenda: "Add to my calendar",
    newEvent: "NEW",
    post: {
      write: "Add an event",
      updatePost: "Update an event",
      form: {
        labels: {
          dateToPublish: "Publication date :",
          eventDate: "Event date :",
          noFiles: "No files",
          files: "Files :",
          nbUsers: "Max Subscribers Number",
          details: "Content for subscribers",
        },
        placeholders: {
          title: "Title (optionnal)",
          addTag: "Add a tag",
        },
      },
    },
    fullscreen: {
      details: "Details",
      usersViewByAdmin: "Users subscribed (vue admin)",
      users: "users",
    },
  },
};
