import styled from "styled-components";
import { BLACK, BLUE, LIGHT_GREY, WHITE } from "../constants/cts_colors";
import { useEffect, useState } from "react";
import { MdCreate, MdDelete } from "react-icons/md";
import { IPostsInfos } from "../interfaces/posts";
import { useAuthentication } from "../common/contexts/authenticationContext";
import DotsButton from "./DotsButton";
import { useAnalyticLogs } from "../common/contexts/analyticLogContext";
import checkedIconPath from "../assets/images/png/circle_check.png";
import { useApp } from "../common/contexts/appContext";
import { MOBILE_WIDTH } from "../constants/cts_sizes";
import { beautifulDate, fullDateString } from "../utils/date";
import checkedEvent from "../assets/images/events/event_checked.png";
import { useTranslation } from "react-i18next";

const EventCard = ({
  event,
  onClick,
  size,
  titleSize,
  paragraphSize,
  borderSize,
  handleDeletePost,
  handleUpdatePost,
}: {
  event: IPostsInfos;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  size: string;
  titleSize: string;
  paragraphSize: string;
  borderSize: string;
  handleDeletePost?: Function;
  handleUpdatePost?: Function;
}) => {
  const { t, i18n } = useTranslation();
  const { isMobileView } = useApp();
  const { isUserAdmin, user } = useAuthentication();
  const [isHovered, _setIsHovered] = useState<boolean>(false);
  const [isMenuOpened, _setIsMenuOpened] = useState<boolean>(false);
  const [podcastBackground, _setPodcastBackground] = useState<string>("");
  const { onAnalyticsGetMyListeningsPercentagesForPodcast } = useAnalyticLogs();
  const [isListened, _setIsListened] = useState<boolean>(false);
  const [isNewEvent, _setIsNewEvent] = useState<boolean>(false);

  useEffect(() => {
    if (event) {
      let dateToPublishEvent = new Date(event.date_to_publish);
      let actualDate = new Date();

      let diffInTime = actualDate.getTime() - dateToPublishEvent.getTime();
      let diffInDays = Math.round(diffInTime / (1000 * 3600 * 24));

      _setIsNewEvent(diffInDays <= 14);

      const images = (event.filesHistory || []).filter(
        (f) => f.type.split("/")[0] === "image"
      );
      if (images.length) {
        _setPodcastBackground(images[0].url);
      }
    }
  }, [event]);

  // CHECK IF THE PODCAST IS ALREADY LISTENED
  useEffect(() => {
    onAnalyticsGetMyListeningsPercentagesForPodcast(event.id)
      .then((logs) => {
        const listeningsPercentages: number[] = [];

        logs.forEach((log) => {
          // convert the datas (the percentage) to float
          listeningsPercentages.push(parseFloat(log.datas));
        });

        const maxPercentage = Math.max(...listeningsPercentages);

        if (maxPercentage >= 0.8) {
          _setIsListened(true);
        }
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <EventCardStyles>
      <div
        className="relative cursor-pointer image dt-size-is-1-1"
        style={{
          backgroundImage: `url(${podcastBackground})`,
        }}
        onMouseEnter={() => {
          _setIsHovered(true);
        }}
        onMouseLeave={() => {
          _setIsHovered(false);
        }}
      >
        <PlayDiv
          onClick={(event) => {
            if (onClick) {
              onClick(event);
            }
          }}
          className="absolute top-0 flex justify-center items-center"
          style={{
            visibility: isHovered ? "visible" : "hidden",
            borderRadius: borderSize,
          }}
        >
          <p className="mt-4">{t("seeEvent", { ns: "eventsPage" })}</p>
        </PlayDiv>

        {isUserAdmin && (
          <DotsArea>
            <DotsButton
              type="post"
              onClick={() => {
                _setIsMenuOpened(!isMenuOpened);
              }}
            />
            {/* 3 DOTS MENU */}
            <DotsMenu
              style={{ display: isMenuOpened ? "block" : "none" }}
              className="absolute rounded-xl"
            >
              <button
                className="py-2 px-4 rounded-xl"
                onClick={() => {
                  _setIsMenuOpened(!isMenuOpened);
                  handleUpdatePost && handleUpdatePost(event.id);
                }}
              >
                <MdCreate size={24} />
                <p className="font-bold">
                  {t("post.update", { ns: "circlePage" })}
                </p>
              </button>
              <button
                className="py-2 px-4 rounded-xl"
                onClick={() => {
                  _setIsMenuOpened(!isMenuOpened);
                  handleDeletePost && handleDeletePost(event.id);
                }}
              >
                <MdDelete size={24} />
                <p className="font-bold">
                  {t("post.delete", { ns: "circlePage" })}
                </p>
              </button>
            </DotsMenu>
          </DotsArea>
        )}

        {/* FULL EVENT */}
        {event.nb_max_subscriptions === event.subscribesUsersIds.length && (
          <FullEvent className="">
            <div className="full-event">
              <p>
                {t("fullEvent", {
                  ns: "eventsPage",
                })}
              </p>
            </div>
          </FullEvent>
        )}
      </div>
      <div className="flex flex-col gap-2">
        <h3 className="font-bold mt-3 mb-1 pr-8 text-wrap max-w-full">
          {event.title}
        </h3>
        <p>🗓️ {fullDateString(event.event_date, i18n.language)}</p>
        {/* ADDRESSE */}
        {/* <p>📍 DYNAMIC 27 rue Henri Barbusse, 75012, Paris</p> */}

        {event.subscribesUsersIds.includes(user.id) && (
          <div className="flex justify-start items-center gap-1">
            <img src={checkedEvent} style={{ width: 16, height: 16 }} />
            <p>{t("alreadyRegisteredEvent", { ns: "eventsPage" })}</p>
          </div>
        )}
      </div>
      {isListened && (
        <IsListenedInfo>
          <img src={checkedIconPath} />
          <span>{t("post.read", { ns: "circlePage" })}</span>
        </IsListenedInfo>
      )}

      {/* NEW CARD */}
      {isNewEvent && (
        <NewEvent>
          <p>{t("newEvent", { ns: "eventsPage" })}</p>
        </NewEvent>
      )}

      <div className="mt-4 flex gap-2 flex-wrap">
        {(event.tagsHistory || []).map((tag, key) => {
          if (tag !== null)
            return <SmallTagPill key={key}>#{tag.name}</SmallTagPill>;
        })}
      </div>
    </EventCardStyles>
  );
};

export default EventCard;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const EventCardStyles = styled.div`
  position: relative;

  .image {
    position: relative;
    border-radius: 16px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 340px;
    height: 207px;

    @media (max-width: 1024px) {
      border-radius: 8px;
    }

    @media (max-width: ${MOBILE_WIDTH}px) {
      max-width: inherit;
      max-height: inherit;
    }
  }

  p {
    font-family: League Spartan;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: ${BLACK};
  }

  h3 {
    font-family: League Spartan;
    font-size: 24px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: ${BLUE};
  }
`;

const NewEvent = styled.div`
  padding: 8px;
  border-radius: 4px;
  border: 1px solid ${BLACK};
  background-color: ${WHITE};
  position: absolute;
  top: -12px;
  left: -8px;
  transform: rotateZ(-2deg);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  p {
    font-family: League Spartan;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    color: ${BLACK};
  }
`;

const FullEvent = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: stretch;
  height: 100%;

  .full-event {
    padding: 12px;
    border-radius: 6px;
    background-color: ${WHITE};
    margin-right: 12px;
    margin-bottom: 12px;

    p {
      font-family: League Spartan;
      font-size: 18.18px;
      font-weight: 600;
      text-align: left;
      color: ${BLACK};
    }
  }
`;

const PlayDiv = styled.div`
  background-color: rgba(61, 61, 234, 0.5);
  transition: all 250ms linear;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;

  p {
    font-family: League Spartan;
    font-size: 24px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    color: ${WHITE};
  }

  :hover {
    opacity: 1;
  }
`;

const DotsMenu = styled.div`
  background-color: ${WHITE};
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  right: 0;

  button {
    display: flex;
    flex-direction: row;
    gap: 4px;
    width: 100%;

    :hover {
      background-color: ${LIGHT_GREY};
    }
  }
`;

const DotsArea = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 6px;
  padding-top: 6px;
`;

const IsListenedInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;

  img {
    display: block;
  }

  span {
    display: block;
    font-size: 16px;
    font-weight: 500;
    color: ${BLACK};
  }
`;

const SmallTagPill = styled.div`
  font-family: "League Spartan";
  font-weight: 700;
  font-size: 12px;
  padding: 4px 8px;
  width: min;
  border-radius: 50px;
  border: 1px solid ${BLUE};
`;
