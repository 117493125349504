import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  BLUE,
  GREY,
  LIGHT_BLUE,
  LIGHT_GREY,
  WHITE,
} from "../constants/cts_colors";
import { IPostsInfos } from "../interfaces/posts";
import ProfileImage from "./ProfileImage";
import { useTranslation } from "react-i18next";
import { AiOutlineComment, AiOutlineHeart } from "react-icons/ai";
import LikePostButton from "./LikePostButton";
import CommentPostButton from "./CommentPostButton";
import CommentComponent from "./CommentComponent";
import { IUserInfos } from "../interfaces/user";
import AddComment from "./AddComment";
import { ILikesInfos } from "../interfaces/likes";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import DotsButton from "./DotsButton";
import { MdDelete, MdCreate } from "react-icons/md";
import { useAuthentication } from "../common/contexts/authenticationContext";
import { getTimeAgoTextByLanguage } from "../utils/translations";
import i18next from "i18next";
import MagicUrl from "quill-magic-url";
import Quill from "quill";
import { MOBILE_WIDTH } from "../constants/cts_sizes";
import { useLocation } from "react-router-dom";

Quill.register("modules/magicUrl", MagicUrl);

const Post = ({
  index,
  post,
  user,
  likes,
  handleLikePost,
  handleOnSentComment,
  handleDeletePost,
  handleUpdatePost,
  isRecentPost,
}: {
  index: number;
  post: IPostsInfos;
  user: IUserInfos;
  likes: Array<ILikesInfos>;
  handleLikePost: Function;
  handleOnSentComment: Function;
  handleDeletePost: Function;
  handleUpdatePost: Function;
  isRecentPost?: boolean;
}) => {
  const { t } = useTranslation();
  const element = useRef<HTMLDivElement>(null);
  let { isUserAdmin } = useAuthentication();
  let {hash} = useLocation()
  const commentInputRef = useRef<HTMLInputElement>(null);

  const [isMenuOpened, _setIsMenuOpened] = useState<boolean>(false);

  const handleToggleCommentInput = () => {
    if (commentInputRef.current) commentInputRef.current.focus();
  };

  const modulesForQuill = {
    magicUrl: true,
  };

  useEffect(() => {
    if(hash && element && element.current) {
      hash = hash.split('&')[0]
      hash = hash.replace('#', '')
      if (hash === `p${post.id}`) {
        element.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'center',
        });
      }
    }
  }, [post, hash, element])

  return (
    <PostStyles id={`p${post.id}`} ref={element}>
      {/* HEADER INFOS */}
      <PostHeader>
        <ProfileImage
          src={
            post.userImage
              ? post.userImage
              : "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?size=338&ext=jpg&ga=GA1.1.34264412.1707782400&semt=ais"
          }
        />
        <div className="content w-full flex justify-between">
          <div className="content">
            <div className="flex md:gap-2 md:flex-row flex-col">
              <h2 className="font-bold text-2xl" style={{ color: BLUE }}>
                {post.userFirstName} {post.userLastName}
              </h2>
              {post.date_to_publish && (
                <p className="md:pt-1" style={{ fontSize: 14 }}>
                  {getTimeAgoTextByLanguage({
                    date: new Date(post.date_to_publish),
                    lang: i18next.language,
                    t,
                    additionalText: t("post.itWas", { ns: "circlePage" }),
                  })}
                </p>
              )}
            </div>
            {/* <p className="font-semibold" style={{ fontSize: 14 }}>
              {t("post.posted", { ns: "circlePage" })} Pitch Me
            </p> */}
          </div>

          {(post.user_id === user.id || isUserAdmin) && (
            <div>
              <DotsButton
                type="post"
                onClick={() => {
                  _setIsMenuOpened(!isMenuOpened);
                }}
              />
              {/* 3 DOTS MENU */}
              <DotsMenu
                style={{ display: isMenuOpened ? "block" : "none" }}
                className="absolute rounded-xl"
              >
                <button
                  className="py-2 px-4 rounded-xl"
                  onClick={() => {
                    _setIsMenuOpened(!isMenuOpened);
                    handleUpdatePost(post.id);
                  }}
                >
                  <MdCreate size={24} />
                  <p className="font-bold">
                    {t("post.update", { ns: "circlePage" })}
                  </p>
                </button>
                <button
                  className="py-2 px-4 rounded-xl"
                  onClick={() => {
                    _setIsMenuOpened(!isMenuOpened);
                    handleDeletePost(post.id, index);
                  }}
                >
                  <MdDelete size={24} />
                  <p className="font-bold">
                    {t("post.delete", { ns: "circlePage" })}
                  </p>
                </button>
              </DotsMenu>
            </div>
          )}
        </div>
      </PostHeader>

      {/* TITLE */}
      <PostTitle>{post.title}</PostTitle>

      {/* CONTENT */}
      <PostContent>
        <ReactQuill
          modules={modulesForQuill}
          value={post.content}
          readOnly={true}
          theme={"bubble"}
        />

        <div className="mt-6">
          {post.filesHistory.map((file, key) => {
            if (file.type.split("/")[0] === "image")
              return (
                <div className="flex items-center justify-center">
                  <img key={key} src={file.url} />
                </div>
              );
            // FOR AUDIO BUT AUDIO ARE SHOWN IN LIBRARY --> PODCASTS
            // else if (file.type.split("/")[0] === "audio")
            //   return <audio key={key} src={file.url} />;
            else file.type.split("/")[0] === "video";
            return (
              <div className="flex items-center justify-center">
                <video controls>
                  <source src={file.url} type={file.type} />
                </video>
              </div>
            );
          })}
        </div>
      </PostContent>

      {!isRecentPost && (
        <>
          {/* NUMBER OF LIKES AND COMMENTS */}
          <LikesAndComments>
            <div>
              <AiOutlineHeart size={22} />
              <p>{post.numberOfLikes}</p>
            </div>
            <div>
              <AiOutlineComment size={22} />
              <p>0</p>
            </div>
          </LikesAndComments>

          {/* ACTIONS */}
          <ActionsDiv>
            <LikeAndComments>
              <LikePostButton
                isLiked={likes.some((e) => e.post_id === post.id)}
                onClick={() => {
                  handleLikePost(post.id);
                }}
              />
              <CommentPostButton onClick={handleToggleCommentInput} />
            </LikeAndComments>
          </ActionsDiv>

          {/* ALL COMMENTS */}
          <div className="w-100">
            <h3 className="font-bold mb-4 text-2xl">
              {t("post.allComments", { ns: "circlePage" })}
            </h3>
            {post.comments &&
              post.comments.map((comment, key) => {
                return (
                  <CommentComponent
                    key={key}
                    user={user}
                    comment={comment}
                    isSubComment={false}
                    handleToggleCommentCommentInput={handleToggleCommentInput}
                    onSentComment={handleOnSentComment}
                  />
                );
              })}
          </div>

          {/* INPUT ADD COMMENT */}
          <AddComment
            userId={user.id}
            post={post}
            inputRef={commentInputRef}
            onSentComment={handleOnSentComment}
          />
        </>
      )}
    </PostStyles>
  );
};

export default Post;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const PostStyles = styled.div`
  width: 100%;
  margin: 24px auto;
  padding: 24px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 4px;
  /* border: 1px solid ${BLUE}; */
  border-radius: 24px;

  max-width: 55%;

  @media (max-width: 1250px) {
    max-width: 80%;
  }

  @media (max-width: ${MOBILE_WIDTH}px) {
    max-width: 100%;
    width: calc(100% - 48px);
    margin: 16px 24px;
  }
`;

const ActionsDiv = styled.div`
  width: 100%;
  border-top: 1px solid ${BLUE};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
`;

const LikeAndComments = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 16px 16px 16px 0px;
`;

const PostHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;

  & > .content {
    margin-left: 16px;
  }
`;

const PostTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
`;

const PostContent = styled.div`
  margin-top: 12px;
  margin-bottom: 24px;
  text-align: left;
  div {
    div {
      div {
        padding: 0px;
        font-size: 16px;
        font-family: "League Spartan";

        p > .mention {
          /* cursor: pointer; */
          font-weight: 600;
          color: ${BLUE};
          transition: all 250ms;

          /* :hover {
            color: ${LIGHT_BLUE};
          } */
        }
      }
    }
  }
`;

const LikesAndComments = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  gap: 24px;

  & > div {
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    gap: 8px;

    & > p {
      font-size: 16px;
      font-weight: bold;
    }
  }
`;

const DotsMenu = styled.div`
  background-color: ${WHITE};
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  button {
    display: flex;
    flex-direction: row;
    gap: 4px;
    width: 100%;

    :hover {
      background-color: ${LIGHT_GREY};
    }
  }
`;
