import styled from "styled-components";
import { BLACK, BLUE, DARK_GREY, WHITE } from "../constants/cts_colors";
import { useTranslation } from "react-i18next";
import { IPostsInfos } from "../interfaces/posts";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import ArrowButton from "./ArrowButton";
import { useAuthentication } from "../common/contexts/authenticationContext";
import ArrowWithTextButton from "./ArrowWithTextButton";
import { useAnalyticLogs } from "../common/contexts/analyticLogContext";
import Button from "./Button";
import { dateToHoursString, fullDateString } from "../utils/date";
import { usePosts } from "../common/contexts/postContext";
import { useUser } from "../common/contexts/userContext";
import { IUserInfos } from "../interfaces/user";
import { ImCross } from "react-icons/im";
import checkedEvent from "../assets/images/events/event_checked.png";
import { AddToCalendarButton } from "add-to-calendar-button-react";

const FullEventPreview = ({
  post,
  isPodcastPreviewOpen,
  onClick,
  onReadPrevious,
  onReadNext,
}: {
  post: IPostsInfos;
  isPodcastPreviewOpen: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  onReadPrevious?: Function | null;
  onReadNext?: Function | null;
}) => {
  const [event, setEvent] = useState(post);
  const { t, i18n } = useTranslation();
  const { onGetAllUsersAdmin } = useUser();
  const { user, isUserAdmin } = useAuthentication();
  const [backgroundImage, _setImageBackground] = useState<string>("");
  const { onAnalyticsEventClick } = useAnalyticLogs();
  const { joinPost, onGetPost, exitPost } = usePosts();
  const [users, setUsers] = useState<IUserInfos[]>([]);

  const [isSubscribedEvent, _setIsSubscribedEvent] = useState<boolean>(false);

  const joinEvent = () => {
    joinPost(event.id)
      .then(() => onGetPost(event.id))
      .then((e) => setEvent(e));

    _setIsSubscribedEvent(true);
  };

  const exitEvent = () => {
    exitPost(event.id)
      .then(() => onGetPost(event.id))
      .then((e) => setEvent(e));
  };

  useEffect(() => {
    if (post) {
      onAnalyticsEventClick(post.id);

      const images = (post.filesHistory || []).filter(
        (f) => f.type.split("/")[0] === "image"
      );
      if (images.length) {
        _setImageBackground(images[0].url);
      }
    }
  }, [post]);

  useEffect(() => {
    if (isUserAdmin) {
      onGetAllUsersAdmin().then(setUsers);
    }
  }, [isUserAdmin]);

  const eventDate = new Date(event.event_date || "");

  return (
    <FullResourcePreviewStyles
      className="flex w-full top-0 left-0"
      style={{ visibility: isPodcastPreviewOpen ? "visible" : "hidden" }}
    >
      {!isSubscribedEvent && (
        <>
          <div
            className="hidden md:flex md:basis-1/6 items-center justify-center"
            style={{ color: WHITE }}
          >
            {onReadPrevious && (
              <ArrowButton
                onClick={() => {
                  onReadPrevious();
                }}
              />
            )}
          </div>

          <div className="podcast-main-preview">
            <EventPreview className="w-full">
              {/* TOP SECTION */}
              <section
                className="preview relative dt-size-is-3-1"
                style={{
                  background: `url(${backgroundImage}) no-repeat center / cover`,
                }}
              >
                <div className="action-row">
                  <ArrowWithTextButton
                    text={t("back", { ns: "libraryPage" })}
                    arrowPosition="left"
                    color="white"
                    onClick={(e: any) => {
                      onClick(e);
                    }}
                  />
                </div>
                {/* FULL EVENT */}
                {event.nb_max_subscriptions ===
                  event.subscribesUsersIds.length && (
                  <div className="flex justify-end">
                    <div className="full-event">
                      <p>
                        {t("fullEvent", {
                          ns: "eventsPage",
                        })}
                      </p>
                    </div>
                  </div>
                )}
              </section>
              {/* BOTTOM SECTION */}
              <section className="other-podcasts">
                <div
                  className="flex flex-col gap-4"
                  style={{ color: BLACK, minWidth: "55vw" }}
                >
                  {/* TITLE */}
                  <h3 className="font-bold text-2xl">{event.title}</h3>

                  {/* LIEU / HEURE / DATE */}
                  <EventInfos className="flex md:flex-row md:gap-6 my-4 flex-col gap-4">
                    <div>
                      <p>
                        🗓️{" "}
                        {
                          fullDateString(event.event_date, i18n.language).split(
                            "•"
                          )[0]
                        }
                      </p>
                    </div>
                    <hr></hr>
                    <div>
                      <p>
                        ⏰{" "}
                        {dateToHoursString(
                          event.event_date,
                          i18n.language === "fr" ? "h" : ":",
                          i18n.language
                        )}
                      </p>
                    </div>
                    {/* ADDRESSE */}
                    {/* <hr></hr>
                    <div>
                      <p>
                        📍 DYNAMIC{" "}
                        {beautifulDate(event.event_date).split("à")[0]}
                      </p>
                    </div> */}
                    {/* <hr></hr> */}
                    {/* <div>
                  <p>🎤 conférence</p>
                </div> */}
                  </EventInfos>

                  <ReactQuill
                    value={event.content}
                    readOnly={true}
                    theme={"bubble"}
                  />
                  {event.subscribesUsersIds &&
                    event.subscribesUsersIds.includes(user.id) && (
                      <div className="flex flex-col gap-4">
                        {/* <h3 className="font-bold text-2xl">
                          {t("fullscreen.details", {
                            ns: "eventsPage",
                          })}
                        </h3> */}
                        <ReactQuill
                          value={event.content_details}
                          readOnly={true}
                          theme={"bubble"}
                        />
                      </div>
                    )}
                </div>

                {event.nb_max_subscriptions !== 0 && (
                  <div className="join">
                    {event.subscribesUsersIds &&
                    event.subscribesUsersIds.includes(user.id) ? (
                      <div className="flex flex-col gap-4">
                        <Button
                          text={t("alreadyRegisteredEvent", {
                            ns: "eventsPage",
                          })}
                          // text={`Quitter l'événement du ${beautifulDate(
                          //   event.event_date
                          // )}`}
                          borderRadiusRounded
                          isBold={true}
                          isAlreadyRegistered={true}
                        />
                        <div className="text-center">
                          <ButtonUnsubscribe onClick={exitEvent}>
                            {t("quitEvent", {
                              ns: "eventsPage",
                            })}
                          </ButtonUnsubscribe>
                        </div>
                      </div>
                    ) : (
                      <Button
                        text={t("registerEvent", {
                          ns: "eventsPage",
                        })}
                        // text={`Rejoindre l'événement du ${beautifulDate(
                        //   event.event_date
                        // )}`}
                        onClick={joinEvent}
                        borderRadiusRounded
                        isBold={true}
                      />
                    )}
                  </div>
                )}

                {isUserAdmin && event.subscribesUsersIds && (
                  <div className="flex flex-col mt-6 gap-4 users">
                    <h3 className="font-bold text-2xl">
                      {t("fullscreen.usersViewByAdmin", {
                        ns: "eventsPage",
                      })}{" "}
                      - {event.subscribesUsersIds.length}{" "}
                      {t("fullscreen.users", {
                        ns: "eventsPage",
                      })}
                    </h3>
                    <table>
                      {event.subscribesUsersIds.map((uId, index) => {
                        const user = users.find((u) => u.id === uId);

                        return (
                          <tr key={uId}>
                            <td> {index + 1}</td>
                            <td>{user?.firstName}</td>
                            <td>{user?.lastName}</td>
                            <td>{user?.email}</td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                )}
              </section>
            </EventPreview>
          </div>

          <div className="hidden md:flex md:basis-1/6 items-center justify-center rotate-180">
            {onReadNext && (
              <ArrowButton
                onClick={() => {
                  onReadNext();
                }}
              />
            )}
          </div>
        </>
      )}

      {isSubscribedEvent && (
        <EventSubscribedPreview>
          <section className="relative dt-size-is-3-1">
            <div className="flex justify-end">
              <ImCross
                color={BLACK}
                size={16}
                className="cursor-pointer"
                onClick={(e: any) => {
                  onClick(e);
                }}
              />
            </div>
            <div className="text-center">
              <img src={checkedEvent} className="my-8" />
              <h2>
                {t("thanksForSubscription", {
                  ns: "eventsPage",
                })}
              </h2>
              <p>
                {t("rappelMail", {
                  ns: "eventsPage",
                })}
              </p>
            </div>
            {isUserAdmin && event.subscribesUsersIds && !isSubscribedEvent && (
              <div className="flex flex-col mt-6 gap-4 users">
                <h3 className="font-bold text-2xl">
                  {t("fullscreen.usersViewByAdmin", {
                    ns: "eventsPage",
                  })}{" "}
                  - {event.subscribesUsersIds.length}{" "}
                  {t("fullscreen.users", {
                    ns: "eventsPage",
                  })}
                </h3>
                <table>
                  {event.subscribesUsersIds.map((uId, index) => {
                    const user = (users || []).find((u) => u.id === uId);

                    return (
                      <tr key={uId}>
                        <td> {index + 1}</td>
                        <td>{user?.firstName}</td>
                        <td>{user?.lastName}</td>
                        <td>{user?.email}</td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            )}
            <div style={{ display: "flex", marginTop: 16 }}>
              <AddToCalendarButton
                name={event.title}
                startDate={
                  eventDate.getFullYear() +
                  "-" +
                  (eventDate.getMonth() + 1) +
                  "-" +
                  eventDate.getDate()
                }
                startTime={eventDate.getHours() + ":" + eventDate.getMinutes()}
                options="['Apple','Google','iCal','Microsoft365','Outlook.com','Yahoo']"
                timeZone="Europe/Paris"
                endTime={
                  eventDate.getHours() + 1 + ":" + eventDate.getMinutes()
                }
                buttonsList
                hideTextLabelButton
                buttonStyle="round"
                lightMode="bodyScheme"
              />
            </div>
          </section>
        </EventSubscribedPreview>
      )}
    </FullResourcePreviewStyles>
  );
};

export default FullEventPreview;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const FullResourcePreviewStyles = styled.div`
  background-color: rgba(46, 50, 56, 0.4);
  backdrop-filter: blur(2px);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;

  .podcast-main-preview {
    display: flex;
    align-items: center;
    flex: 1;
  }
`;

const EventPreview = styled.div`
  border-radius: 24px;
  overflow: auto;
  margin-top: 16px;
  margin-bottom: 16px;
  max-height: calc(100vh - 32px);

  .preview {
    padding: 48px 56px;
    border-top-right-radius: 24px;
    border-top-left-radius: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .full-event {
      padding: 12px;
      border-radius: 6px;
      background-color: ${WHITE};

      p {
        font-family: League Spartan;
        font-size: 18.18px;
        font-weight: 600;
        text-align: left;
        color: ${BLACK};
      }
    }

    @media (max-width: 768px) {
      border-radius: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }

  .other-podcasts {
    padding: 40px 56px;
    background-color: ${WHITE};
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;

    div.join {
      margin-top: 32px;
      border-radius: 1000px;
    }

    div > div.ql-editor {
      padding: 0;
    }

    @media (max-width: 768px) {
      padding: 24px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border-radius: 0px;
    }
  }

  @media (max-width: 768px) {
    border-radius: 0px;
    max-height: 100vh;
  }
`;

const EventSubscribedPreview = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  section {
    min-width: 66vw;
    background-color: ${WHITE};
    padding: 32px 48px 40px 48px;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h2 {
      font-family: League Spartan;
      font-size: 32px;
      font-weight: 700;
      line-height: 29.44px;
      text-align: left;
      color: ${BLACK};
      margin-bottom: 16px;
      text-align: center;
    }

    p {
      font-family: League Spartan;
      font-size: 16px;
      font-weight: 300;
      line-height: 14.72px;
      text-align: center;
      color: ${BLACK};
      margin-bottom: 24px;
      text-align: center;
    }

    @media (max-width: 768px) {
      margin-left: 16px;
      margin-right: 16px;
    }
  }
`;

const EventInfos = styled.div`
  color: ${BLUE};

  hr {
    border: none;
    border-left: 1px solid ${BLUE};
    height: 24px;
    width: 1px;

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

const ButtonUnsubscribe = styled.button`
  font-family: League Spartan;
  font-size: 16px;
  font-weight: 400;
  line-height: 14.72px;
  text-align: center;
  color: ${DARK_GREY};
  cursor: pointer;

  text-underline-offset: 0.33em;
  text-decoration: underline 0.1em rgba(0, 0, 0, 0);
  transition: all 250ms;

  :hover {
    text-decoration-color: rgba(0, 0, 0, 1);
  }
`;
