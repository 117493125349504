import styled from "styled-components";
import { useEffect, useState } from "react";
import { BLACK, GREY, LIGHT_GREY } from "../constants/cts_colors";
import { useTranslation } from "react-i18next";

const TopMenu = ({
  sections,
  selectedSection,
  fromPage,
  onClick,
}: {
  sections: Array<string>;
  selectedSection: string;
  fromPage: string;
  onClick?: Function;
}) => {
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(location.search);
  const [isHovered, _setIsHovered] = useState<boolean>();
  const disabledStyle = {
    textDecorationColor: isHovered
      ? "rgba(163, 172, 185, 1)"
      : "rgba(255, 255, 255, 0)",
  };

  useEffect(() => {
    const paramValue = queryParams.get("t");
    console.log("paramValue", paramValue);
    if (paramValue && onClick) {
      onClick(paramValue);
    }
  }, []);

  return (
    <TopMenuStyles className="pl-8 mt-4 flex flex-row gap-8">
      {sections.map((section, key) => {
        return (
          <div
            key={key}
            className={`cursor-pointer ${
              section === selectedSection ? "active" : "disabled"
            } ${
              sections.length > 1 && section === selectedSection
                ? "underline"
                : ""
            }`}
            style={section !== selectedSection ? disabledStyle : {}}
            onMouseEnter={() => {
              section !== selectedSection && _setIsHovered(true);
            }}
            onMouseLeave={() => {
              section !== selectedSection && _setIsHovered(false);
            }}
            onClick={() => {
              if (onClick) {
                onClick(section);
              }
            }}
          >
            <p className="text-2xl font-bold">{t(section, { ns: fromPage })}</p>
          </div>
        );
      })}
      {/* <div className="cursor-pointer active">
        <p className="text-2xl font-bold">
          {t("podcasts", { ns: "libraryPage" })}
        </p>
      </div>
      <div
        className="cursor-pointer disabled"
        onMouseEnter={() => {
          _setIsHovered(true);
        }}
        onMouseLeave={() => {
          _setIsHovered(false);
        }}
        style={disabledStyle}
      >
        <p className="text-2xl">{t("resources", { ns: "libraryPage" })}</p>
      </div> */}
    </TopMenuStyles>
  );
};

export default TopMenu;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const TopMenuStyles = styled.div`
  flex-wrap: wrap;

  div {
    transition: color 250ms linear, text-decoration-color 250ms linear;
    text-underline-offset: 8px;

    &.underline {
      text-decoration: underline;
    }

    &.active {
      color: ${BLACK};
    }

    &.disabled {
      color: #6e737c;

      :hover {
        color: #6e737c;
      }
    }
  }
`;
