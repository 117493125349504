import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { GREY } from "../../constants/cts_colors";
import { IFormationsInfos } from "../../interfaces/formations";
import FormationCard from "../../components/FormationCard";
import { useAuthentication } from "../../common/contexts/authenticationContext";
import { useFormations } from "../../common/contexts/formationContext";
import TopMenu from "../../components/TopMenu";
import { useAnalyticLogs } from "../../common/contexts/analyticLogContext";
import i18next from "i18next";
import { IUserInfos } from "../../interfaces/user";
import { useUser } from "../../common/contexts/userContext";
import GroupsPage from "./groups";
import ModulesMessages from "./modulesMessages";

const TeachUpPage = () => {
  const { t } = useTranslation();
  const { token } = useAuthentication();
  const [user, _setUser] = useState<IUserInfos | null>();
  const [isGroup, setIsGroup] = useState<number | null>(null);
  const { onGetCurrentUser } = useUser();
  const { onGetAllUserFormations } = useFormations();
  const [formations, _setFormations] = useState<Array<IFormationsInfos>>([]);

  const menuSections = {
    formations: "formations",
    journeyTracking: "journeyTracking.title",
  };

  const [selectedSection, _setSelectedSection] = useState<string>(
    menuSections.formations
  );

  const { onAnalyticsFormationsPageView } = useAnalyticLogs();

  useEffect(() => {
    if (user && token) {
      onGetAllUserFormations()
        .then((response) => {
          _setFormations(response);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [user, token]);

  // ANALITYCS - LOG EVENT - PAGE VIEW
  useEffect(() => {
    setIsGroup(null);
    switch (selectedSection) {
      case menuSections.formations:
        onAnalyticsFormationsPageView()
          .then(() => {})
          .catch((error) => console.error(error));
        break;
      default: {
        const group = (user?.groups || []).find(
          (g) => g.name === selectedSection
        );
        if (group) {
          setIsGroup(group.id);
        }
      }
    }
  }, [selectedSection]);

  useEffect(() => {
    document.title = t("mainTitle", { ns: "teachupPage" });
  }, [i18next.language]);

  // load user
  const loadUser = () => {
    onGetCurrentUser()
      .then((responseUser) => {
        if (responseUser) {
          _setUser(responseUser);
          // console.log(responseUser);
        }
      })
      .catch((error) => alert(error));
  };

  useEffect(() => {
    if (token) {
      loadUser();
    }
  }, [token]);

  if (!user) {
    return <></>;
  }

  // --- FUNCTIONS ---
  const onOpenPopup = (link: string) => {
    window.open(link, "lescles-teachup");
  };

  return (
    <Wrapper>
      <TopMenu
        sections={[
          menuSections.formations,
          menuSections.journeyTracking,
        ].concat((user.groups || []).map((g) => g.name))}
        selectedSection={selectedSection}
        fromPage="teachupPage"
        onClick={(response: string) => _setSelectedSection(response)}
      />

      {menuSections.formations === selectedSection && (
        <SectionContent className="w-full">
          <div className="overflow-scroll mt-8">
            <div className="flex flex-row flex-wrap gap-3 justify-evenly ml-2 my-6 max-w-72">
              {formations &&
                formations.length > 0 &&
                formations.map((formation, key) => {
                  return (
                    <FormationCard
                      formationId={formation.id}
                      title={formation.title}
                      description={formation.description}
                      language={formation.language}
                      image={formation.image}
                      key={key}
                      onClick={() => {
                        onOpenPopup(formation.link);
                      }}
                    />
                  );
                })}
              {formations && formations.length < 1 && (
                <NoContentSentence>
                  {t("formations.noFormations", { ns: "settingsPage" })}
                </NoContentSentence>
              )}
            </div>
          </div>
        </SectionContent>
      )}

      {menuSections.journeyTracking === selectedSection && (
        <ModulesMessages user={user} />
      )}

      {isGroup && <GroupsPage groupId={isGroup} />}
    </Wrapper>
  );
};

export default TeachUpPage;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div`
  padding-bottom: 16px;
`;

const SectionContent = styled.div`
  margin-bottom: 80px;
`;

const NoContentSentence = styled.div`
  width: 100%;
  text-align: center;
  color: ${GREY};
`;
