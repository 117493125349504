import { instanceAxios } from "../utils/axios-api";

export const onCreateFormationApi = ({
  title,
  image,
  language,
  link,
  modules,
}: {
  title: string;
  image: string | null;
  language: string;
  link: string;
  modules: any[];
}) => {
  const formData = new FormData();
  formData.append("title", title || "");
  formData.append("image", image || "");
  formData.append("language", language || "");
  formData.append("link", link || "");
  formData.append("modules", JSON.stringify(modules));

  return instanceAxios
    .post("/formations/create-formation", formData)
    .then((response) => (response.data ? response.data : null));
};

export const onGetAllFormationsApi = () => {
  return instanceAxios
    .get(`/formations/get-all-formations`)
    .then((response) => (response.data ? response.data.data : null));
};

export const onGetAllUserFormationsApi = () => {
  return instanceAxios
    .get(`/formations/get-user-formations`)
    .then((response) => (response.data ? response.data.data : null));
};

export const onGetFormationApi = (id: number) => {
  return instanceAxios
    .get(`/formations/unique/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

export const onDeleteFormationApi = (id: number) => {
  return instanceAxios
    .put("/formations/delete-formation/" + id)
    .then((response) => (response.data ? response.data.data : null));
};

export const onUpdateFormationApi = ({
  id,
  title,
  image,
  language,
  link,
  modules,
}: {
  id: number;
  title: string;
  image: string | null;
  language: string;
  link: string;
  modules: any[];
}) => {
  const formData = new FormData();
  formData.append("title", title || "");
  formData.append("image", image || "");
  formData.append("language", language || "");
  formData.append("link", link || "");
  formData.append("modules", JSON.stringify(modules));

  return instanceAxios
    .put("/formations/update-formation/" + id, formData)
    .then((response) => (response.data ? response.data.data : null));
};

export const onGetSearchFormationsApi = (search: string) => {
  const formData = new FormData();

  formData.append("search", search || "");

  return instanceAxios
    .post(`/formations/search`, formData)
    .then((response) => (response.data ? response.data.data : null));
};

export const onGetTeachupTokenApi = () => {
  return instanceAxios
    .get(`/teachups/get-auth-token`)
    .then((response) => (response.data ? response.data.data : null));
};
