import React, { useState, useCallback, createContext } from "react";

import { useTranslation } from "react-i18next";
import { checkEmptyInput } from "../../utils/checkInputs";
import { EMPTY_COMPANY_NAME } from "../../constants/cts_formErrors";

import { ICompaniesContext, ICompaniesInfos } from "../../interfaces/companies";
import {
  onCreateCompanyApi,
  onDeleteCompanyApi,
  onGetAllCompaniesApi,
  onGetCompanyApi,
  onGetNumberOfMembersApi,
  onGetSearchCompaniesApi,
  onUpdateCompanyApi,
} from "../../api/company.api";

const CompaniesContext = createContext(null);

export function CompaniesProvider(props: any) {
  const [isLoading, _setIsLoading] = useState(false);
  const { t } = useTranslation();

  const onCreateCompany = useCallback(({ name, is_intra }: ICompaniesInfos) => {
    if (!checkEmptyInput(name)) {
      return new Promise((resolve, reject) => {
        reject(t(`form.${EMPTY_COMPANY_NAME}`, { ns: "errors" }));
      });
    }

    _setIsLoading(true);
    return onCreateCompanyApi({ name, is_intra })
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.response.data);
        } else {
          throw new Error(error.message);
        }
      })
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      });
  }, []);

  const onGetAllCompanies = useCallback(() => {
    _setIsLoading(true);
    return onGetAllCompaniesApi()
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      })
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      });
  }, []);

  const onGetCompany = useCallback((id: number) => {
    _setIsLoading(true);
    return onGetCompanyApi(id)
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  const onUpdateCompany = useCallback(
    ({
      id,
      name,
      formations,
      is_intra,
    }: {
      id: number;
      name: string;
      formations: Array<number>;
      is_intra: boolean;
    }) => {
      if (!checkEmptyInput(name)) {
        return new Promise((resolve, reject) => {
          reject(t(`form.${EMPTY_COMPANY_NAME}`, { ns: "errors" }));
        });
      }

      _setIsLoading(true);
      return onUpdateCompanyApi({
        id,
        name,
        formations,
        is_intra,
      })
        .then((response: any) => {
          _setIsLoading(false);
          return response;
        })
        .catch((error: any) => {
          if (error.response) {
            throw new Error(error.message.data);
          } else {
            throw new Error(error.message);
          }
        });
    },
    []
  );

  const onDeleteCompany = useCallback((id: number) => {
    _setIsLoading(true);
    return onDeleteCompanyApi(id)
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  const onGetNumberOfMembers = useCallback((id: number) => {
    _setIsLoading(true);
    return onGetNumberOfMembersApi(id)
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      })
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      });
  }, []);

  const onGetSearchCompanies = useCallback((search: string) => {
    _setIsLoading(true);
    return onGetSearchCompaniesApi(search)
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      })
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      });
  }, []);

  return (
    <CompaniesContext.Provider
      {...props}
      value={{
        isLoading,
        // function
        onCreateCompany,
        onGetAllCompanies,
        onGetCompany,
        onUpdateCompany,
        onDeleteCompany,
        onGetNumberOfMembers,
        onGetSearchCompanies,
      }}
    />
  );
}

export const useCompanies = (): ICompaniesContext => {
  const context = React.useContext(CompaniesContext);
  if (!context) throw new Error("useCompanies must be used in CompanyProvider");

  return context;
};
