import React, { useEffect, useState } from "react";
import { IMembersInfos } from "../../interfaces/members";
import { useMembers } from "../../common/contexts/memberContext";
import styled from "styled-components";
import {
  BLACK,
  BLUE,
  CLEAR_BLUE,
  COLOR_BLACK,
  COLOR_WHITE,
  WHITE,
} from "../../constants/cts_colors";
import MemberInfosSidebar from "./memberInfosSidebar";
import { ICompaniesInfos } from "../../interfaces/companies";

const MembersDirectory = () => {
  const [members, _setMembers] = useState<IMembersInfos[]>([]);
  const [membersFiltered, _setMembersFiltered] = useState<IMembersInfos[]>([]);
  const { onGetAllCommunityMembers } = useMembers();
  const [selectedMemberInfos, _setSelectedMemberInfos] =
    useState<IMembersInfos | null>(null);
  const [enterprises, setEnterprises] = useState<ICompaniesInfos[]>([]);
  const [filters, setFilters] = useState({ enterprise: null, name: null });

  // GET ALL MEMBERS
  useEffect(() => {
    onGetAllCommunityMembers()
      .then((response) => {
        _setMembers(response);
        // console.log(response);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    const list: ICompaniesInfos[] = [];
    members.map((m) => {
      if (m.companyInfos && !list.find((l) => l.id === m.companyInfos.id)) {
        list.push(m.companyInfos);
      }
    });
    setEnterprises(list);
  }, [members]);

  useEffect(() => {
    let list = [...members];

    if (filters && filters.enterprise) {
      list = list.filter(
        (l) => l.companyInfos && l.companyInfos.id == filters.enterprise
      );
    }

    if (filters && filters.name) {
      list = list.filter(
        (l) =>
          (l.first_name || "").toLowerCase().includes(filters.name || "") ||
          (l.last_name || "").toLowerCase().includes(filters.name || "")
      );
    }

    _setMembersFiltered(list);
  }, [filters, members]);

  const updateFilter = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    // @ts-ignore
    filters[name] = value ? value.toLowerCase() : null;
    setFilters({ ...filters });
  };

  return (
    <Wrapper>
      <SearchBar>
        <SelectBt>
          <div className="label">
            <p>Membres</p>
          </div>
          <div className="list">
            <input
              name="name"
              onChange={updateFilter}
              type="text"
              placeholder="Rechercher..."
            />
          </div>
        </SelectBt>
        <SelectBt>
          <div className="label">
            <p>Entreprises</p>
          </div>
          <div className="list">
            <select name="enterprise" onChange={updateFilter}>
              <option value="">Rechercher...</option>
              {enterprises.map((e) => (
                <option key={e.id} value={e.id}>
                  {e.name}
                </option>
              ))}
            </select>
          </div>
        </SelectBt>
      </SearchBar>
      {/* MEMBERS LIST */}
      {membersFiltered && membersFiltered.length > 0 && (
        <Members>
          {membersFiltered.map((member) => (
            <MemberCard
              key={"member-card-" + member.id}
              onClick={() => _setSelectedMemberInfos(member)}
            >
              {/* image */}
              <MemberImage>
                <ImageFrame>
                  {member.image && <img src={member.image} alt="member" />}
                </ImageFrame>
              </MemberImage>
              <MemberCardInfos>
                {/* name */}
                <MemberName>
                  {(member.first_name + " " + member.last_name).length > 20
                    ? (member.first_name + " " + member.last_name).substring(
                        0,
                        17
                      ) + "..."
                    : member.first_name + " " + member.last_name}
                </MemberName>
                {/* position */}
                <MemberPosition>
                  {(member.position || "").length > 38
                    ? member.position.substring(0, 35) + "..."
                    : member.position}
                </MemberPosition>
                {/* company */}
                <MemberCompany>
                  {(member.companyInfos.name || "").length > 38
                    ? member.companyInfos.name.substring(0, 35) + "..."
                    : member.companyInfos.name}
                </MemberCompany>
                {/* email */}
                <MemberEmail>
                  {(member.email || "").length > 38
                    ? member.email.substring(0, 35) + "..."
                    : member.email}
                </MemberEmail>
              </MemberCardInfos>
            </MemberCard>
          ))}
        </Members>
      )}

      {/* MEMBER INFOS */}
      {selectedMemberInfos && (
        <MemberInfosSidebar
          onClose={() => _setSelectedMemberInfos(null)}
          member={selectedMemberInfos}
        />
      )}
    </Wrapper>
  );
};

export default MembersDirectory;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div`
  padding: 40px;
  /* background-color: red; */
  max-width: 60vw;
`;

const SearchBar = styled.div`
  margin-bottom: 48px;
`;

const MemberImage = styled.div`
  display: block;
  min-width: 80px;
  width: 40%;
  border-radius: 50%;
  background-color: ${CLEAR_BLUE};
  border: 0.92px solid ${BLUE};
`;

const ImageFrame = styled.div`
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;

    // prevent dragging
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
`;

const MemberCardInfos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 8px;
`;

const MemberName = styled.span`
  font-size: 1.67vw;
  font-weight: bold;
  color: ${BLUE};
  transition: color 250ms;
`;

const MemberPosition = styled.span`
  font-size: 0.97vw;
  font-weight: 500;
  color: ${BLACK};
  transition: color 250ms;
`;

const MemberCompany = styled.span`
  font-size: 0.97vw;
  color: ${BLACK};
  transition: color 250ms;
`;

const MemberEmail = styled.span`
  font-size: 0.97vw;
  color: ${BLUE};
  transition: color 250ms;
`;

const MemberCard = styled.button`
  aspect-ratio: 262/285;
  background-color: ${WHITE};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  transition: background-color 250ms, color 250ms;
  border-radius: 16px;
  padding: 30px 0px;

  &:hover {
    background-color: ${BLUE};

    ${MemberName} {
      color: ${WHITE};
    }
    ${MemberPosition} {
      color: ${WHITE};
    }
    ${MemberCompany} {
      color: ${WHITE};
    }
    ${MemberEmail} {
      color: ${WHITE};
    }
  }
`;

const Members = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 22px;
  margin-bottom: 32px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);

    ${MemberName} {
      font-size: 3vw;
    }
    ${MemberPosition} {
      font-size: 1.5vw;
    }
    ${MemberCompany} {
      font-size: 1.5vw;
    }
    ${MemberEmail} {
      font-size: 1.5vw;
    }
  }

  @media (max-width: 860px) {
    grid-template-columns: 1fr;

    ${MemberCard} {
      aspect-ratio: unset;
    }

    ${MemberImage} {
      min-width: auto;
    }

    ${MemberName} {
      font-size: 3.5vw;
    }
    ${MemberPosition} {
      font-size: 2vw;
    }
    ${MemberCompany} {
      font-size: 2vw;
    }
    ${MemberEmail} {
      font-size: 2vw;
    }
  }
`;

const SelectBt = styled.div`
  border: 1px solid ${COLOR_BLACK};
  height: 56px;
  border-radius: 8px;
  display: inline-flex;
  background-color: ${COLOR_WHITE};
  margin-right: 16px;
  margin-bottom: 16px;
  max-width: 350px;
  width: 50%;

  &:last-child {
    margin-right: 0;
  }

  > .label {
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid ${COLOR_BLACK};

    p {
      font-size: 16px;
      margin: 0;
      padding: 0;
    }
  }

  > .list {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0 16px;

    > select {
      width: 100%;

      &:focus {
        outline: none;
      }
    }

    > input {
      width: 100%;

      &:focus {
        outline: none;
      }
    }
  }
`;
