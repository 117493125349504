import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAuthentication } from "../../common/contexts/authenticationContext";
import {
  BLUE,
  CLEAR_GREY,
  COLOR_WHITE,
  GREY,
  LIGHT_BLUE,
  LIGHT_GREY,
  WHITE,
} from "../../constants/cts_colors";
import { useEffect, useState } from "react";
import PodcastCard from "../../components/PodcastCard";
import FullPodcastPreview from "../../components/FullPodcastPreview";
import TagPill from "../../components/TagPill";
import { usePosts } from "../../common/contexts/postContext";
import { IPostsInfos } from "../../interfaces/posts";
import TopMenu from "../../components/TopMenu";
import WriteYourPost from "../../components/WriteYourPost";
import { AiOutlineLoading3Quarters, AiTwotoneAudio } from "react-icons/ai";
import { RiImageLine } from "react-icons/ri";
import { ImCross } from "react-icons/im";
import ReactQuill from "react-quill";
import FileCard from "../../components/FileCard";
import ResourceCard from "../../components/ResourceCard";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { useTags } from "../../common/contexts/tagContext";
import { ITagsInfos } from "../../interfaces/tags";
import FullResourcePreview from "../../components/FullResourcePreview";
import SelectTagPill from "../../components/SelectTagPill";
import { useAnalyticLogs } from "../../common/contexts/analyticLogContext";
import i18next from "i18next";
import { languages } from "../../constants/cts_languages";
import PATH from "../../constants/cts_routes";
import { getPageUrl } from "../../locales/i18n";

const LibraryPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [tab, setTab] = useState("podcasts");
  const [isCreatingPost, _setIsCreatingPost] = useState<boolean>(false);
  const [isUpdatingPost, setIsUpdatingPost] = useState<IPostsInfos | null>(
    null
  );
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [onLoadingSendingPost, _setOnLoadingSendingPost] =
    useState<boolean>(false);
  const [tagLang, setTagLang] = useState("");
  let { user, isUserAdmin } = useAuthentication();
  const handleCreatePost = () => {
    _setIsCreatingPost(true);
  };
  const [markdownContent, _setMarkdownContent] = useState<string>("");
  const {
    onGetAllPodcasts,
    onGetAllResources,
    onCreatePost,
    onDeletePost,
    onUpdatePost,
  } = usePosts();
  const { onAnalyticsPodcastsPageView, onAnalyticsResourcesPageView } =
    useAnalyticLogs();
  const [podcasts, _setPodcasts] = useState<Array<IPostsInfos>>([]);
  const [podcastsFiltered, _setPodcastsFiltered] = useState<Array<IPostsInfos>>(
    []
  );
  const [resources, _setResources] = useState<Array<IPostsInfos>>([]);
  const [resourcesFiltered, _setResourcesFiltered] = useState<
    Array<IPostsInfos>
  >([]);
  const initialPostFormState = {
    user_id: user.id ? user.id : "",
    title: "",
    content: "",
    files: [],
    tags: [],
    date_to_publish: new Date(),
  };
  const [postFormValues, _setPostFormValues] = useState(initialPostFormState);

  // --- TAGS ---
  const [sendTags, _setSendTags] = useState<Array<ITagsInfos>>([]);
  const [allTagsDB, _setAllTagsDB] = useState<Array<ITagsInfos>>([]);
  const [allTags, _setAllTags] = useState<Array<ITagsInfos>>([]);
  const [allTagsSelected, _setAllTagsSelected] = useState<Array<ITagsInfos>>(
    []
  );
  const { onCreateTag, onGetAllTags, onDeleteTag } = useTags();
  const initialTagFormState = {
    name: "",
    lang: "fr",
  };
  const [tagFormValues, _setTagFormValues] = useState(initialTagFormState);

  useEffect(() => {
    _setPodcastsFiltered(podcasts);
    _setResourcesFiltered(
      resources.filter((p) => {
        const tags = p.tags || [];
        if (tags.length === 0 && allTagsSelected.length === 0) {
          return true;
        }

        const tagSelected = (
          allTagsSelected.length ? [...allTagsSelected] : [...allTags]
        ).filter((t) => !t || t.lang === tagLang);
        if (tagSelected.some((t) => tags.includes(t.id))) {
          return true;
        }

        return false;
      })
    );
  }, [allTagsSelected, resources, podcasts, tagLang]);

  useEffect(() => {
    _setAllTagsSelected([]);
  }, [tagLang]);

  useEffect(() => {
    const paramValue = queryParams.get("id");
    if (paramValue && isFirstLoad) {
      if (tab === "podcasts") {
        const p = podcastsFiltered.find((po) => po.id == +paramValue);
        if (p) {
          _setPodcastPreview(p);
          setIsFirstLoad(false);
        }
      } else {
        const p = resourcesFiltered.find((po) => po.id == +paramValue);
        if (p) {
          _setResourcePreview(p);
          setIsFirstLoad(false);
        }
      }
    }
  }, [resourcesFiltered, podcastsFiltered]);

  useEffect(() => {
    if (allTagsDB.length && resources.length && podcasts.length) {
      const allTagsIds = [
        ...resources.flatMap((e) => e.tags),
        ...podcasts.flatMap((e) => e.tags),
      ];
      _setAllTags(allTagsDB.filter((a) => allTagsIds.includes(a.id)));
    }
  }, [allTagsDB, resources, podcasts]);

  useEffect(() => {
    onGetAllTags().then((response: any) => {
      _setAllTagsDB(response);
    });
  }, [tab]);

  // HANDLE TAG CHANGE
  const handleTagChange = (e: any) => {
    const { name, value } = e.target;
    _setTagFormValues({
      ...tagFormValues,
      [name]: value,
    });
  };

  // HANDLE TAG SUBMIT
  const handleTagSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (tagFormValues.name.length === 0) {
      alert("Veuillez entrer un nom pour le tag");
    } else {
      onCreateTag({
        name: tagFormValues.name,
        lang: tagFormValues.lang,
      }).then(() => {
        // REFRESH TAGS
        onGetAllTags().then((response) => {
          _setAllTagsDB(response);
        });
      });

      _setTagFormValues(initialTagFormState);
    }
  };

  // HANDLE TAG DELETE
  function handleTagDelete(e: any, id: number) {
    e.preventDefault();
    onDeleteTag(id).then((response: any) => {
      // REFRESH TAGS
      onGetAllTags().then((response) => {
        _setAllTagsDB(response);
      });
    });
  }

  // HANDLE ADD TAG FOR PODCAST
  function handleAddTagToList(e: any, tag: ITagsInfos) {
    e.preventDefault();

    _setSendTags((sendTags) => [...sendTags, tag]);
  }

  // HANDLE REMOVE TAG FOR PODCAST
  function handleRemoveTagToList(e: any, tag: ITagsInfos) {
    e.preventDefault();

    _setSendTags(
      sendTags.filter(function (sendTag) {
        return sendTag.id !== tag.id;
      })
    );
  }

  // HANDLE ADD AND REMOVE TAG FROM SELECTED TAGS
  function handleSelectedTags(tag: ITagsInfos) {
    if (!allTagsSelected.some((selectedTag) => selectedTag.id === tag.id))
      _setAllTagsSelected((allTagsSelected) => [...allTagsSelected, tag]);
    else {
      _setAllTagsSelected((selectedTags) =>
        selectedTags.filter((_, i) => i !== selectedTags.length - 1)
      );
    }
  }

  // --- FILES ---
  const [sendFiles, _setSendFiles] = useState<any[]>([]);

  // IMAGES FILES
  function onImageChange(e: any) {
    let tmpFiles = sendFiles;
    tmpFiles.push(e.target.files[0]);
    for (let i = 0; i < tmpFiles.length; i++) {
      if (!tmpFiles[i].id) {
        tmpFiles[i].url = URL.createObjectURL(tmpFiles[i]);
      }
    }
    _setSendFiles([...tmpFiles]);
  }

  // AUDIO FILES
  function onAudioChange(e: any) {
    let tmpFiles = sendFiles;
    tmpFiles.push(e.target.files[0]);
    _setSendFiles([...tmpFiles]);
  }

  // HANDLE POST SUBMIT
  const handlePostSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    _setOnLoadingSendingPost(true);

    const thenPost = () => {
      _setIsCreatingPost(false);
      _setPostFormValues(initialPostFormState);
      _setMarkdownContent("");
      _setSendFiles([]);
      _setOnLoadingSendingPost(false);
      _setSendTags([]);

      load();
    };

    // tags
    let onlyIdForTags: number[] = [];
    sendTags.map((tag: ITagsInfos) => {
      onlyIdForTags.push(tag.id);
    });

    if (isUpdatingPost) {
      onUpdatePost({
        id: isUpdatingPost.id,
        user_id: isUpdatingPost.user_id,
        title: postFormValues.title,
        content: markdownContent,
        files: sendFiles,
        type: tab,
        tags: onlyIdForTags,
        date_to_publish: postFormValues.date_to_publish,
      }).then(thenPost);
    } else {
      onCreatePost({
        user_id: user.id,
        title: postFormValues.title,
        content: markdownContent,
        files: sendFiles,
        type: tab,
        tags: onlyIdForTags,
        date_to_publish: postFormValues.date_to_publish,
      }).then(thenPost);
    }
  };

  // ON CHANGE POST
  const handlePostChange = (e: any) => {
    const { name, value } = e.target;
    _setPostFormValues({
      ...postFormValues,
      [name]: value,
    });
  };

  // HANDLE CHANGE MARKDOWN EDITOR
  const handleMarkdownChange = (value: string) => {
    _setMarkdownContent(value);
  };

  useEffect(() => {
    document.title = t("mainTitle", { ns: "libraryPage" });
  }, [i18next.language]);

  useEffect(() => {
    load();
  }, [tab, tagLang]);

  const load = () => {
    if (tab === "podcasts") {
      onGetAllPodcasts().then((response: Array<IPostsInfos>) => {
        _setPodcasts(response);
        onAnalyticsPodcastsPageView();
      });
    } else {
      onGetAllResources().then((response: Array<IPostsInfos>) => {
        _setResources(response);
        onAnalyticsResourcesPageView();
      });
    }
  };

  const [podcastPreview, _setPodcastPreview] = useState<IPostsInfos>();
  const [podcastIndex, setPodcastIndex] = useState<number>(-1);
  const [resourcePreview, _setResourcePreview] = useState<IPostsInfos>();
  const [resourceIndex, setResourceIndex] = useState<number>(-1);

  useEffect(() => {
    if (podcastPreview) {
      setPodcastIndex(
        podcastsFiltered.findIndex((p) => p.id === podcastPreview.id)
      );
    }
  }, [podcastPreview, tagLang, podcastsFiltered]);

  useEffect(() => {
    if (resourcePreview) {
      setResourceIndex(
        resourcesFiltered.findIndex((p) => p.id === resourcePreview.id)
      );
    }
  }, [resourcePreview, tagLang, resourcesFiltered]);

  const handlePostDelete = (postId: number) => {
    const response = confirm(
      `${t("post.confirmDelete", { ns: "circlePage" })}`
    );

    if (response) {
      onDeletePost(postId).then(load);
    }
  };

  // HANDLE POST UPDATE
  const handleUpdatePost = (postId: number) => {
    const post = (tab === "podcasts" ? podcasts : resources).find(
      (i) => i.id === postId
    );
    if (post) {
      const t: ITagsInfos[] = [];

      (post.tags || []).map((tId) => {
        const tagElement = allTags.find((t) => t.id === tId);
        if (tagElement) {
          t.push(tagElement);
        }
      });

      setIsUpdatingPost(post);
      _setMarkdownContent(post.content);
      _setPostFormValues({
        user_id: post.user_id,
        title: post.title,
        content: post.content,
        // @ts-ignore
        files: post.filesHistory || [],
        date_to_publish: new Date(post.date_to_publish),
      });
      _setSendFiles(post.filesHistory || []);
      _setIsCreatingPost(true);
      _setSendTags(t);
    }
  };

  return (
    <Wrapper>
      {/* TOP MENU */}
      <TopMenu
        sections={["podcasts", "resources"]}
        selectedSection={tab}
        fromPage="libraryPage"
        onClick={setTab}
      />

      {isUserAdmin && (
        <div className="mt-8">
          <WritePostStyle>
            <WriteYourPost
              userImage={user.image ? user.image : ""}
              onClick={handleCreatePost}
            />
          </WritePostStyle>
        </div>
      )}

      {tab === "podcasts" && (
        <div>
          {/* TAGS */}
          {/* <div className="pl-8 mt-8 flex flex-row gap-2 flex-wrap">
            {allTags &&
              allTags.map((tag, key) => {
                return (
                  <SelectTagPill
                    key={key}
                    name={tag.name}
                    onClick={() => {
                      handleSelectedTags(tag);
                    }}
                  />
                );
              })}
          </div> */}

          {/* PODCASTS LIST */}
          <div className="m-8 grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-1 gap-x-8 gap-y-8">
            {podcastsFiltered.map((podcast, key) => {
              return (
                <PodcastCard
                  key={key}
                  podcast={podcast}
                  size="25vw"
                  titleSize="24px"
                  paragraphSize="16px"
                  borderSize="16px"
                  playSize={56}
                  onClick={() => {
                    navigate({
                      pathname: getPageUrl(PATH.library),
                      search: `?id=${podcast.id}`,
                    });
                    _setPodcastPreview(podcast);
                  }}
                  handleDeletePost={handlePostDelete}
                  handleUpdatePost={handleUpdatePost}
                />
              );
            })}
          </div>

          {podcastPreview && (
            <FullPodcastPreview
              podcast={podcastPreview}
              isPodcastPreviewOpen={podcastPreview ? true : false}
              onClick={() => {
                _setPodcastPreview(undefined);
              }}
              onReadPrevious={
                podcastIndex <= 0
                  ? null
                  : () => {
                      _setPodcastPreview(podcastsFiltered[podcastIndex - 1]);
                    }
              }
              onReadNext={
                podcastIndex === -1 ||
                podcastIndex + 2 > podcastsFiltered.length
                  ? null
                  : () => {
                      _setPodcastPreview(podcastsFiltered[podcastIndex + 1]);
                    }
              }
            />
          )}
        </div>
      )}

      {tab === "resources" && (
        <div>
          {/* TAGS */}
          <div className="pl-8 mt-8 flex flex-row gap-2 flex-wrap">
            <SelectBt>
              <div className="list">
                <select onChange={(e) => setTagLang(e.target.value)}>
                  <option value="">{t("multilingue", { ns: "common" })}</option>
                  {languages.map((language) => (
                    <option key={language.code} value={language.code}>
                      {language.code.toUpperCase() === "EN" ? "🇬🇧" : "🇫🇷"}{" "}
                      {language.nativeName}
                    </option>
                  ))}
                </select>
              </div>
            </SelectBt>
            {allTags &&
              allTags
                .filter((t) => !t || t.lang === tagLang)
                .map((tag) => {
                  return (
                    <SelectTagPill
                      key={tag.id}
                      name={tag.name}
                      onClick={() => {
                        handleSelectedTags(tag);
                      }}
                    />
                  );
                })}
          </div>
          {/* RESOURCES LIST */}
          <div className="m-8 flex flex-col grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-x-8 gap-y-8">
            {resourcesFiltered.map((resource) => {
              return (
                <ResourceCard
                  key={resource.id}
                  resource={resource}
                  titleSize="24px"
                  onClick={() => {
                    navigate({
                      pathname: getPageUrl(PATH.library),
                      search: `?t=resources&id=${resource.id}`,
                    });
                    _setResourcePreview(resource);
                  }}
                  handleDeletePost={handlePostDelete}
                  handleUpdatePost={handleUpdatePost}
                  langFilter={tagLang}
                />
              );
            })}
          </div>

          {resourcePreview && (
            <FullResourcePreview
              resource={resourcePreview}
              isPodcastPreviewOpen={resourcePreview ? true : false}
              onClick={() => {
                _setResourcePreview(undefined);
              }}
              onReadPrevious={
                resourceIndex <= 0
                  ? null
                  : () => {
                      _setResourcePreview(resourcesFiltered[resourceIndex - 1]);
                    }
              }
              onReadNext={
                resourceIndex === -1 ||
                resourceIndex + 2 > resourcesFiltered.length
                  ? null
                  : () => {
                      _setResourcePreview(resourcesFiltered[resourceIndex + 1]);
                    }
              }
            />
          )}
        </div>
      )}

      {/* ADD POST FORM */}
      {isCreatingPost && (
        <AddPostContent onSubmit={handlePostSubmit}>
          <Content className="header p-8 flex flex-row justify-between">
            <h3 className="font-bold text-xl">
              {t(isUpdatingPost ? "post.updatePost" : "post.write", {
                ns: "circlePage",
              })}
            </h3>
            <a
              className="flex"
              onClick={() => {
                _setSendFiles([]);
                setIsUpdatingPost(null);
                _setPostFormValues(initialPostFormState);
                _setIsCreatingPost(false);
              }}
            >
              <ImCross />
            </a>
          </Content>
          {/* FORM TEXT */}
          <Content className="form gap-4">
            <input
              className="font-bold"
              type="text"
              name="title"
              id="title"
              placeholder={
                t("post.form.placeholders.title", { ns: "circlePage" }) + ""
              }
              value={postFormValues.title ? postFormValues.title : ""}
              onChange={handlePostChange}
            />

            <ReactQuill
              style={{
                border: "1px",
                height: "25vh",
              }}
              value={markdownContent}
              onChange={handleMarkdownChange}
              theme="snow"
            />
          </Content>
          {/* DATE TO PUBLISH */}
          <Content className="date">
            <h3 className="text-base font-bold mb-2">
              {t("post.form.labels.dateToPublish", { ns: "circlePage" })}
            </h3>
            <input
              type="date"
              value={
                new Date(postFormValues.date_to_publish)
                  .toISOString()
                  .split("T")[0]
              }
              name="date_to_publish"
              id="date_to_publish"
              onChange={handlePostChange}
            />
          </Content>
          {/* FORM TAGS ONLY FOR RESOURCES ???? */}
          {tab === "resources" && (
            <Content className="tags">
              <h3 className="text-base font-bold mb-2">
                {t("post.form.labels.tags", { ns: "circlePage" })}
              </h3>
              {allTagsDB && allTagsDB.length === 0 && (
                <p>{t("post.form.labels.noTags", { ns: "circlePage" })}</p>
              )}
              <div className="flex flex-col">
                <div className="flex flex-row gap-4 flex-wrap">
                  {allTagsDB &&
                    allTagsDB.map((tag, key) => {
                      return (
                        <TagPill
                          key={key}
                          name={`${tag.name} (${tag.lang || "Multilange"})`}
                          type="add"
                          onClick={(e: any) => {
                            handleAddTagToList(e, tag);
                          }}
                          onDelete={(e: any) => {
                            handleTagDelete(e, tag.id);
                          }}
                        />
                      );
                    })}
                </div>
              </div>
              <div className="flex justify-center my-8">
                <InputTag className="flex flex-row">
                  <input
                    type="text"
                    placeholder={
                      t("post.form.placeholders.addTag", { ns: "circlePage" }) +
                      ""
                    }
                    name="name"
                    value={tagFormValues.name}
                    onChange={handleTagChange}
                  />
                  <select
                    name="lang"
                    value={tagFormValues.lang}
                    onChange={handleTagChange}
                  >
                    <option value="">Multilange</option>
                    <option value="fr">FR</option>
                    <option value="en">EN</option>
                  </select>
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={handleTagSubmit}
                  >
                    <MdOutlineAddCircleOutline size={24} />
                  </div>
                </InputTag>
              </div>
              <div>
                <h3 className="text-base font-bold mb-2">
                  {t("post.form.labels.selectedTags", { ns: "circlePage" })}
                </h3>
                {sendTags && sendTags.length === 0 && (
                  <p>
                    {t("post.form.labels.noTagsSelected", { ns: "circlePage" })}
                  </p>
                )}
                <div className="flex flex-col">
                  <div className="flex flex-row gap-4 flex-wrap">
                    {sendTags &&
                      sendTags.map((sendTag, key) => {
                        return (
                          <TagPill
                            key={key}
                            name={sendTag.name}
                            type="remove"
                            onClick={(e: any) => {
                              handleRemoveTagToList(e, sendTag);
                            }}
                          />
                        );
                      })}
                  </div>
                </div>
              </div>
            </Content>
          )}

          {/* FORM FILES */}
          <Content className="files">
            {sendFiles && sendFiles.length === 0 && (
              <p>{t("post.form.labels.noFiles", { ns: "circlePage" })}</p>
            )}
            {sendFiles && sendFiles.length !== 0 && (
              <p className="mb-2">
                {t("post.form.labels.files", { ns: "circlePage" })}
              </p>
            )}

            <div className="flex flex-col">
              <div className="flex flex-row gap-4 flex-wrap">
                {sendFiles &&
                  sendFiles.map((sendFile, key) => {
                    return (
                      <FileCard
                        key={key}
                        file={sendFile}
                        onRemoveFile={() => {
                          let upFiles = sendFiles;
                          upFiles.splice(key, 1);
                          _setSendFiles([...upFiles]);
                        }}
                      />
                    );
                  })}
              </div>
            </div>
          </Content>
          <Content className="bottom">
            <div className="left">
              {/* IMAGE INPUT */}
              <div>
                <input
                  type="file"
                  name="imgFile"
                  id="imgFile"
                  className="inputfile"
                  accept="image/*"
                  multiple
                  // {...register("imgFile")}
                  onChange={onImageChange}
                />
                <label htmlFor="imgFile">
                  <RiImageLine size={24} />
                </label>
              </div>
              {/* AUDIO INPUT */}
              {isUserAdmin && (
                <div>
                  <input
                    type="file"
                    name="audioFile"
                    id="audioFile"
                    className="inputfile"
                    accept="audio/*"
                    multiple
                    // {...register("audioFile")}
                    onChange={onAudioChange}
                  />
                  <label htmlFor="audioFile">
                    <AiTwotoneAudio size={23} />
                  </label>
                </div>
              )}
            </div>
            {/* PUBLISH BUTTON */}
            <div className="right">
              <button
                disabled={
                  markdownContent.length === 0 &&
                  postFormValues.title.length === 0
                }
              >
                {!onLoadingSendingPost && t("post.send", { ns: "circlePage" })}
                {onLoadingSendingPost && (
                  <div className="flex flex-row justify-center items-center px-5 py-1 rotating">
                    <AiOutlineLoading3Quarters size={20} />
                  </div>
                )}
              </button>
            </div>
          </Content>
        </AddPostContent>
      )}
    </Wrapper>
  );
};

export default LibraryPage;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div``;

const WritePostStyle = styled.div``;

const AddPostContent = styled.form`
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: fit-content;
  padding-bottom: 7%;
  background-color: rgba(0, 0, 0, 0.66);
`;

const Content = styled.div`
  margin: auto;
  width: 60%;
  background-color: ${WHITE};

  &.header {
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    margin-top: 4%;
    border-bottom: 2px solid ${LIGHT_GREY};

    a {
      max-width: 28px;
      padding: 6px;
      background-color: ${WHITE};
      border-radius: 4px;
      cursor: pointer;
      border: 1px solid ${WHITE};

      :hover {
        background-color: ${LIGHT_GREY};
        border: 1px solid ${GREY};
      }
    }
  }

  &.form {
    font-size: 24px;
    padding: 32px;
    padding-bottom: 86px;
    display: flex;
    flex-direction: column;
    min-height: 55%;
    border-bottom: 2px solid ${LIGHT_GREY};
    align-items: stretch;

    textarea {
      border: none;
      outline: none;
      height: 55%;
    }

    input {
      border: none;
      outline: none;
    }

    input::placeholder {
      font-weight: bold;
    }
  }

  &.files {
    padding: 32px;
    border-bottom: 2px solid ${LIGHT_GREY};
  }

  &.tags {
    padding: 32px;
    border-bottom: 2px solid ${LIGHT_GREY};
  }

  &.date {
    padding: 32px;
    border-bottom: 2px solid ${LIGHT_GREY};
  }

  &.bottom {
    padding: 32px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .left {
      display: flex;
      gap: 4px;

      & > div {
        cursor: pointer;
        margin: 2px;
        padding: 6px;
        display: flex;
        border-radius: 4px;
        border: 1px solid ${WHITE};

        :hover {
          background-color: ${LIGHT_GREY};
          border: 1px solid ${GREY};
        }

        input {
          &.inputfile {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
          }

          &.inputfile + label {
            font-size: 1.25em;
            font-weight: 700;
            display: inline-block;
            margin: auto;
          }

          &.inputfile + label {
            cursor: pointer; /* "hand" cursor */
          }
        }
      }
    }

    .right {
      display: flex;
      flex-direction: row;
      justify-content: end;

      & > button {
        padding: 8px 16px;
        border-radius: 8px;
        font-weight: bold;
        background-color: ${BLUE};
        color: ${WHITE};
        font-size: 18px;
        cursor: pointer;
        border: 1px solid ${BLUE};
        transition: 250ms;

        & .rotating {
          @keyframes rotating {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }
          animation: rotating 2s linear infinite;
        }

        :hover {
          background-color: ${WHITE};
          color: ${BLUE};
          border: 1px solid ${BLUE};
        }
      }

      & > button:disabled {
        cursor: not-allowed;
        background-color: ${CLEAR_GREY};
        color: ${GREY};
        border: 1px solid ${CLEAR_GREY};
      }
    }
  }
`;

const InputTag = styled.div`
  position: relative;

  & > input {
    outline: none;
    border: 1px solid ${LIGHT_GREY};
    width: 100%;
    border-radius: 96px;
    padding: 0px 32px 0px 16px;
    height: 32px;
    font-size: 14px;
    font-weight: bold;

    :focus {
      border: 1px solid ${BLUE};
    }
  }

  & > div {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 4px;
    right: -26px;
    transition: all 25ms ease-in-out;
    color: ${LIGHT_BLUE};

    :hover {
      color: ${BLUE};
    }
  }
`;
const SelectBt = styled.div`
  border: 1px solid ${BLUE};
  height: 56px;
  border-radius: 8px;
  display: inline-flex;
  background-color: ${COLOR_WHITE};
  margin-right: 8px;
  max-width: 350px;
  //width: 50%;

  &:last-child {
    margin-right: 0;
  }

  > .label {
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid ${BLUE};

    p {
      font-size: 16px;
      margin: 0;
      padding: 0;
      color: ${BLUE};
    }
  }

  > .list {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0 16px;

    > select {
      width: 100%;
      color: ${BLUE};
      font-weight: bold;

      &:focus {
        outline: none;
      }
    }

    > input {
      width: 100%;

      &:focus {
        outline: none;
      }
    }
  }
`;
