import React from "react";
import styled from "styled-components";
import { BLUE, CLEAR_BLUE, WHITE } from "../constants/cts_colors";
const Button = ({
  text,
  disabled,
  onClick,
  borderRadiusRounded,
  isBold,
  isAlreadyRegistered,
}: {
  text: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  borderRadiusRounded?: boolean;
  isBold?: boolean;
  isAlreadyRegistered?: boolean;
}) => {
  return (
    <ButtonStyles
      disabled={disabled}
      onClick={onClick}
      style={{
        borderRadius: borderRadiusRounded ? "1000px" : "5px",
        fontWeight: isBold ? "bold" : "400",
        color: isAlreadyRegistered ? BLUE : WHITE,
        backgroundColor: isAlreadyRegistered ? CLEAR_BLUE : BLUE,
        cursor: isAlreadyRegistered ? "not-allowed" : "pointer",
      }}
    >
      {text}
    </ButtonStyles>
  );
};

export default Button;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const ButtonStyles = styled.button`
  border-radius: 5px;
  padding: 13.5px;
  border: 1px solid ${WHITE};
  width: 100%;
  transition: 250ms;

  :disabled {
    opacity: 0.5;

    /* :hover {
      background-color: ${BLUE};
      color: ${WHITE};
      border: 1px solid ${WHITE};
    } */
  }

  :hover {
    background-color: ${WHITE};
    border: 1px solid ${BLUE};
    color: ${BLUE};
  }
`;
