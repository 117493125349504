import { IIaModel, IIaProfil, IIaUser } from "../interfaces/ia";
import { httpError, instanceAxios } from "../utils/axios-api";

export const onAISignInApi = async (email: string): Promise<IIaUser | null> => {
  return instanceAxios
    .post(`/ia/sign-in`, { email })
    .then((response) => (response.data ? response.data.data : null))
    .catch(httpError);
};

export const onModelListApi = async (): Promise<IIaModel[]> => {
  return instanceAxios
    .get(`/ia/model-list`)
    .then((response) => (response.data ? response.data.data : []))
    .catch(httpError);
};

export const onLoadProfilsApi = async (
  userId: number,
  modelId: number
): Promise<IIaProfil[]> => {
  return instanceAxios
    .post(`/ia/get-profil`, { userId, modelId })
    .then((response) => (response.data ? response.data.data : []))
    .catch(httpError);
};

export const onAskMeProfilApi = async (
  userId: number,
  modelId: number
): Promise<string | null> => {
  return instanceAxios
    .post(`/ia/ask-me-profil`, { userId, modelId })
    .then((response) => (response.data ? response.data.data : null))
    .catch(httpError);
};

export const completeMyProfilApi = async (
  userId: number,
  modelId: number,
  question: string,
  message: string
): Promise<string | null> => {
  return instanceAxios
    .post(`/ia/complete-my-profil`, {
      userId,
      modelId,
      question,
      userResponse: message,
    })
    .then((response) => (response.data ? response.data.data : null))
    .catch(httpError);
};

export const converseApi = async (
  userId: number,
  modelId: number,
  question: string,
  message: string
): Promise<string | null> => {
  return instanceAxios
    .post(`/ia/converse`, { userId, modelId, userResponse: message, question })
    .then((response) => (response.data ? response.data.data : null))
    .catch(httpError);
};

export const getQuestionApi = async (
  userId: number,
  modelId: number
): Promise<string | null> => {
  return instanceAxios
    .post(`/ia/get-question`, { userId, modelId })
    .then((response) => (response.data ? response.data.data : null))
    .catch(httpError);
};
