export const COLOR_BACKGROUND_BODY = "#F9F9F9";
export const COLOR_WHITE = "#FFFFFF";
export const COLOR_BLACK = "#2E3238";
export const COLOR_GRADIENT_1 = "#B446FF";
export const COLOR_GRADIENT_2 = "#6F68FE";
export const COLOR_SPECIAL = "#6F67FE";
export const COLOR_GREY = "#999999";
export const COLOR_GREY_LIGHT = "#C6C6C6";
export const COLOR_FORM_INFO_MESSAGE = "#696980";

export const BLUE = "#3D3DEA";
export const LIGHT_BLUE = "#CFE1F9";
export const CLEAR_BLUE = "#F5F5FF";
export const GREEN = "#17DD7E";
export const LIGHT_GREEN = "#C7EAD8";
export const RED = "#EB5A65";
export const PURPLE = "#9747FF";
export const LIGHT_RED = "#DEDEFF";
export const ORANGE = "#FFB602";
export const BLACK = "#2E3238";
export const GREY = "#A3ACB9";
export const LIGHT_GREY = "#D9DCE1";
export const CLEAR_GREY = "#F6F6F6";
export const WHITE = "#FFFFFF";
export const DARK_GREY = "#696980";
