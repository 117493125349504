import React, { ChangeEvent, FormEvent, useState } from "react";
import styled from "styled-components";
import {
  BLACK,
  BLUE,
  CLEAR_BLUE,
  GREY,
  WHITE,
} from "../../constants/cts_colors";
import { IAccountInfosFormData, IUserInfos } from "../../interfaces/user";
import penIconBlue from "../../assets/images/form/pen-blue.png";
import penIconGrey from "../../assets/images/form/pen-grey.png";
import InputWithLabel from "../../components/form/InputWithLabel";
import { useTranslation } from "react-i18next";
import Modal from "../../components/Modal";
import ChangePasswordForm from "./changePasswordForm";
import { useUser } from "../../common/contexts/userContext";
import { CHANGE_SAVED } from "../../constants/cts_infoMessages";
import Button from "../../components/Button";
import { MdLoop } from "react-icons/md";

const AccountInfos = ({
  user,
  loadUser,
}: {
  user: IUserInfos;
  loadUser: Function;
}) => {
  const { t } = useTranslation();
  const [formData, _setFormData] = useState<IAccountInfosFormData>({
    name: {
      inputName: "name",
      value: user.firstName + " " + user.lastName,
      error: "",
    },
    email: {
      inputName: "email",
      value: user.email,
      error: "",
    },
  });
  const [infoMessage, _setInfoMessage] = useState<string>("");
  const { onEditAccount, onChangeProfileImage } = useUser();
  const [newProfileImage, _setNewProfileImage] = useState<File | null>(null);
  const [openModalChangePassword, _setOpenModalChangePassword] =
    useState<boolean>(false);

  // GET THE FIRST NAME AND LAST NAME FROM THE FULL NAME
  const getFirstNameAndLastNameFromFullName = (fullName: string) => {
    let firstName = "";
    let lastName = "";
    if (fullName) {
      const nameArray = fullName.trim().split(" ");

      for (let i = 0; i < nameArray.length; i++) {
        // the first element in the array should be the first name
        if (i === 0) {
          firstName = nameArray[i];
        } else {
          // the last element in the array should be the last name
          if (i === nameArray.length - 1) {
            lastName = nameArray[i];
          } else {
            // add the middle elements (if there are) to the first name
            firstName += " " + nameArray[i];
          }
        }
      }
    }

    return {
      firstName,
      lastName,
    };
  };

  // HANDLE CHANGE
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let input = null;

    switch (name) {
      case formData.name.inputName:
        input = formData.name;
        break;
      case formData.email.inputName:
        input = formData.email;
        break;
    }

    if (input) {
      _setFormData({
        ...formData,
        [input.inputName]: {
          ...(formData as any)[input.inputName],
          value,
          error: "",
        },
      });
      _setInfoMessage("");
    }
  };

  // HANDLE CLICK ICON
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const { firstName, lastName } = getFirstNameAndLastNameFromFullName(
      formData.name.value
    );

    onEditAccount({
      userId: user.id,
      firstName: {
        inputName: formData.name.inputName,
        value: firstName,
      },
      lastName: {
        inputName: formData.name.inputName,
        value: lastName,
      },
      email: {
        inputName: formData.email.inputName,
        value: formData.email.value,
      },
    })
      .then(() => {
        _setInfoMessage("" + t(CHANGE_SAVED, { ns: "infoMessages" }));
        loadUser();
      })
      .catch((error: { inputName: string; message: string }) => {
        if (error.inputName) {
          _setFormData({
            ...formData,
            [error.inputName]: {
              ...(formData as any)[error.inputName],
              error: error.message,
            },
          });
        } else {
          console.error(error);
        }
      });
  };

  // CHANGE PROFILE IMAGE
  const changeProfileImage = () => {
    if (newProfileImage) {
      onChangeProfileImage({ userId: user.id, newProfileImage })
        .then(() => {
          _setNewProfileImage(null);
          loadUser();
        })
        .catch((error) => console.error(error));
    }
  };

  return (
    <Wrapper>
      {/* Image and name */}
      <ImageAndName>
        <ImageContainer>
          <ImageFrame>
            {user.image && <img src={user.image} alt="profile" />}
          </ImageFrame>
          <ChangeImage htmlFor="profileImage">
            <img src={penIconBlue} />
          </ChangeImage>
          <input
            type="file"
            hidden
            id="profileImage"
            onChange={(e) =>
              _setNewProfileImage(
                e.target.files && e.target.files.length > 0
                  ? e.target.files[0]
                  : null
              )
            }
            multiple={false}
          />
        </ImageContainer>

        <Name>{user.firstName + " " + user.lastName}</Name>
      </ImageAndName>

      {/* Form */}
      <Form onSubmit={(e) => handleSubmit(e)}>
        {/* name */}
        <InputWithLabel
          label={t("accountInfos.form.labels.name", { ns: "accountPage" })}
          placeholder={t("accountInfos.form.placeholders.name", {
            ns: "accountPage",
          })}
          icon={<img src={penIconGrey} />}
          name={formData.name.inputName}
          value={formData.name.value}
          onChange={(e) => handleChange(e)}
          error={formData.name.error}
        />
        {/* email */}
        <InputWithLabel
          label={t("accountInfos.form.labels.email", { ns: "accountPage" })}
          placeholder={t("accountInfos.form.placeholders.email", {
            ns: "accountPage",
          })}
          icon={<img src={penIconGrey} />}
          name={formData.email.inputName}
          value={formData.email.value}
          onChange={(e) => handleChange(e)}
          error={formData.email.error}
        />
        {infoMessage && <FormInfoMessage>{infoMessage}</FormInfoMessage>}
        <Button
          text={t("myFile.form.saveBtn", { ns: "accountPage" })}
          onClick={(e) => handleSubmit(e)}
        />
      </Form>

      {/* password */}
      <Form>
        <InputWithLabel
          label={t("accountInfos.form.labels.password", { ns: "accountPage" })}
          placeholder={t("accountInfos.form.placeholders.password", {
            ns: "accountPage",
          })}
          icon={
            <InputIconContainer>
              <MdLoop />
            </InputIconContainer>
          }
          onClickIcon={() => _setOpenModalChangePassword(true)}
          onChange={() => {}}
          disabled
        />
      </Form>

      {/* change password modal */}
      {openModalChangePassword && (
        <Modal
          onClose={() => _setOpenModalChangePassword(false)}
          maxHeight="50vh"
        >
          <ChangePasswordForm user={user} />
        </Modal>
      )}

      {/* confirm profile image modal */}
      {newProfileImage && (
        <Modal onClose={() => _setNewProfileImage(null)} maxHeight="50vh">
          <ConfirmProfileImageContainer>
            <ConfirmProfileImageTitle>
              {t("accountInfos.confirmNewProfileImage.title", {
                ns: "accountPage",
              })}
            </ConfirmProfileImageTitle>

            <ImageContainer>
              <ImageFrame>
                <img src={URL.createObjectURL(newProfileImage)} alt="profile" />
              </ImageFrame>
            </ImageContainer>

            <ConfirmProfileButtons>
              <Button
                text={t("accountInfos.confirmNewProfileImage.confirm", {
                  ns: "accountPage",
                })}
                onClick={changeProfileImage}
              />
              <button onClick={() => _setNewProfileImage(null)}>
                {t("accountInfos.confirmNewProfileImage.cancel", {
                  ns: "accountPage",
                })}
              </button>
            </ConfirmProfileButtons>
          </ConfirmProfileImageContainer>
        </Modal>
      )}
    </Wrapper>
  );
};

export default AccountInfos;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 56px;
`;

const ImageAndName = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 24px;
`;

const ImageContainer = styled.div`
  position: relative;
  min-width: 80px;
  max-width: 112px;
  border-radius: 50%;
  background-color: ${CLEAR_BLUE};
`;

const ImageFrame = styled.div`
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;

    // prevent dragging
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
`;

const ChangeImage = styled.label`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 32px;
  height: 32px;
  border: 1px solid ${BLUE};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${WHITE};
  cursor: pointer;
  transition: 250ms background-color;

  &:hover {
    background-color: ${BLUE};
  }

  img {
    display: block;
    width: 20px;
    height: 20px;

    // prevent dragging
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    // shadow
    -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
`;
const Name = styled.span`
  font-size: 24px;
  font-weight: bold;
  color: ${BLUE};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;
  overflow: auto;
  padding-right: 5px;
`;

const ConfirmProfileImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

const ConfirmProfileImageTitle = styled.h3`
  text-align: center;
  color: ${BLACK};
  font-weight: bold;
  font-size: 20px;
`;

const ConfirmProfileButtons = styled.div`
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const FormInfoMessage = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: ${GREY};
`;

const InputIconContainer = styled.div`
  cursor: pointer;
  color: ${BLACK};
`;
