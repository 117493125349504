import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import DashboardPodcasts from "../../components/DashboardPodcasts";
import { usePosts } from "../../common/contexts/postContext";
import { IPostsInfos } from "../../interfaces/posts";
import DashboardLastPost from "../../components/DashboardLastPost";
import i18next from "i18next";

const DashboardPage = () => {
  const { t } = useTranslation();

  const [podcasts, _setPodcasts] = useState<Array<IPostsInfos>>([]);
  const [resources, _setResources] = useState<Array<IPostsInfos>>([]);
  const [lastPost, _setLastPost] = useState<IPostsInfos>();
  const { onGetRecentsPodcasts, onGetRecentsResource, onGetLastPost } =
    usePosts();

  useEffect(() => {
    onGetRecentsPodcasts().then((response: any) => {
      _setPodcasts(response);
    });

    onGetRecentsResource().then((response: any) => {
      _setResources(response);
    });

    onGetLastPost().then((response: any) => {
      _setLastPost(response);
    });
  }, []);

  useEffect(() => {
    document.title = t("mainTitle", { ns: "dashboardPage" });
  }, [i18next.language]);

  return (
    <Wrapper>
      <div className="p-3 lg:p-8 flex flex-col gap-4 lg:gap-8">
        <DashboardPodcasts podcasts={podcasts} resources={resources} />
        {lastPost && <DashboardLastPost post={lastPost} />}
      </div>
    </Wrapper>
  );
};

export default DashboardPage;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div``;
