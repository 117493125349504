import React from "react";
import AppRoutes from "./routes/routes";
import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";


function App() {
  return (
    <div>
      <Wrapper>
        <AppRoutes />
      </Wrapper>
    </div>
  );
}

export default App;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div`
  font-family: "League Spartan", sans-serif;
  position: relative;
  height: 100vh;
  width: 100vw;
`;
