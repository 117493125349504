import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import penIconGrey from "../../assets/images/form/pen-grey.png";
import InputWithLabel from "../../components/form/InputWithLabel";
import { useTranslation } from "react-i18next";
import { IUserInfos, IUserModulesMessages } from "../../interfaces/user";
import { useUser } from "../../common/contexts/userContext";
import TextareaWithLabel from "../../components/form/TextareaWithLabel";
import { BLACK, BLUE, LIGHT_GREY, WHITE } from "../../constants/cts_colors";
import SectionDivider from "../../components/SectionDivider";
import Input from "../../components/form/Input";
import { MOBILE_WIDTH } from "../../constants/cts_sizes";
import { MdOutlineFileDownload } from "react-icons/md";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import PATH from "../../constants/cts_routes";
import { IFormationsInfos } from "../../interfaces/formations";
import { useFormations } from "../../common/contexts/formationContext";

const ModulesMessages = ({ user }: { user: IUserInfos }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.workshop_remember
  //         ? user.journeyTrackingValues.workshop_remember
  //         : "",
  //   },
  //   workshop_implement1: {
  //     inputName: "workshop_implement1",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.workshop_implement1
  //         ? user.journeyTrackingValues.workshop_implement1
  //         : "",
  //   },
  //   workshop_implement2: {
  //     inputName: "workshop_implement2",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.workshop_implement2
  //         ? user.journeyTrackingValues.workshop_implement2
  //         : "",
  //   },
  //   workshop_implement3: {
  //     inputName: "workshop_implement3",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.workshop_implement3
  //         ? user.journeyTrackingValues.workshop_implement3
  //         : "",
  //   },
  //   module1_remember1: {
  //     inputName: "module1_remember1",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.module1_remember1
  //         ? user.journeyTrackingValues.module1_remember1
  //         : "",
  //   },
  //   module1_remember2: {
  //     inputName: "module1_remember2",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.module1_remember2
  //         ? user.journeyTrackingValues.module1_remember2
  //         : "",
  //   },
  //   module1_remember3: {
  //     inputName: "module1_remember3",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.module1_remember3
  //         ? user.journeyTrackingValues.module1_remember3
  //         : "",
  //   },
  //   module1_implement1: {
  //     inputName: "module1_implement1",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.module1_implement1
  //         ? user.journeyTrackingValues.module1_implement1
  //         : "",
  //   },
  //   module1_implement2: {
  //     inputName: "module1_implement2",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.module1_implement2
  //         ? user.journeyTrackingValues.module1_implement2
  //         : "",
  //   },
  //   module1_implement3: {
  //     inputName: "module1_implement3",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.module1_implement3
  //         ? user.journeyTrackingValues.module1_implement3
  //         : "",
  //   },
  //   module2_remember1: {
  //     inputName: "module2_remember1",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.module2_remember1
  //         ? user.journeyTrackingValues.module2_remember1
  //         : "",
  //   },
  //   module2_remember2: {
  //     inputName: "module2_remember2",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.module2_remember2
  //         ? user.journeyTrackingValues.module2_remember2
  //         : "",
  //   },
  //   module2_remember3: {
  //     inputName: "module2_remember3",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.module2_remember3
  //         ? user.journeyTrackingValues.module2_remember3
  //         : "",
  //   },
  //   module2_implement1: {
  //     inputName: "module2_implement1",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.module2_implement1
  //         ? user.journeyTrackingValues.module2_implement1
  //         : "",
  //   },
  //   module2_implement2: {
  //     inputName: "module2_implement2",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.module2_implement2
  //         ? user.journeyTrackingValues.module2_implement2
  //         : "",
  //   },
  //   module2_implement3: {
  //     inputName: "module2_implement3",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.module2_implement3
  //         ? user.journeyTrackingValues.module2_implement3
  //         : "",
  //   },
  //   module3_remember1: {
  //     inputName: "module3_remember1",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.module3_remember1
  //         ? user.journeyTrackingValues.module3_remember1
  //         : "",
  //   },
  //   module3_remember2: {
  //     inputName: "module3_remember2",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.module3_remember2
  //         ? user.journeyTrackingValues.module3_remember2
  //         : "",
  //   },
  //   module3_remember3: {
  //     inputName: "module3_remember3",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.module3_remember3
  //         ? user.journeyTrackingValues.module3_remember3
  //         : "",
  //   },
  //   module3_implement1: {
  //     inputName: "module3_implement1",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.module3_implement1
  //         ? user.journeyTrackingValues.module3_implement1
  //         : "",
  //   },
  //   module3_implement2: {
  //     inputName: "module3_implement2",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.module3_implement2
  //         ? user.journeyTrackingValues.module3_implement2
  //         : "",
  //   },
  //   module3_implement3: {
  //     inputName: "module3_implement3",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.module3_implement3
  //         ? user.journeyTrackingValues.module3_implement3
  //         : "",
  //   },
  //   module4_remember1: {
  //     inputName: "module4_remember1",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.module4_remember1
  //         ? user.journeyTrackingValues.module4_remember1
  //         : "",
  //   },
  //   module4_remember2: {
  //     inputName: "module4_remember2",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.module4_remember2
  //         ? user.journeyTrackingValues.module4_remember2
  //         : "",
  //   },
  //   module4_remember3: {
  //     inputName: "module4_remember3",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.module4_remember3
  //         ? user.journeyTrackingValues.module4_remember3
  //         : "",
  //   },
  //   module4_implement1: {
  //     inputName: "module4_implement1",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.module4_implement1
  //         ? user.journeyTrackingValues.module4_implement1
  //         : "",
  //   },
  //   module4_implement2: {
  //     inputName: "module4_implement2",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.module4_implement2
  //         ? user.journeyTrackingValues.module4_implement2
  //         : "",
  //   },
  //   module4_implement3: {
  //     inputName: "module4_implement3",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.module4_implement3
  //         ? user.journeyTrackingValues.module4_implement3
  //         : "",
  //   },
  //   module5_remember1: {
  //     inputName: "module5_remember1",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.module5_remember1
  //         ? user.journeyTrackingValues.module5_remember1
  //         : "",
  //   },
  //   module5_remember2: {
  //     inputName: "module5_remember2",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.module5_remember2
  //         ? user.journeyTrackingValues.module5_remember2
  //         : "",
  //   },
  //   module5_remember3: {
  //     inputName: "module5_remember3",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.module5_remember3
  //         ? user.journeyTrackingValues.module5_remember3
  //         : "",
  //   },
  //   module5_implement1: {
  //     inputName: "module5_implement1",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.module5_implement1
  //         ? user.journeyTrackingValues.module5_implement1
  //         : "",
  //   },
  //   module5_implement2: {
  //     inputName: "module5_implement2",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.module5_implement2
  //         ? user.journeyTrackingValues.module5_implement2
  //         : "",
  //   },
  //   module5_implement3: {
  //     inputName: "module5_implement3",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.module5_implement3
  //         ? user.journeyTrackingValues.module5_implement3
  //         : "",
  //   },
  //   module6_remember1: {
  //     inputName: "module6_remember1",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.module6_remember1
  //         ? user.journeyTrackingValues.module6_remember1
  //         : "",
  //   },
  //   module6_remember2: {
  //     inputName: "module6_remember2",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.module6_remember2
  //         ? user.journeyTrackingValues.module6_remember2
  //         : "",
  //   },
  //   module6_remember3: {
  //     inputName: "module6_remember3",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.module6_remember3
  //         ? user.journeyTrackingValues.module6_remember3
  //         : "",
  //   },
  //   module6_implement1: {
  //     inputName: "module6_implement1",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.module6_implement1
  //         ? user.journeyTrackingValues.module6_implement1
  //         : "",
  //   },
  //   module6_implement2: {
  //     inputName: "module6_implement2",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.module6_implement2
  //         ? user.journeyTrackingValues.module6_implement2
  //         : "",
  //   },
  //   module6_implement3: {
  //     inputName: "module6_implement3",
  //     value:
  //       user.journeyTrackingValues &&
  //       user.journeyTrackingValues.module6_implement3
  //         ? user.journeyTrackingValues.module6_implement3
  //         : "",
  //   },
  // });

  const [moduleFormData, _setModuleFormData] = useState<any>({});

  const [allUserModulesMessages, _setAllUserModulesMessages] = useState<
    Array<IUserModulesMessages>
  >([]);

  const { onEditUserModuleMessage } = useUser();
  const { onGetAllUserFormations } = useFormations();

  const [infoMessage, _setInfoMessage] = useState<string>("");
  const [isLoading, _setIsLoading] = useState<boolean>(false);

  const [formations, _setFormations] = useState<Array<IFormationsInfos>>([]);
  const [modules, _setModules] = useState<Array<any>>([]);

  const captureRef = useRef<HTMLDivElement>(null);

  // onChange inputs
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    // console.log("NAME ", name, " VALUE ", value);

    onEditUserModuleMessage({
      user_id: user.id,
      module_id: name.split("-")[2],
      type: name.split("-")[0] + "-" + name.split("-")[1],
      message: value,
    })
      .then((res: any) => {
        console.log("RES : ", res);
      })
      .catch((err: any) => {
        console.log(err);
      });

    // console.log("ID ", name.split("-")[2]);

    // UPDATE OLD VALUES TO NEW IF MODULE EXISTS IN DB
    if (name.split("-")[2]) {
      const newUserModuleMessage = allUserModulesMessages.map(
        (userModuleMessage) => {
          if (
            userModuleMessage.module_id === Number(name.split("-")[2]) &&
            userModuleMessage.type ===
              name.split("-")[0] + "-" + name.split("-")[1]
          ) {
            return { ...userModuleMessage, message: value };
          }
          return userModuleMessage;
        }
      );
      _setAllUserModulesMessages(newUserModuleMessage);
    }
  };

  // handleValue
  const handleValue = (module: any, type: string) => {
    const findModule = allUserModulesMessages.find((userModule) => {
      return userModule.module_id === module.id && userModule.type === type;
    });

    if (findModule) {
      return findModule.message;
    }

    _setAllUserModulesMessages([
      ...allUserModulesMessages,
      {
        id: 0,
        user_id: user.id,
        module_id: module.id,
        type: type,
        message: "",
      },
    ]);

    return "";
  };

  // onDownload PDF
  const onDownloadPDF = async () => {
    _setIsLoading(true);

    // HTML2CANVAS
    const element = captureRef.current;

    if (element) {
      const pdf = new jsPDF("p", "mm", "a4");
      const pageHeight = 297; // Hauteur A4 en mm
      const pageWidth = 210; // Largeur A4 en mm
      const margin = 20; // Marge gauche et droite en mm
      const imgWidth = pageWidth - margin * 2; // Largeur de l'image avec marge latérale
      let position = 8; // Position verticale initiale
      const verticalMargin = 8; // Marge verticale entre les composants .pdf-component

      // Boucle sur chaque composant pour le capturer et l'ajouter au PDF
      const components = element.querySelectorAll(".pdf-component");

      for (let i = 0; i < components.length; i++) {
        const component = components[i] as HTMLElement;

        // Capture le composant avec html2canvas
        const canvas = await html2canvas(component, {
          scale: 4,
          useCORS: true,
        });

        const imgData = canvas.toDataURL("image/png");

        // Calcul de la hauteur de l'image pour l'ajuster au PDF
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        // Si le composant dépasse la hauteur de la page, ajoute une nouvelle page
        if (position + imgHeight > pageHeight) {
          pdf.addPage();
          position = 8; // Réinitialise la position en haut de la nouvelle page
        }

        // Ajouter l'image au PDF avec une marge gauche et droite
        pdf.addImage(imgData, "PNG", margin, position, imgWidth, imgHeight);
        position += imgHeight; // Met à jour la position pour le composant suivant

        // Ajouter la marge verticale après chaque composant
        position += verticalMargin;
      }

      // Sauvegarde le PDF
      pdf.save(
        `${t("journeyTracking.downloadPDFName", { ns: "teachupPage" })}.pdf`
      );
    }

    _setIsLoading(false);
  };

  useEffect(() => {
    onGetAllUserFormations()
      .then((response) => {
        _setFormations(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    // console.log("FORMATIONS ", formations);
    formations.map((formation) => {
      formation.modules.map((formMod) => {
        _setModules((modules) => [formMod, ...modules]);
      });
    });

    if (user) {
      let tmpModules = [];

      // console.log("USER MODULES MESSAGES : ", user.modulesMessages);
      if (user.modulesMessages) {
        for (let i = 0; i < user.modulesMessages.length; i++) {
          // console.log("MODULE USER ", user.modulesMessages[i]);
          tmpModules.push(user.modulesMessages[i]);
        }
      }

      _setAllUserModulesMessages(tmpModules);
    }
  }, [formations]);

  useEffect(() => {
    let tmpFormData = {};

    // console.log("MODULES ", modules);

    modules.map((module) => {
      tmpFormData = {
        ...tmpFormData,
        "first-left-module.id": {
          inputName: `first-left-${module.id}`,
          value: "",
        },
        "second-left-module.id": {
          inputName: `second-left-${module.id}`,
          value: "",
        },
        "third-left-module.id": {
          inputName: `third-left-${module.id}`,
          value: "",
        },
        "first-right-module.id": {
          inputName: `first-right-${module.id}`,
          value: "",
        },
        "second-right-module.id": {
          inputName: `second-right-${module.id}`,
          value: "",
        },
        "third-right-module.id": {
          inputName: `third-right-${module.id}`,
          value: "",
        },
      };
    });

    _setModuleFormData(tmpFormData);

    // console.log(modules);
  }, [modules]);

  return (
    <SectionContent className="w-full">
      {/* DOWNLOAD BUTTON */}
      <DownloadButton>
        <button onClick={onDownloadPDF} disabled={modules.length === 0}>
          {!isLoading && (
            <>
              <MdOutlineFileDownload size={24} />
              <p>
                {t("journeyTracking.downloadButton", { ns: "teachupPage" })}
              </p>
            </>
          )}
          {isLoading && (
            <>
              <AiOutlineLoading3Quarters size={24} className="rotate" />
              <p>{t("journeyTracking.downloadText", { ns: "teachupPage" })}</p>
            </>
          )}
        </button>
      </DownloadButton>
      {/* DOWNLOAD PDF CONTENT */}
      <DownloadContent ref={captureRef}>
        {modules.map((module, index) => {
          return (
            <div key={index}>
              <ModuleSection className="pdf-component">
                <h1>{module.title}</h1>
                <InputsSection>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 4,
                    }}
                  >
                    <TextareaWithLabel
                      label={module.left_subtitle}
                      placeholder={t("journeyTracking.placeholders", {
                        ns: "teachupPage",
                      })}
                      icon={<img src={penIconGrey} />}
                      onChange={handleChange}
                      name={`first-left-${module.id}`}
                      value={handleValue(module, "first-left")}
                      autoHeight={true}
                    />
                    {/* <TextareaWithLabel
                      placeholder={t("journeyTracking.placeholders", {
                        ns: "teachupPage",
                      })}
                      onChange={handleChange}
                      name={`second-left-${module.id}`}
                      value={handleValue(module, "second-left")}
                      autoHeight={true}
                    />
                    <TextareaWithLabel
                      placeholder={t("journeyTracking.placeholders", {
                        ns: "teachupPage",
                      })}
                      onChange={handleChange}
                      name={`third-left-${module.id}`}
                      value={handleValue(module, "third-left")}
                      autoHeight={true}
                    /> */}
                  </div>
                  <div
                    style={{ display: "flex", flexDirection: "column", gap: 4 }}
                  >
                    <TextareaWithLabel
                      label={module.right_subtitle}
                      placeholder={t("journeyTracking.placeholders", {
                        ns: "teachupPage",
                      })}
                      icon={<img src={penIconGrey} />}
                      onChange={handleChange}
                      name={`first-right-${module.id}`}
                      value={handleValue(module, "first-right")}
                      autoHeight={true}
                    />
                    {/* <TextareaWithLabel
                      placeholder={t("journeyTracking.placeholders", {
                        ns: "teachupPage",
                      })}
                      onChange={handleChange}
                      name={`second-right-${module.id}`}
                      value={handleValue(module, "second-right")}
                      autoHeight={true}
                    />
                    <TextareaWithLabel
                      placeholder={t("journeyTracking.placeholders", {
                        ns: "teachupPage",
                      })}
                      onChange={handleChange}
                      name={`third-right-${module.id}`}
                      value={handleValue(module, "third-right")}
                      autoHeight={true}
                    /> */}
                  </div>
                </InputsSection>
              </ModuleSection>
              {index !== modules.length - 1 && <SectionDivider />}
            </div>
          );
        })}

        {modules && modules.length < 1 && (
          <div className="text-center font-semibold text-xl">
            {t("journeyTracking.noneModules", { ns: "teachupPage" })}
          </div>
        )}
      </DownloadContent>
    </SectionContent>
  );
};

export default ModulesMessages;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const SectionContent = styled.div`
  margin-bottom: 80px;
`;

const DownloadButton = styled.div`
  margin: 24px 32px;
  display: flex;
  justify-content: end;

  button {
    padding: 16px;
    background-color: ${BLUE};
    border-radius: 1000px;
    color: ${WHITE};
    font-size: 16px;
    font-weight: bold;
    border: 1px solid ${BLUE};
    transition: all 250ms;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    :disabled {
      opacity: 0.6;
    }

    :active:hover {
      background-color: ${WHITE};
      color: ${BLUE};
    }

    @media (max-width: ${MOBILE_WIDTH}px) {
      width: 100%;
      justify-content: center;
      align-items: center;
    }
  }

  .rotate {
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;

    @-moz-keyframes spin {
      100% {
        -moz-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      100% {
        -webkit-transform: rotate(360deg);
      }
    }
    @keyframes spin {
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }

  @media (max-width: ${MOBILE_WIDTH}px) {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: center;
  }
`;

const ModuleSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputsSection = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 32px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  > div {
    flex-basis: 50%;
  }
`;

const DownloadContent = styled.div`
  background-color: ${WHITE};
  border-radius: 8px;
  margin: auto;
  border: 1px solid ${BLUE};
  padding: 40px;
  max-width: 75%;

  label {
    color: ${BLUE};
    font-weight: bold;
    font-size: 16px;
  }

  div {
    h1 {
      font-size: 24px;
      font-weight: bold;
      color: ${BLACK};
      margin-bottom: 24px;
    }
  }

  @media (max-width: ${MOBILE_WIDTH}px) {
    margin: 40px 24px 24px 24px;
    max-width: 100%;
  }
`;

const PopupContent = styled.div`
  z-index: 15;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);

  & > span {
    color: ${WHITE};

    :hover {
      color: ${LIGHT_GREY};
    }
  }
`;
