import React from "react";
import { AppProvider } from "./appContext";
import { AuthenticationProvider } from "./authenticationContext";
import { UserProvider } from "./userContext";
import { ItemsProvider } from "./itemContext";
import { PaymentProvider } from "./paymentContext";
import { FormationsProvider } from "./formationContext";
import { CompaniesProvider } from "./companyContext";
import { MembersProvider } from "./memberContext";
import { CoachsProvider } from "./coachContext";
import { GroupsProvider } from "./groupContext";
import { PostsProvider } from "./postContext";
import { LikesProvider } from "./likeContext";
import { CommentsProvider } from "./commentContext";
import { FilesProvider } from "./fileContext";
import { FavoritesProvider } from "./favoriteContext";
import { SettingProvider } from "./settingsContext";
import { TagsProvider } from "./tagContext";
import { AnalyticLogsProvider } from "./analyticLogContext";
import { IaProvider } from "./iaContext";

const Providers = (props: any) => {
  return (
    <AnalyticLogsProvider>
      <AuthenticationProvider>
        <SettingProvider>
          <PaymentProvider>
            <UserProvider>
              <AppProvider>
                <ItemsProvider>
                  <FormationsProvider>
                    <CompaniesProvider>
                      <MembersProvider>
                        <CoachsProvider>
                          <GroupsProvider>
                            <PostsProvider>
                              <LikesProvider>
                                <CommentsProvider>
                                  <FilesProvider>
                                    <FavoritesProvider>
                                      <IaProvider>
                                        <TagsProvider {...props} />
                                      </IaProvider>
                                    </FavoritesProvider>
                                  </FilesProvider>
                                </CommentsProvider>
                              </LikesProvider>
                            </PostsProvider>
                          </GroupsProvider>
                        </CoachsProvider>
                      </MembersProvider>
                    </CompaniesProvider>
                  </FormationsProvider>
                </ItemsProvider>
              </AppProvider>
            </UserProvider>
          </PaymentProvider>
        </SettingProvider>
      </AuthenticationProvider>
    </AnalyticLogsProvider>
  );
};

export default Providers;
