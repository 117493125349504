import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { LIGHT_GREY, GREY, WHITE, BLUE } from "../constants/cts_colors";

import { useTranslation } from "react-i18next";
import SearchAndAddContainer from "./SearchAndAddContainer";
import { useFormations } from "../common/contexts/formationContext";
import { IFormationsInfos } from "../interfaces/formations";
import FormationCard from "./FormationCard";
import { ImCross } from "react-icons/im";
import Button from "./Button";
import { useForm } from "react-hook-form";
import DeleteButton from "./DeleteButton";
import { IoAddCircle } from "react-icons/io5";
import { IoMdRemoveCircle } from "react-icons/io";

const SettingsTeachup = ({}: {}) => {
  const { t } = useTranslation();

  const [noResultsSentence, _setNoResultsSentence] = useState<string>("");

  const initialFormulaireFormState = {
    title: "",
    image: "",
    language: "french",
    link: "",
  };

  // ***** TEACHUP FORMULAIRE *****
  const [formulaireFormValues, _setFormulaireFormValues] = useState(
    initialFormulaireFormState
  );

  const { register, getValues } = useForm();

  const {
    onCreateFormation,
    onGetAllFormations,
    onGetFormation,
    onUpdateFormation,
    onGetSearchFormations,
    onDeleteFormation,
  } = useFormations();

  const [formations, _setFormations] = useState<Array<IFormationsInfos>>([]);

  const [idUpdateFormation, _setIdUpdateFormation] = useState<number>();

  const refCreationFormulairePopup = useRef<HTMLDivElement>(null);
  const [isCreationFormationPopupOpen, _setIsCreationFormationPopupOpen] =
    useState<boolean>(false);
  const [isUpdateFormationPopupOpen, _setIsUpdateFormationPopupOpen] =
    useState<boolean>(false);

  // ***** FORMATION FUNCTIONS *****
  // FORMATION CHANGE
  const handleFormulaireChange = (e: any) => {
    const { name, value } = e.target;
    _setFormulaireFormValues({
      ...formulaireFormValues,
      [name]: value,
    });
  };

  // FORMATION SUBMIT
  const handleFormulaireSubmit = (e: React.FormEvent) => {
    _setFormulaireFormValues(initialFormulaireFormState);
    _setIsUpdateFormationPopupOpen(false);
    _setIsCreationFormationPopupOpen(false);
    e.preventDefault();

    // console.log("FORMVALUES ", formulaireFormValues);

    if (isCreationFormationPopupOpen) {
      onCreateFormation({
        title: formulaireFormValues.title,
        image:
          getValues("image") && getValues("image").length
            ? getValues("image")[0]
            : null,
        language: formulaireFormValues.language,
        link: formulaireFormValues.link,
        modules,
      })
        .then(() => {
          // console.log("RETURN FORMATION ", resFormation);

          _setFormulaireFormValues(initialFormulaireFormState);
          _setModules([]);

          onGetAllFormations()
            .then((response: any) => {
              _setFormations(response);
            })
            .catch((error: any) => alert(error));
        })
        .catch((error) => alert(error));
    } else {
      onUpdateFormation({
        id: idUpdateFormation,
        title: formulaireFormValues.title,
        image:
          getValues("image") && getValues("image").length
            ? getValues("image")[0]
            : null,
        language: formulaireFormValues.language,
        link: formulaireFormValues.link,
        modules,
      })
        .then(() => {
          _setFormulaireFormValues(initialFormulaireFormState);
          _setModules([]);

          onGetAllFormations()
            .then((response: any) => {
              _setFormations(response);
            })
            .catch((error: any) => alert(error));
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  // FORMATION GET VALUES
  const getFormationValues = (id: number) => {
    onGetFormation(id)
      .then((response) => {
        _setFormulaireFormValues(response);
        _setModules(response.modules);
      })
      .catch((error) => {
        alert(error);
      });
  };

  useEffect(() => {
    onGetAllFormations()
      .then((response: any) => {
        _setFormations(response);
      })
      .catch((error: any) => alert(error));
  }, []);

  // USE EFFECT FOR CLICK OUTSIDE OF FORMULAIRE POPUP
  useEffect(() => {
    function handleClickFormulaireOutside(event: any) {
      if (
        refCreationFormulairePopup.current &&
        !refCreationFormulairePopup.current.contains(event.target)
      ) {
        _setIsCreationFormationPopupOpen(false);
        _setIsUpdateFormationPopupOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickFormulaireOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickFormulaireOutside);
    };
  }, [refCreationFormulairePopup]);

  //onChange FOR SEARCH INPUT
  const handleChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (value === "") {
      _setNoResultsSentence("");
      onGetAllFormations().then((response) => {
        _setFormations(response);
      });
    } else {
      onGetSearchFormations(value)
        .then((response) => {
          if (response.length === 0) {
            _setNoResultsSentence(t("noResults", { ns: "search" }).toString());
            _setFormations(response);
          } else {
            _setFormations(response);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleDeleteFormation = (id: number) => {
    if (confirm(t("formations.confirmDelete", { ns: "settingsPage" }) + " ?")) {
      onDeleteFormation(id)
        .then((response) => {
          _setIdUpdateFormation(undefined);
          _setIsUpdateFormationPopupOpen(false);
          _setIsCreationFormationPopupOpen(false);
          _setFormulaireFormValues(initialFormulaireFormState);
          onGetAllFormations()
            .then((response: any) => {
              _setFormations(response);
            })
            .catch((error: any) => alert(error));
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  // --- MODULES ---
  const [modules, _setModules] = useState<
    Array<{
      id: number | null;
      title: string;
      left_subtitle: string;
      right_subtitle: string;
    }>
  >([]);

  const updateModule = (
    node: "title" | "left_subtitle" | "right_subtitle",
    key: number,
    text: string
  ) => {
    modules[key][node] = text;
    _setModules([...modules]);
  };

  return (
    <SettingsTeachupStyle>
      <SearchAndAddContainer
        onChange={handleChangeSearchInput}
        onClick={() => {
          _setFormulaireFormValues(initialFormulaireFormState);
          _setIsCreationFormationPopupOpen(true);
        }}
        showBtOptions={false}
      />
      <div className="overflow-scroll" style={{ height: "78vh" }}>
        <div
          style={{ maxWidth: 1260, margin: "auto" }}
          className="flex flex-row flex-wrap gap-3 justify-evenly ml-2 my-6 max-w-72"
        >
          {formations &&
            formations.map((formation, key) => {
              return (
                <FormationCard
                  formationId={formation.id}
                  title={formation.title}
                  language={formation.language}
                  image={formation.image}
                  key={key}
                  onClick={() => {
                    getFormationValues(formation.id);
                    _setIdUpdateFormation(formation.id);
                    _setIsUpdateFormationPopupOpen(true);
                  }}
                />
              );
            })}
          {formations && formations.length < 1 && (
            <NoContentSentence>
              {t("formations.noFormations", { ns: "settingsPage" })}
            </NoContentSentence>
          )}
        </div>
      </div>

      {/* FORM CREATION FOR FOMULAIRE SECTION */}
      <CreationPopupContent
        style={{
          display:
            isCreationFormationPopupOpen || isUpdateFormationPopupOpen
              ? "block"
              : "none",
        }}
      >
        <div
          className="bg-white max-w-fit mx-auto my-28 py-12 px-16"
          ref={refCreationFormulairePopup}
        >
          <form onSubmit={handleFormulaireSubmit}>
            <h3>
              {isUpdateFormationPopupOpen &&
                t("form.nameFormulaireUpdate", { ns: "settingsPage" })}
              {isCreationFormationPopupOpen &&
                t("form.nameFormulaireCreation", { ns: "settingsPage" })}
            </h3>

            <div className="flex flex-row gap-4">
              {/* TITLE */}
              <InputWithSubtitle>
                <label htmlFor="title" className="mt-4 mb-1">
                  {t("form.placeholders.title", { ns: "settingsPage" })}
                </label>
                <FormInput
                  type="text"
                  id="title"
                  name="title"
                  placeholder={
                    "" + t("form.placeholders.title", { ns: "settingsPage" })
                  }
                  required
                  onChange={handleFormulaireChange}
                  value={formulaireFormValues.title}
                />
              </InputWithSubtitle>

              {/* IMAGE LINK */}
              <InputWithSubtitle>
                <label htmlFor="image" className="mt-4 mb-1">
                  {t("form.placeholders.image", { ns: "settingsPage" })}
                </label>
                <input
                  id="image"
                  // name="image"
                  type="file"
                  accept="image/*"
                  {...register("image")}
                />
              </InputWithSubtitle>
            </div>

            <div className="flex flex-row gap-4">
              {/* DROPDOWN LANGUAGE */}
              <InputWithSubtitle>
                <label htmlFor="language" className="mt-4 mb-1">
                  {t("form.placeholders.language", { ns: "settingsPage" })}
                </label>
                <SelectInput
                  onChange={handleFormulaireChange}
                  name="language"
                  id="language"
                  required
                  value={formulaireFormValues.language}
                >
                  <option value="french">Français</option>
                  <option value="english">Anglais</option>
                </SelectInput>
              </InputWithSubtitle>

              {/* LINK */}
              <InputWithSubtitle>
                <label htmlFor="link" className="mt-4 mb-1">
                  {t("form.placeholders.link", { ns: "settingsPage" })}
                </label>
                <FormInput
                  type="text"
                  id="link"
                  name="link"
                  placeholder={
                    "" + t("form.placeholders.link", { ns: "settingsPage" })
                  }
                  required
                  onChange={handleFormulaireChange}
                  value={formulaireFormValues.link}
                />
              </InputWithSubtitle>
            </div>

            {/* MODULE FORMATION */}
            <ModuleContent>
              <div className="flex items-center justify-center">
                <h3>Modules</h3>
                <div
                  onClick={() => {
                    _setModules([
                      {
                        id: null,
                        title: "",
                        left_subtitle: "",
                        right_subtitle: "",
                      },
                      ...modules,
                    ]);
                  }}
                  className="m-3"
                  style={{ cursor: "pointer" }}
                >
                  <IoAddCircle color={BLUE} size={24} />
                </div>
              </div>
              {modules.map((module, key) => {
                return (
                  <ModuleCard key={key} className="mb-8">
                    <div className="flex justify-end">
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          _setModules(
                            modules.filter((item) => item.id !== module.id)
                          );
                        }}
                      >
                        <IoMdRemoveCircle color={BLUE} size={24} />
                      </div>
                    </div>
                    {/* title */}
                    <div className="flex justify-center">
                      <InputWithSubtitle>
                        <label htmlFor="link" className="mt-4 mb-1">
                          {t("form.labels.module_title", {
                            ns: "settingsPage",
                          })}
                        </label>
                        <FormInput
                          type="text"
                          id="module_title"
                          name="module_title"
                          placeholder={
                            "" +
                            t("form.placeholders.module_title", {
                              ns: "settingsPage",
                            })
                          }
                          value={module.title}
                          onChange={(e) =>
                            updateModule("title", key, e.target.value)
                          }
                        />
                      </InputWithSubtitle>
                    </div>
                    <div className="flex flex-row gap-4">
                      {/* left subtitle */}
                      <InputWithSubtitle>
                        <label htmlFor="link" className="mt-4 mb-1">
                          {t("form.labels.left_subtitle", {
                            ns: "settingsPage",
                          })}
                        </label>
                        <FormInput
                          type="text"
                          id="left_subtitle"
                          name="left_subtitle"
                          placeholder={
                            "" +
                            t("form.placeholders.left_subtitle", {
                              ns: "settingsPage",
                            })
                          }
                          onChange={(e) =>
                            updateModule("left_subtitle", key, e.target.value)
                          }
                          value={module.left_subtitle}
                        />
                      </InputWithSubtitle>
                      {/* right subtitle */}
                      <InputWithSubtitle>
                        <label htmlFor="link" className="mt-4 mb-1">
                          {t("form.labels.right_subtitle", {
                            ns: "settingsPage",
                          })}
                        </label>
                        <FormInput
                          type="text"
                          id="right_subtitle"
                          name="right_subtitle"
                          placeholder={
                            "" +
                            t("form.placeholders.right_subtitle", {
                              ns: "settingsPage",
                            })
                          }
                          onChange={(e) =>
                            updateModule("right_subtitle", key, e.target.value)
                          }
                          value={module.right_subtitle}
                        />
                      </InputWithSubtitle>
                    </div>
                  </ModuleCard>
                );
              })}
            </ModuleContent>

            {/* BUTTONS */}
            <div className="mt-4 flex justify-center">
              <Button
                text={
                  isUpdateFormationPopupOpen
                    ? t("form.buttonUpdate", { ns: "settingsPage" })
                    : t("form.button", { ns: "settingsPage" })
                }
              />
            </div>
            <CrossBtn
              className="cursor-pointer mt-4 mr-4"
              onClick={() => {
                _setIsCreationFormationPopupOpen(false);
                _setIsUpdateFormationPopupOpen(false);
                _setFormulaireFormValues(initialFormulaireFormState);
              }}
            >
              <ImCross size={20} />
            </CrossBtn>
          </form>
          {isUpdateFormationPopupOpen && idUpdateFormation && (
            <div className="mt-4 flex justify-center">
              <DeleteButton
                text={t("delete", { ns: "settingsPage" })}
                onClick={() => {
                  handleDeleteFormation(idUpdateFormation);
                }}
              />
            </div>
          )}
        </div>
      </CreationPopupContent>
    </SettingsTeachupStyle>
  );
};

export default SettingsTeachup;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const SettingsTeachupStyle = styled.div`
  width: 100%;
`;

const NoContentSentence = styled.div`
  width: 100%;
  text-align: center;
  color: ${GREY};
`;

const CreationPopupContent = styled.div`
  z-index: 20;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(186, 186, 186, 0.666);

  & > div {
    box-shadow: 0px 15px 35px 0px rgba(60, 66, 87, 0.08),
      0px 5px 15px 0px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    position: relative;
    min-width: 50vw;
  }

  & > div > form > h3 {
    font-size: 24px;
    font-weight: bold;
  }
`;

const InputWithSubtitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > select {
    padding: 10px 12px 10px 16px;
    border-radius: 4px;
    background: ${WHITE};
    border-radius: 5px;
    border: 1px solid ${LIGHT_GREY};
    width: 400px;
  }
`;

const FormInput = styled.input`
  display: flex;
  padding: 10px 12px 10px 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  background: ${WHITE};
  border-radius: 5px;
  border: 1px solid ${LIGHT_GREY};
  width: 400px;
`;

const CrossBtn = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  color: ${LIGHT_GREY};

  :hover {
    color: ${GREY};
  }
`;

const SelectInput = styled.select`
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  background: ${WHITE};
  border-radius: 5px;
  border: 1px solid ${LIGHT_GREY};
`;

const ModuleContent = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;

  h3 {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }
`;

const ModuleCard = styled.div`
  padding: 16px;
  border: 1px solid ${BLUE};
  border-radius: 8px;
`;
