import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useUser } from "../../common/contexts/userContext";
import { useTranslation } from "react-i18next";
import { IUserInfos } from "../../interfaces/user";
import { useAuthentication } from "../../common/contexts/authenticationContext";
import { BLUE, CLEAR_BLUE, WHITE } from "../../constants/cts_colors";
import AccountInfos from "./accountInfos";
import MyFile from "./myFile";
import TopMenu from "../../components/TopMenu";
import i18next from "i18next";
import { MOBILE_WIDTH } from "../../constants/cts_sizes";

const Account = () => {
  const { t } = useTranslation();
  // sections menu
  const menuSections = {
    accountInfos: "selectSection.accountInfos",
    myFile: "selectSection.myFile",
  };
  const [selectedSection, _setSelectedSection] = useState<string>(
    menuSections.accountInfos
  );
  // user
  const { onGetCurrentUser } = useUser();
  const [user, _setUser] = useState<IUserInfos | null>();
  const { token } = useAuthentication();

  const loadUser = () => {
    onGetCurrentUser()
      .then((responseUser) => {
        if (responseUser) {
          _setUser(responseUser);
          console.log(responseUser);
        }
      })
      .catch((error) => alert(error));
  };

  useEffect(() => {
    document.title = t("pageTitle", { ns: "accountPage" });
  }, [i18next.language]);

  useEffect(() => {
    if (token) {
      loadUser();
    }
  }, [token]);

  if (!user) {
    return <></>;
  }

  return (
    <Wrapper>
      {/* TOP MENU */}
      <TopMenu
        sections={[menuSections.accountInfos, menuSections.myFile]}
        selectedSection={selectedSection}
        fromPage="accountPage"
        onClick={(response: string) => _setSelectedSection(response)}
      />

      <SectionContainer>
        {menuSections.accountInfos === selectedSection && (
          <AccountInfos user={user} loadUser={loadUser} />
        )}
        {menuSections.myFile === selectedSection && (
          <MyFile user={user} loadUser={loadUser} />
        )}
      </SectionContainer>
    </Wrapper>
  );
};

export default Account;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div`
  background-color: ${CLEAR_BLUE};
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const SectionContainer = styled.div`
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
  border: 1px solid ${BLUE};
  border-radius: 8px;
  padding: 40px;
  background-color: ${WHITE};

  @media (max-width: ${MOBILE_WIDTH}px) {
    width: calc(100% - 48px);
    margin: 16px 24px;
  }
`;
