import styled from "styled-components";
import { useIa } from "../../common/contexts/iaContext";
import IaTrainingNewUserComponent from "./ia-training-new-user";
import { useState } from "react";
import IaTrainingSetProfilComponent from "./ia-training-set-profil";
import IaTrainingConversationComponent from "./ia-training-conversation";

const PROFIL = "profil-tab";
const CONVERSATION = "conversation-tab";

const IaTrainingPage = () => {
  const { iaUser, modelList, modelSelected, setModelSelected, isLoading } =
    useIa();
  const [viewTab, setViewTab] = useState(PROFIL);

  if (!iaUser) {
    return <IaTrainingNewUserComponent />;
  }

  return (
    <Wrapper>
      <Header>
        {isLoading && (
          <p>
            <b>Chargement...</b>
          </p>
        )}
        <p>Bonjour, {iaUser.email}</p>
        <p>
          Le model d'entrainement est{" "}
          <select
            value={modelSelected?.id || ""}
            onChange={(e) => {
              const idSelected = +e.target.value;
              const findToList = modelList.find((m) => m.id === idSelected);
              setModelSelected(findToList ? findToList : null);
            }}
          >
            <option value="">-</option>
            {modelList.map((m) => (
              <option key={m.id} value={m.id}>
                {m.label}
              </option>
            ))}
          </select>
        </p>

        <Tabs>
          <Tab
            onClick={() => setViewTab(CONVERSATION)}
            className={viewTab === CONVERSATION ? "selected" : ""}
          >
            Conversations
          </Tab>
          <Tab
            onClick={() => setViewTab(PROFIL)}
            className={viewTab === PROFIL ? "selected" : ""}
          >
            Profil
          </Tab>
        </Tabs>
      </Header>

      {modelSelected && (
        <>
          <div>{viewTab === PROFIL && <IaTrainingSetProfilComponent />}</div>
          <div>
            {viewTab === CONVERSATION && <IaTrainingConversationComponent />}
          </div>
        </>
      )}
    </Wrapper>
  );
};

export default IaTrainingPage;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div``;

const Header = styled.div`
  padding: 16px;
`;

const Tabs = styled.div`
  margin: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Tab = styled.p`
  margin: 8px;
  cursor: pointer;

  &.selected {
    text-decoration: underline;
  }
`;
