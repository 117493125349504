import {
  SETTING_ALLOW_NEWSLETTERS,
  SETTING_ALLOW_POST,
  SETTING_ALLOW_RESOURCE,
  SETTING_ALLOW_TAGG,
} from "../constants/cts_settings";

export default {
  common: {
    multilingue: "Multilingue",
  },

  // alerts ----------------------------------------------------------------
  alerts: {
    // account
    pwdChanged: "Votre mot de passe à bien été modifié.",
    accountEdited: "Les informations de votre compte ont été modifiées",
    accountDeleted: "Votre compte a été supprimé",
    // forms
    emptyInput: "Les champs ne peuvent pas être vides",
  },

  // confirm alerts
  confirmAlerts: {
    confirmDeleteAccount: "Voulez-vous supprimer votre compte ?",
    confirmLogout: "Voulez-vous vous déconnecter ?",
  },

  // info messages ---------------------------------------------------------
  infoMessages: {
    changeSaved: "La modification a été enregistrée",
    passwordChanged: "Votre mot de passe à bien été modifié",
  },

  // errors ----------------------------------------------------------------
  errors: {
    form: {
      // no match
      passwordNoMatch: "Les mots de passe ne sont pas identiques",
      // empty
      emptyPassword: "Veuillez entrer un mot de passe",
      emptyFirstName: "Veuillez entrer le prénom",
      emptyLastName: "Veuillez entrer le nom",
      emptyFirstNameAndLastName: "Veuillez saisir le nom et le prénom",
      emptyToken: "Veuillez entrer le token",
      emptyContactMessage: "Veuillez entrer le message",
      emptyItemTitle: "Veuillez entrer le titre",
      emptyItemDescription: "Veuillez entrer la description",
      emptyItemImage: "Veuillez entrer une image",
      emptyItemPrice: "Veuillez entrer le prix",
      emptyItemStatus: "Veuillez entrer le statut",
      // formations
      emptyFormationTitle: "Veuillez saisir un titre",
      emptyFormationImage: "Veuillez saisir une image",
      emptyFormationLanguage: "Veuillez saisir un langage",
      emptyFormationLink: "Veuillez saisir un lien",
      // company
      emptyCompanyName: "Veuillez saisir un nom",
      // members
      emptyMemberFirstName: "Veuillez saisir un prénom",
      emptyMemberLastName: "Veuillez saisir un nom de famille",
      emptyMemberEmail: "Veuillez saisir un email",
      emptyMemberCompany: "Veuillez saisir une entreprise",
      emptyMemberPosition: "Veuillez saisir un poste",
      // coachs
      emptyCoachFirstName: "Veuillez saisir un prénom",
      emptyCoachLastName: "Veuillez saisir un nom de famille",
      emptyCoachEmail: "Veuillez saisir un email",
      emptyCoachPhoneNumber: "Veuillez saisir un numéro de téléphone",
      // groups
      emptyGroupName: "Veuillez saisir un nom",
      emptyGroupMembers: "Veuillez saisir au moin un membre",
      // posts
      emptyPostContent: "Veuillez saisir un contenu pour le post",
      // comments
      emptyCommentContent: "Veuillez saisir un contenu pour le commentaire",
      // not valid
      notValidEmail: "Veuillez entrer un email valide (exemple@email.com)",
      notValidPassword:
        "Veuillez entrer un mot de passe valide (plus de 6 caractères)",
      notValidPhoneNumber:
        "Veuillez entrer un numéro de téléphone valide (ex: 0612345678)",
    },
  },

  // complex translations --------------------------------------------------
  translation: {
    // reset password page
    resetPasswordPage: {
      form: {
        infos: {
          passwordChanged: `Votre mot de passe à bien été modifié. Vous pouvez à présent vous <a href={{loginPath}}>connecter</a> à votre compte.`,
        },
      },
    },
  },

  // simple translations ---------------------------------------------------
  timeUnits: {
    second: "seconde",
    minute: "minute",
    hour: "heure",
    day: "jour",
    month: "mois",
    year: "an",
    // multiple
    seconds: "secondes",
    minutes: "minutes",
    hours: "heures",
    days: "jours",
    months: "mois",
    years: "ans",
  },

  // header
  header: {
    elements: {
      home: "Accueil",
      contact: "Contact",
      about: "À propos de nous",
      legals: "Mentions légales",
    },
    itemsLink: "Jobs",
    loginLink: "Connexion",
    accountLink: "Compte",
  },

  // settings header for admins
  settingsHeader: {
    users: "Utilisateurs",
    members: "Membres",
    groups: "Groupes",
    coachs: "Coachs",
    companies: "Entreprises",
  },

  // Orderby settings translation
  orderBy: {
    sortBy: "Trier par",
    all: "Tous",
    firstName: "Prénom",
    lastName: "Nom",
    status: "Actif",
    email: "Email",
    companies: "Entreprise",
    position: "Poste",
    phoneNumber: "Téléphone",
  },

  // footer
  footer: {
    elements: {
      aboutUs: "Qui sommes nous ?",
      legals: "Mentions légales",
    },
  },

  // onboarding
  onBoardingPage: {
    title: "Les Clés - OnBoarding",
  },

  // login page
  loginPage: {
    title: "Les Clés - Connexion",
    mainTitle: "Connexion",
    description: "Connectez-vous à votre compte",
    connectSSO: "Se connecter à votre compte",
    welcome: "Bonjour",
    privacyAndTerms: "Confidentialité et Conditions d'Utilisation",
    form: {
      labels: {
        email: "Email",
        password: "Mot de passe",
      },
      placeholders: {
        email: "Email",
        password: "Mot de passe",
      },
      links: {
        lostPassword: "Mot de passe oublié ?",
        routeToLogin: "Vous n'avez pas de compte ? Inscrivez-vous ici",
      },
    },
  },

  // signup page
  signupPage: {
    mainTitle: "INSCRIPTION",
    form: {
      labels: {
        firstName: "Prénom",
        lastName: "Nom",
        email: "Adresse email",
        phoneNumber: "Numéro de téléphone (optionnel)",
        password: "Mot de passe",
        repeatPassword: "Confirmer mot de passe",
      },
      placeholders: {
        firstName: "Prénom",
        lastName: "Nom",
        email: "Adresse email",
        phoneNumber: "Numéro de téléphone (optionnel)",
        password: "Mot de passe",
        repeatPassword: "Confirmer mot de passe",
      },
      links: {
        navigateToLogIn: "Déjà enregistré ? Connectez-vous ici",
      },
    },
  },

  // reset password page
  resetPasswordPage: {
    mainTitle: "Modification du mot de passe",
    form: {
      labels: {
        token: "Token",
        email: "Adresse email",
        password: "Mot de passe",
        repeatPassword: "Confirmer mot de passe",
      },
      placeholders: {
        token: "Token",
        email: "Adresse email",
        password: "Mot de passe",
        repeatPassword: "Confirmer mot de passe",
      },
    },
    passwordChangedInfo: {
      firstPartText:
        "Votre mot de passe a été changé. vous pouvez vous connecter maintenant à votre compte !",
    },
  },

  // lost password page
  lostPasswordPage: {
    title: "Les Clés - Mot de passe oublié",
    mainTitle: "Mot de passe oublié ?",
    send: "Envoyer",
    redirectToLogInLink: "Retour à la page connexion",
    form: {
      labels: {
        email: "Adresse email",
      },
      placeholders: {
        email: "Adresse email",
      },
      infos: {
        linkSent:
          "Un lien pour réinitialiser votre mot de passe a été envoyé par e-mail à",
      },
    },
  },

  // topbar
  topbar: {
    profile: {
      editProfile: "Editer mon profil",
      settings: "Paramètres",
      contact: "Contacter le support",
      logout: "Déconnexion",
    },
    logoutModal: {
      text: "Vous allez vous déconnecter",
      buttonExitModal: "Je reste connectée",
      buttonConfirmLogout: "Je me déconnecte",
    },
    favorites: {
      title: "Favoris",
    },
  },

  // account page
  accountPage: {
    pageTitle: "Les Clés - Votre compte",
    selectSection: {
      accountInfos: "Editer mon profil",
      myFile: "Ma fiche",
    },
    accountInfos: {
      form: {
        labels: {
          name: "Nom complet",
          email: "Email",
          password: "Mot de passe",
        },
        placeholders: {
          name: "Nom complet",
          email: "Adresse email",
          password: "**********",
        },
        submitBtn: "Changer",
      },
      confirmNewProfileImage: {
        title: "Voulez-vous changer votre image de profil ?",
        confirm: "Oui",
        cancel: "Non",
      },
    },
    myFile: {
      form: {
        labels: {
          pitch: "Contenu du pitch",
          anecdote1: "Anecdote 1 : Mes plaisirs au travail sont",
          anecdote2: "Anecdote 2 : La Clé que j’ai envie de partager",
          anecdote3: "Anecdote 3 : Pour me ressourcer, j’aime",
          position: "Métier",
          linkedinProfileLink: "Insérer ici le lien de votre profil Linkedin",
        },
        placeholders: {
          pitch: "Le contenu du pitch ici",
          anecdote1: "Texte ici",
          anecdote2: "Texte ici",
          anecdote3: "Texte ici",
          position: "Texte ici",
          linkedinProfileLink: "linkedin.com/nomutilisateur",
        },
        submitBtn: "Changer",
        saveBtn: "Sauvegarder",
      },
    },
    changePasswordForm: {
      form: {
        labels: {
          password: "Nouveau mot de passe",
          repeatPassword: "Confirmer le nouveau mot de passe",
        },
        placeholders: {
          password: "Nouveau mot de passe",
          repeatPassword: "Confirmer le nouveau mot de passe",
        },
        submitBtn: "Changer",
      },
    },
  },

  // account settings page
  accountSettingsPage: {
    title: "Activez les notifications !",
    settings: {
      [SETTING_ALLOW_NEWSLETTERS]: {
        name: "Je veux recevoir la newsletter",
      },
      [SETTING_ALLOW_POST]: {
        name: "Je veux recevoir les nouveaux podcasts",
      },
      [SETTING_ALLOW_RESOURCE]: {
        name: "Je veux recevoir les nouvelles ressources",
      },
      [SETTING_ALLOW_TAGG]: {
        name: "Je veux recevoir quand je suis tagué",
      },
    },
  },

  // legal notices page
  legalNoticesPage: {
    mainTitle: "Mentions Légales",
  },

  // about page
  aboutPage: {
    mainTitle: "Qui sommes nous ?",
  },

  // contact page
  contactPage: {
    mainTitle: "CONTACT",
    form: {
      labels: {
        lastName: "Nom",
        firstName: "Prénom",
        email: "Adresse email",
        phoneNumber: "Numéro de téléphone (optionnel)",
        message: "Votre message",
      },
      placeholders: {
        lastName: "Nom",
        firstName: "Prénom",
        email: "Adresse email",
        phoneNumber: "Numéro de téléphone (optionnel)",
        message: "Écrivez votre message...",
      },
      infos: {
        messageSent: "Votre message a été envoyé",
      },
    },
  },

  // home page
  homePage: {
    mainTitle: "Les Clés - Dashboard",
    presentation: {
      title: {
        firstPart: "Nous offrons ",
        strongText: "25 000 emplois",
        lastPart: " vacants dès maintenant !",
      },
      description:
        "Le logiciel de service sur le terrain le plus complet pour les secteurs de l'assistance informatique et mobile, des services d'incendie, de l'électricité, de la maintenance, du CVC et de la sécurité",
      button: "Inscription gratuite",
      numbers: {
        users: "Utilisateurs",
        downloads: "Téléchargements",
        likes: "Aimes",
        rating: "Classement 5 étoiles",
      },
    },
    about: {
      title: {
        firstPart: "Ce qui rend ",
        strongText: "Dear Job",
        lastPart: " Différent",
      },
      subtitle: "Les atouts de votre société de développement",
      cards: {
        titles: {
          searchJob: "RECHERCHER UN EMPLOI",
          functional: "COMPLÈTEMENT FONCTIONNEL",
          liveChat: "CHAT EN DIRECT",
        },
      },
    },
    footer: {
      title: {
        strongText: "UNE FONCTION COMPLÈTE",
        lastPart: "PRÊT À VOUS AIDER",
      },
      button: "Vidéo de démonstration",
    },
  },

  // circle page
  circlePage: {
    mainTitle: "Les Clés - Communauté",
    post: {
      write: "Écrire un post",
      updatePost: "Modifier un post",
      posted: "Posté dans ",
      like: "J'aime",
      comment: "Commenter",
      respond: "Répondre",
      itWas: "il y a",
      allComments: "Tous les commentaires",
      send: "Publier",
      delete: "Supprimer",
      update: "Modifier",
      confirmDelete: "Voulez-vous supprimer ce post ?",
      read: "Lu",
      form: {
        labels: {
          dateToPublish: "Date de publication :",
          tags: "Tous les tags :",
          noTags: "Aucun tag",
          selectedTags: "Tags sélectionnés :",
          noTagsSelected: "Aucun tag sélectionné",
          noFiles: "Aucun fichier",
          files: "Fichiers :",
          companies: "Entreprises :",
          interSpan: "Toutes les entreprises",
        },
        placeholders: {
          title: "Titre (optionnel)",
          addTag: "Ajouter un tag",
        },
      },
    },
  },

  // community page
  communityPage: {
    mainTitle: "Les Clés - Communauté",
    community: "Communauté",
    titleAnnu: "Annuaire membres",
    memberInfosSidebar: {
      linkedinBtn: "Se connecter sur Linkedin",
      anecdotes: {
        anecdote1: "Mes plaisirs au travail sont",
        anecdote2: "La Clé que j’ai envie de partager",
        anecdote3: "Pour me ressourcer, j’aime",
        liked: "J'aime",
        notLiked: "Aimer cette anecdote",
      },
      pitchTitle: "Mon pitch",
    },
  },

  // library page
  libraryPage: {
    mainTitle: "Les Clés - Bibliothèque",
    podcasts: "Les Podcasts",
    resources: "Les Ressources",
    back: "Retour",
    explore: "Tout explorer",
    allEpisodes: "Tous les épisodes",
    listen: "Écouter ce podcast",
  },

  // teachup page
  teachupPage: {
    mainTitle: "Les Clés - Académie",
    myeLearnings: "Mes e-learnings",
    formations: "Formations",
    journeyTracking: {
      title: "Mon suivi de parcours",
      downloadButton: "Télécharger",
      downloadPDFName: "mon_suivi_de_parcours",
      downloadText: "Chargement",
      placeholders: "• Entrez vos réponses ici",
      remember: "Ce que je retiens",
      implements: "Ce que je veux mettre en place",
      atelier: "Atelier de lancement : La confiance en soi",
      module1: "Module 1 : Me libérer de mes croyances",
      module2: "Module 2 : Poser mes limites",
      module3: "Module 3 : Me valoriser en tant que manager",
      module4: "Module 4 : Construire mon parcours",
      module5: "Module 5 : Parler de moi avec impact",
      module6: "Module 6 : Me créer un réseau",
      noneModules: "Aucun module",
    },
  },

  // favorite page
  favoritesPage: {
    mainTitle: "Les Clés - Favoris",
    podcastsFavoris: "Podcasts Favoris",
    resourcesFavoris: "Ressources Favorites",
  },

  // settings page
  settingsPage: {
    mainTitle: "Les Clés - Paramètres",
    settings: "Administration",
    add: "Ajouter",
    access: "Accès",
    search: "Rechercher",
    edit: "Modifier",
    delete: "Supprimer",
    community: "Communauté",
    becomeAdmin: "Passer administrateur",
    formations: {
      noFormations: "Aucune formation",
      confirmDelete: "Êtes vous sûr de vouloir supprimer cette formation ",
      module: "Module",
    },
    members: {
      user: "Utilisateur",
      status: "Actif",
      email: "Email",
      company: "Entreprise",
      poste: "Poste",
      activity: "Opérations",
      noMembers: "Aucun membre",
      access: "Accès",
      confirmDelete: "Êtes vous sûr de supprimer ",
    },
    companies: {
      headerCompagnies: "Entreprises",
      headerMembers: "Membres",
      noCompanies: "Aucune entreprise",
      confirmDelete: "Êtes vous sûr de supprimer ",
      activity: "Opérations",
      headerFormations: "Nb Formations",
      accessAdded: "Accès ajoutés avec succès !",
      formation: "Ajouter un formation",
      intraCompanies: "Intra Entreprises",
      interCompanies: "Inter Entreprises",
      allCompanies: "Toutes les entreprises",
    },
    coachs: {
      user: "Utilisateur",
      email: "Email",
      phoneNumber: "Numéro de téléphone",
      noCoachs: "Aucun coachs",
      confirmDelete: "Êtes vous sûr de supprimer ",
    },
    groups: {
      name: "Nom",
      nbMembers: "Nb Membres",
      nbFormations: "Nb Formations",
      activity: "Opérations",
      noGroups: "Aucun groupe",
      confirmDelete: "Êtes vous sûr de supprimer ",
      accessAdded: "Accès ajoutés avec succès !",
    },
    form: {
      nameFormulaireCreation: "Créer une formation",
      nameFormulaireUpdate: "Mettre à jour une formation",
      nameCompagniesCreation: "Créer une entreprise",
      nameCompagniesUpdate: "Mettre à jour une entreprise",
      nameMembersCreation: "Créer un membre",
      nameMembersUpdate: "Mettre à jour un membre",
      nameGroupsCreation: "Créer un groupe",
      nameGroupsUpdate: "Mettre à jour un groupe",
      nameCoachsCreation: "Créer un coach",
      nameCoachsUpdate: "Mettre à jour un coach",
      addAccess: "Ajouter des accès",
      updateAccess: "Mettre a jour les accès",
      labels: {
        title: "Titre",
        image: "Image",
        language: "Langage",
        link: "Lien",
        name: "Nom",
        first_name: "Prénom",
        last_name: "Nom",
        email: "Email",
        company: "Entreprise",
        position: "Poste",
        accessToCommunity: "Accès à la communauté",
        accessToFormations: "Accès aux formations",
        accessToEvents: "Accès aux événements",
        status: "Actif",
        phone_number: "Numéro de téléphone",
        members: "Membres",
        noMember: "Aucun membre",
        links: "Teachup Formation",
        module_title: "Titre du mobule",
        left_subtitle: "Sous titre de gauche",
        right_subtitle: "Sous titre de droite",
      },
      placeholders: {
        title: "Titre",
        image: "Image",
        language: "Langage",
        link: "Lien",
        name: "Nom",
        first_name: "Prénom",
        last_name: "Nom",
        email: "Email",
        company: "Entreprise",
        position: "Poste",
        status: "Actif",
        phone_number: "Numéro de téléphone",
        members: "Membres",
        links: "Teachup Formation",
        formations: "Formations",
        is_intra: "Intra Entreprise ?",
        module_title: "Titre du mobule",
        left_subtitle: "Sous titre de gauche",
        right_subtitle: "Sous titre de droite",
        formation: "Ajouter une formation",
      },
      button: "Créer",
      buttonUpdate: "Mettre à jour",
      infos: {
        messageSent: "Formation créée",
      },
    },
  },

  // items page
  itemsPage: {
    mainTitle: "JOBS",
    itemsButton: "POSTULER MAINTENANT",
  },

  // Payment form
  paymentForm: {
    payButton: "Payer",
  },

  menu: {
    home: "Accueil",
    academy: "Académie",
    bord: "Tableau de bord",
    library: "Bibliothèque",
    community: "Communauté",
    events: "Événements",
  },

  // dashboard page
  dashboardPage: {
    mainTitle: "Les Clés - Accueil",
    podcasts: {
      title: "Podcasts & Ressources",
      description: "Découvrez les nouveautés de la Bibliothèque",
      button: "Tout voir",
    },
    post: {
      title: "Dernier post dans la Communauté",
      explore: "Tout explorer",
    },
  },

  eventsPage: {
    mainTitle: "Les Clés - Événements",
    nextEvents: "Les prochains événements",
    presentiel: "En présentiel",
    distance: "À distance",
    registerEvent: "Je m'inscris",
    quitEvent: "Se désinscrire",
    alreadyRegisteredEvent: "Vous êtes déjà inscrite à cet événement",
    fullEvent: "Complet !",
    seeEvent: "Voir l'événement",
    thanksForSubscription: "Merci pour votre inscription !",
    rappelMail: "Une confirmation vous sera envoyée par mail.",
    addToAgenda: "Ajouter à mon agenda",
    newEvent: "NOUVEAU",
    post: {
      write: "Ajouter un événement",
      updatePost: "Modifier un événment",
      form: {
        labels: {
          dateToPublish: "Date de publication :",
          eventDate: "Date de l'événement :",
          noFiles: "Aucun fichier",
          files: "Fichiers :",
          nbUsers: "Nombre d'inscript maximum",
          details: "Contenu pour les inscrits",
        },
        placeholders: {
          title: "Titre (optionnel)",
          addTag: "Ajouter un tag",
        },
      },
    },
    fullscreen: {
      details: "Détails de l'événement",
      usersViewByAdmin: "Utilisateurs inscrits (vue admin)",
      users: "utilisateurs",
    },
  },
};
