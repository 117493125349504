import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  BLACK,
  BLUE,
  CLEAR_GREY,
  GREY,
  LIGHT_GREY,
  WHITE,
} from "../../constants/cts_colors";
import { useAuthentication } from "../../common/contexts/authenticationContext";
import { usePosts } from "../../common/contexts/postContext";
import { IPostsInfos } from "../../interfaces/posts";
import { useLikes } from "../../common/contexts/likeContext";
import { ILikesInfos } from "../../interfaces/likes";
import { useComments } from "../../common/contexts/commentContext";
import Post from "../../components/Post";
import { AiTwotoneAudio, AiOutlineLoading3Quarters } from "react-icons/ai";
import { RiVideoLine, RiImageLine } from "react-icons/ri";
import { ImCross } from "react-icons/im";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import WriteYourPost from "../../components/WriteYourPost";
import FileCard from "../../components/FileCard";
import TopMenu from "../../components/TopMenu";
import "quill-mention";
import { useAnalyticLogs } from "../../common/contexts/analyticLogContext";
import i18next from "i18next";
import { useUser } from "../../common/contexts/userContext";
import { IUserInfos } from "../../interfaces/user";

let atValuesForMention = [
  { id: 1, value: "Fredrik Sundqvist" },
  { id: 2, value: "Patrik Sjölin" },
];

async function suggestPeople(searchTerm: any) {
  return atValuesForMention
    .filter((person) =>
      (person.value || "")
        .toLowerCase()
        .includes((searchTerm || "").toLowerCase())
    )
    .slice(0, 10);
}

const modulesForQuill = {
  magicUrl: true,
  mention: {
    allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
    mentionDenotationChars: ["@", "#"],
    source: async function (searchTerm: any, renderList: any) {
      const matchedPeople = await suggestPeople(searchTerm);
      renderList(matchedPeople);
    },
  },
};

const GroupsPage = ({ groupId }: { groupId: number }) => {
  const { t } = useTranslation();
  let { user, isUserAdmin } = useAuthentication();
  const { onGetAllUsers } = useUser();
  const [allUsersForMention, _setAllUsersForMention] = useState<
    Array<IUserInfos>
  >([]);

  // --- FILES ---
  const [sendFiles, _setSendFiles] = useState<any[]>([]);

  // IMAGES FILES
  function onImageChange(e: any) {
    let tmpFiles = sendFiles;
    tmpFiles.push(e.target.files[0]);
    for (let i = 0; i < tmpFiles.length; i++) {
      if (!tmpFiles[i].id) {
        tmpFiles[i].url = URL.createObjectURL(tmpFiles[i]);
      }
    }
    _setSendFiles([...tmpFiles]);
  }

  // AUDIO FILES
  function onAudioChange(e: any) {
    let tmpFiles = sendFiles;
    tmpFiles.push(e.target.files[0]);
    _setSendFiles([...tmpFiles]);
  }

  // VIDEO FILES
  function onVideoChange(e: any) {
    let tmpFiles = sendFiles;
    tmpFiles.push(e.target.files[0]);
    _setSendFiles([...tmpFiles]);
  }

  // --- MARKDOWN EDITOR ---
  const [markdownContent, _setMarkdownContent] = useState<string>("");

  // HANDLE CHANGE MARKDOWN EDITOR
  const handleMarkdownChange = (value: string) => {
    _setMarkdownContent(value);
  };

  // --- POSTS ---
  const { onGetAllPosts, onCreatePost, onDeletePost, onUpdatePost } =
    usePosts();
  const [posts, _setPosts] = useState<Array<IPostsInfos>>([]);
  const [isCreatingPost, _setIsCreatingPost] = useState<boolean>(false);
  const [isUpdatingPost, setIsUpdatingPost] = useState<IPostsInfos | null>(
    null
  );
  const [onLoadingSendingPost, _setOnLoadingSendingPost] =
    useState<boolean>(false);

  const initialPostFormState = {
    user_id: user.id ? user.id : "",
    title: "",
    content: "",
    files: [],
    date_to_publish: new Date(),
  };

  const [postFormValues, _setPostFormValues] = useState(initialPostFormState);

  // HANDLE POST SUBMIT
  const handlePostSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    _setOnLoadingSendingPost(true);

    const thenPost = () => {
      _setIsCreatingPost(false);
      _setPostFormValues(initialPostFormState);
      _setMarkdownContent("");
      _setSendFiles([]);
      _setOnLoadingSendingPost(false);

      // refresh all posts
      onGetAllPosts(groupId)
        .then(async (resPosts) => {
          for (let i = 0; i < resPosts.length; i++) {
            const resNumberLikes = await onGetNumberLikesForPost(
              resPosts[i].id
            );
            resPosts[i].numberOfLikes = parseInt(resNumberLikes.toString());

            const resComments = await onGetAllCommentsFromPost(resPosts[i].id);
            resPosts[i].comments = resComments;
          }

          _setPosts(resPosts);
        })
        .catch((error) => {
          _setOnLoadingSendingPost(true);
        });
    };

    if (isUpdatingPost) {
      onUpdatePost({
        id: isUpdatingPost.id,
        user_id: isUpdatingPost.user_id,
        title: postFormValues.title,
        content: markdownContent,
        files: sendFiles,
        date_to_publish: postFormValues.date_to_publish,
      }).then(thenPost);
    } else {
      onCreatePost({
        user_id: user.id,
        title: postFormValues.title,
        content: markdownContent,
        files: sendFiles,
        date_to_publish: postFormValues.date_to_publish,
        groupId,
      }).then(thenPost);
    }
  };

  // HANDLE POST DELETE
  const handlePostDelete = (postId: number, index: number) => {
    const response = confirm(
      `${t("post.confirmDelete", { ns: "circlePage" })}`
    );

    if (response) {
      onDeletePost(postId).then(() => {
        // refresh posts from state
        _setPosts([...posts.slice(0, index), ...posts.slice(index + 1)]);
      });
    }
  };

  // HANDLE POST UPDATE
  const handleUpdatePost = (postId: number) => {
    const post = posts.find((i) => i.id === postId);
    if (post) {
      setIsUpdatingPost(post);
      _setMarkdownContent(post.content);
      _setPostFormValues({
        user_id: post.user_id,
        title: post.title,
        content: post.content,
        // @ts-ignore
        files: post.filesHistory || [],
        date_to_publish: post.date_to_publish
          ? post.date_to_publish
          : new Date(),
      });
      _setSendFiles(post.filesHistory || []);
      _setIsCreatingPost(true);
    }
  };

  // ON CHANGE POST
  const handlePostChange = (e: any) => {
    const { name, value } = e.target;
    _setPostFormValues({
      ...postFormValues,
      [name]: value,
    });
  };

  // --- LIKES ---
  const { onCreateLike, onGetAllLikesFromUser, onGetNumberLikesForPost } =
    useLikes();
  const [likes, _setLikes] = useState<Array<ILikesInfos>>([]);

  // LIKE POST
  const handleLikePost = (post_id: number) => {
    onCreateLike({
      user_id: user.id,
      post_id: post_id,
      comment_id: null,
    }).then((reslike: any) => {
      // update number of like for post liked
      let tmpPosts = posts;
      tmpPosts.map((tmpPost) => {
        if (tmpPost.id === post_id) {
          if (reslike.data.is_liked) tmpPost.numberOfLikes++;
          else tmpPost.numberOfLikes--;
        }
      });
      _setPosts(tmpPosts);
      onGetAllLikesFromUser(user.id).then((res: any) => {
        _setLikes(res);
      });
    });
  };

  // --- COMMENTS ---
  const { onGetAllCommentsFromPost } = useComments();

  // handle refresh comments for post id
  const handleOnSentComment = (post_id: number) => {
    // console.log("SENT COMMENT ON POST ", post_id);
    onGetAllCommentsFromPost(post_id).then((resComments: any) => {
      const postsUpdate = posts.map((post) => {
        if (post.id === post_id) {
          return { ...post, comments: resComments };
        }
        return post;
      });
      _setPosts(postsUpdate);
    });
  };

  // --- USE EFFECTS ---
  useEffect(() => {
    document.title = t("mainTitle", { ns: "circlePage" });
  }, [i18next.language]);

  useEffect(() => {
    let tmp = Array<any>();

    allUsersForMention.map((user: any) => {
      tmp.push({
        id: user.id,
        value: `${user.first_name} ${user.last_name}`,
      });
    });

    atValuesForMention.pop();
    atValuesForMention = tmp;
  }, [allUsersForMention]);

  useEffect(() => {
    // get all users for mention them
    onGetAllUsers(groupId).then((resUsers: any) => {
      _setAllUsersForMention(resUsers);
    });

    // get all posts
    onGetAllPosts(groupId).then((resPosts: any) => {
      resPosts.map((resPost: any) => {
        // add number of likes for each post
        onGetNumberLikesForPost(resPost.id).then((resNumberLikes) => {
          resPost.numberOfLikes = parseInt(resNumberLikes.toString());
        });

        // add comments for each posts
        onGetAllCommentsFromPost(resPost.id).then((resComments) => {
          resPost.comments = resComments;
        });
      });

      _setPosts(resPosts);
    });
  }, [groupId]);

  const handleCreatePost = () => {
    _setIsCreatingPost(true);
  };

  useEffect(() => {
    // get all likes for user
    if (user && user.id) {
      onGetAllLikesFromUser(user.id).then((likesRes: any) => {
        _setLikes(likesRes);
      });
    }
  }, [user]);

  return (
    <Wrapper style={{ overflowY: "hidden" }}>
      <div className="w-full mt-8">
        {/* CREATE NEW POST DIV FOR ADMINS ONLY*/}
        <WriteYourPost
          userImage={user.image ? user.image : ""}
          onClick={handleCreatePost}
        />
      </div>

      <div>
        {/* POSTS LIST */}
        {posts.map((post, key) => {
          return (
            <Post
              key={key}
              index={key}
              post={post}
              user={user}
              likes={likes}
              handleLikePost={handleLikePost}
              handleOnSentComment={handleOnSentComment}
              handleDeletePost={handlePostDelete}
              handleUpdatePost={handleUpdatePost}
            />
          );
        })}
      </div>

      {/* ADD POST FORM */}
      {isCreatingPost && (
        <AddPostContent onSubmit={handlePostSubmit}>
          <Content className="header p-8 flex flex-row justify-between">
            <h3 className="font-bold text-xl">
              {t(isUpdatingPost ? "post.updatePost" : "post.write", {
                ns: "circlePage",
              })}
            </h3>
            <a
              className="flex"
              onClick={() => {
                _setSendFiles([]);
                setIsUpdatingPost(null);
                _setPostFormValues(initialPostFormState);
                _setIsCreatingPost(false);
              }}
            >
              <ImCross />
            </a>
          </Content>
          {/* FORM TEXT */}
          <Content className="form gap-4">
            <input
              className="font-bold"
              type="text"
              name="title"
              id="title"
              placeholder={
                t("post.form.placeholders.title", { ns: "circlePage" }) + ""
              }
              value={postFormValues.title ? postFormValues.title : ""}
              onChange={handlePostChange}
            />
            <ReactQuill
              modules={modulesForQuill}
              style={{
                border: "1px",
                height: "25vh",
              }}
              value={markdownContent}
              onChange={handleMarkdownChange}
              theme="snow"
            />
          </Content>
          {/* DATE TO PUBLISH */}
          <Content className="date">
            <h3 className="text-base font-bold mb-2">
              {t("post.form.labels.dateToPublish", { ns: "circlePage" })}
            </h3>
            <input
              type="date"
              value={postFormValues.date_to_publish.toString()}
              name="date_to_publish"
              id="date_to_publish"
              onChange={handlePostChange}
            />
          </Content>
          {/* FORM FILES */}
          <Content className="files">
            {sendFiles && sendFiles.length === 0 && (
              <p>{t("post.form.labels.noFiles", { ns: "circlePage" })}</p>
            )}
            {sendFiles && sendFiles.length !== 0 && (
              <p className="mb-2">
                {t("post.form.labels.files", { ns: "circlePage" })}
              </p>
            )}

            <div className="flex flex-col">
              <div className="flex flex-row gap-4">
                {sendFiles &&
                  sendFiles.map((sendFile, key) => {
                    return (
                      <FileCard
                        key={key}
                        file={sendFile}
                        onRemoveFile={() => {
                          let upFiles = sendFiles;
                          upFiles.splice(key, 1);
                          _setSendFiles([...upFiles]);
                        }}
                      />
                    );
                  })}
              </div>
            </div>
          </Content>
          <Content className="bottom">
            <div className="left">
              {/* IMAGE INPUT */}
              <div>
                <input
                  type="file"
                  name="imgFile"
                  id="imgFile"
                  className="inputfile"
                  accept="image/*"
                  multiple
                  // {...register("imgFile")}
                  onChange={onImageChange}
                />
                <label htmlFor="imgFile">
                  <RiImageLine size={24} />
                </label>
              </div>
              {/* AUDIO INPUT */}
              {isUserAdmin && (
                <div>
                  <input
                    type="file"
                    name="audioFile"
                    id="audioFile"
                    className="inputfile"
                    accept="audio/*"
                    multiple
                    // {...register("audioFile")}
                    onChange={onAudioChange}
                  />
                  <label htmlFor="audioFile">
                    <AiTwotoneAudio size={23} />
                  </label>
                </div>
              )}
              {/* VIDEO INPUT */}
              {isUserAdmin && (
                <div>
                  <input
                    type="file"
                    name="videoFile"
                    id="videoFile"
                    className="inputfile"
                    accept="video/*"
                    multiple
                    // {...register("videoFile")}
                    onChange={onVideoChange}
                  />
                  <label htmlFor="videoFile">
                    <RiVideoLine size={25} />
                  </label>
                </div>
              )}
            </div>
            {/* PUBLISH BUTTON */}
            <div className="right">
              <button
                disabled={
                  markdownContent.length === 0 &&
                  postFormValues.title.length === 0
                }
              >
                {!onLoadingSendingPost && t("post.send", { ns: "circlePage" })}
                {onLoadingSendingPost && (
                  <div className="flex flex-row justify-center items-center px-5 py-1 rotating">
                    <AiOutlineLoading3Quarters size={20} />
                  </div>
                )}
              </button>
            </div>
          </Content>
        </AddPostContent>
      )}
    </Wrapper>
  );
};

export default GroupsPage;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div`
  padding-bottom: 120px;
`;

const AddPostContent = styled.form`
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: fit-content;
  padding-bottom: 7%;
  background-color: rgba(0, 0, 0, 0.66);
`;

const Content = styled.div`
  margin: auto;
  width: 60%;
  background-color: ${WHITE};

  &.header {
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    margin-top: 4%;
    border-bottom: 2px solid ${LIGHT_GREY};

    a {
      max-width: 28px;
      padding: 6px;
      background-color: ${WHITE};
      border-radius: 4px;
      cursor: pointer;
      border: 1px solid ${WHITE};

      :hover {
        background-color: ${LIGHT_GREY};
        border: 1px solid ${GREY};
      }
    }
  }

  &.form {
    font-size: 24px;
    padding: 32px;
    padding-bottom: 86px;
    display: flex;
    flex-direction: column;
    min-height: 55%;
    border-bottom: 2px solid ${LIGHT_GREY};
    align-items: stretch;

    textarea {
      border: none;
      outline: none;
      height: 55%;
    }

    input {
      border: none;
      outline: none;
    }

    input::placeholder {
      font-weight: bold;
    }

    .quill > .ql-container {
      font-size: 16px;
      font-family: "League Spartan";

      .ql-editor > p > .mention {
        font-weight: 600;
        color: ${BLUE};
      }
    }

    /* quill editor style */
    .quill > .ql-container > .ql-mention-list-container {
      padding: 16px 0px;
      background-color: ${WHITE};
      border: 1px solid ${LIGHT_GREY};
      font-family: "League Spartan";
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      font-size: 16px;
      border-radius: 12px;

      /* style for user popup */
      ul > li {
        transition: all 250ms;
        background-color: ${WHITE};
        cursor: pointer;
        padding: 6px 8px;
        font-weight: bold;
        color: ${BLACK};
        border-top: 1px solid ${WHITE};
        border-bottom: 1px solid ${WHITE};

        :hover {
          background-color: ${LIGHT_GREY};
          border-top: 1px solid ${GREY};
          border-bottom: 1px solid ${GREY};
        }
      }
    }
  }

  &.files {
    padding: 32px;
    border-bottom: 2px solid ${LIGHT_GREY};
  }

  &.date {
    padding: 32px;
    border-bottom: 2px solid ${LIGHT_GREY};
  }

  &.bottom {
    padding: 32px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .left {
      display: flex;
      gap: 4px;

      & > div {
        cursor: pointer;
        margin: 2px;
        padding: 6px;
        display: flex;
        border-radius: 4px;
        border: 1px solid ${WHITE};

        :hover {
          background-color: ${LIGHT_GREY};
          border: 1px solid ${GREY};
        }

        input {
          &.inputfile {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
          }

          &.inputfile + label {
            font-size: 1.25em;
            font-weight: 700;
            display: inline-block;
            margin: auto;
          }

          &.inputfile + label {
            cursor: pointer; /* "hand" cursor */
          }
        }
      }
    }

    .right {
      display: flex;
      flex-direction: row;
      justify-content: end;

      & > button {
        padding: 8px 16px;
        border-radius: 8px;
        font-weight: bold;
        background-color: ${BLUE};
        color: ${WHITE};
        font-size: 18px;
        cursor: pointer;
        border: 1px solid ${BLUE};
        transition: 250ms;

        & .rotating {
          @keyframes rotating {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }
          animation: rotating 2s linear infinite;
        }

        :hover {
          background-color: ${WHITE};
          color: ${BLUE};
          border: 1px solid ${BLUE};
        }
      }

      & > button:disabled {
        cursor: not-allowed;
        background-color: ${CLEAR_GREY};
        color: ${GREY};
        border: 1px solid ${CLEAR_GREY};
      }
    }
  }
`;
