import styled from "styled-components";
import { BLACK } from "../constants/cts_colors";

const SectionDivider = () => {
  return (
    <SectionDividerStyles className="pdf-component"></SectionDividerStyles>
  );
};

export default SectionDivider;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const SectionDividerStyles = styled.hr`
  margin: 32px 0px;
  height: 1px;
  width: 100%;
  border-top: 1px solid ${BLACK};
`;
