import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { LIGHT_GREY, GREY, WHITE, BLUE } from "../constants/cts_colors";
import SearchAndAddContainer from "./SearchAndAddContainer";
import { IMembersInfos } from "../interfaces/members";
import { useTranslation } from "react-i18next";
import Button from "./Button";
import { ImCross } from "react-icons/im";
import GroupLine from "./GroupLine";
import { IGroupsInfos } from "../interfaces/groups";
import { useGroups } from "../common/contexts/groupContext";
import { useMembers } from "../common/contexts/memberContext";
import { IoAddCircle } from "react-icons/io5";
import MemberPill from "./MemberPill";
import LinkPill from "./LinkPill";
import { IFormationsInfos } from "../interfaces/formations";
import { useFormations } from "../common/contexts/formationContext";

const SettingsGroups = ({}: {}) => {
  const { t } = useTranslation();

  const initialGroupsFormState = {
    name: "",
    members: "",
    formations: "",
  };

  const [noResultsSentence, _setNoResultsSentence] = useState<string>("");

  const [idUpdateGroup, _setIdUpdateGroup] = useState<number>(0);

  const { onGetAllMembers, onGetMember } = useMembers();
  const [allMembers, _setAllMembers] = useState<Array<IMembersInfos>>([]);
  const [selectedMembers, _setSelectedMembers] = useState<Array<IMembersInfos>>(
    []
  );

  const { onGetAllFormations, onGetFormation } = useFormations();
  const [allFormations, _setAllFormations] = useState<Array<IFormationsInfos>>(
    []
  );
  const [selectedFormations, _setSelectedFormations] = useState<
    Array<IFormationsInfos>
  >([]);
  const [formationsIdsTable, _setFormationsIdsTable] = useState<Array<number>>(
    []
  );
  const [actualSelectedFormation, _setActualSelectedFormation] =
    useState<number>(0);

  const [membersIdsTable, _setMembersIdsTable] = useState<Array<number>>([]);

  const [actualSelectedMemberId, _setActualSelectedMemberId] =
    useState<number>(0);

  const [isOperationPopupOpen, _setOperationPopupOpen] =
    useState<boolean>(true);

  const [isAccessPopupOpen, _setIsAccessPopupOpen] = useState<boolean>(false);

  const {
    onCreateGroup,
    onGetAllGroups,
    onGetGroup,
    onDeleteGroup,
    onGetSearchGroups,
    onUpdateGroup,
  } = useGroups();

  // ***** GROUPS *****
  const [groupFormValues, _setGroupFormValues] = useState(
    initialGroupsFormState
  );

  const [groups, _setGroups] = useState<Array<IGroupsInfos>>([]);

  const refCreationGroupsPopup = useRef<HTMLDivElement>(null);
  const refAccessGroupsPopup = useRef<HTMLDivElement>(null);

  const [isCreationGroupsPopupOpen, _setIsCreationGroupsPopupOpen] =
    useState<boolean>(false);
  const [isUpdateGroupsPopupOpen, _setIsUpdateGroupsPopupOpen] =
    useState<boolean>(false);

  // ***** GROUPS FUNCTIONS *****
  // GROUP CHANGE
  const handleGroupChange = (e: any) => {
    // console.log(e);
    const { name, value } = e.target;
    _setGroupFormValues({
      ...groupFormValues,
      [name]: value,
    });
  };

  // DROPDOWN CHANGE
  const handleMemberDropdownChange = (e: any) => {
    const { name, value } = e.target;
    _setActualSelectedMemberId(value);
  };

  // ADD MEMBER TO SELECTED MEMBERS FOR CREATION
  const addMemberToSelectedMembers = async (e: any) => {
    // CHECK if actualSelected is already inside the selectedMembers
    // console.log("ADDED ID : ", actualSelectedMemberId);

    await onGetMember(actualSelectedMemberId)
      .then((response) => {
        console.log(response);
        _setSelectedMembers([...selectedMembers, response]);
        _setMembersIdsTable([...membersIdsTable, actualSelectedMemberId]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // REMOVE MEMBER TO SELECTED MEMBERS
  const removeMemberToSelectedMembers = (key: number) => {
    // console.log("KEY : ", key);
    // console.log("REMOVED ID : ", selectedMembers[key]);

    selectedMembers.splice(key, 1);
    _setSelectedMembers([...selectedMembers]);

    membersIdsTable.splice(key, 1);
    _setMembersIdsTable([...membersIdsTable]);

    // console.log("FINAL MEMBER ID TABLE : ", membersIdsTable);
    // console.log("FINAL SELECTED MEMBERS : ", selectedMembers);
  };

  // GROUP SUBMIT
  const handleGroupSubmit = (e: React.FormEvent) => {
    // _setGroupFormValues(initialGroupsFormState);
    // _setIsCreationGroupsPopupOpen(false);
    // _setIsUpdateGroupsPopupOpen(false);
    // _setSelectedMembers([]);
    // _setMembersIdsTable([]);
    // _setActualSelectedMemberId(0);
    e.preventDefault();

    if (isCreationGroupsPopupOpen) {
      onCreateGroup({
        name: groupFormValues.name,
        members: membersIdsTable,
        formations: "",
      })
        .then(() => {
          _setGroupFormValues(initialGroupsFormState);
          _setIsCreationGroupsPopupOpen(false);
          _setIsUpdateGroupsPopupOpen(false);
          _setSelectedMembers([]);
          _setMembersIdsTable([]);
          _setActualSelectedMemberId(0);
          onGetAllGroups()
            .then((response: any) => {
              _setGroups(response);
            })
            .catch((error: any) => alert(error));
        })
        .catch((error) => alert(error));
    } else {
      // console.log(" update id : ", idUpdateGroup);
      onUpdateGroup({
        id: idUpdateGroup,
        name: groupFormValues.name,
        members: membersIdsTable,
      })
        .then(() => {
          _setGroupFormValues(initialGroupsFormState);
          _setIsCreationGroupsPopupOpen(false);
          _setIsUpdateGroupsPopupOpen(false);
          _setSelectedMembers([]);
          _setMembersIdsTable([]);
          _setActualSelectedMemberId(0);
          onGetAllGroups()
            .then((response: any) => {
              _setGroups(response);
            })
            .catch((error: any) => alert(error));
        })
        .catch((error) => alert(error));
    }
  };

  // GROUP GET VALUES
  const getGroupValues = async (id: number) => {
    // console.log("GROUP VALUES FOR ", id);
    onGetGroup(id)
      .then(async (response) => {
        _setGroupFormValues(response);
        _setIdUpdateGroup(response.id);
        // console.log("ID UPDATE GROUP", idUpdateGroup);

        if (response.members && response.members.length !== 0) {
          for (let i = 0; i < response.members.length; i++) {
            await onGetMember(response.members[i])
              .then((response) => {
                // console.log("response : ", response);
                selectedMembers.push(response);
                membersIdsTable.push(response.id);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }

        if (response.formations && response.formations.length !== 0) {
          for (let i = 0; i < response.formations.length; i++) {
            await onGetFormation(response.formations[i])
              .then((response) => {
                selectedFormations.push(response);
                formationsIdsTable.push(response.id);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  // ACCESS
  // ACCESS CHANGE
  const handleAccessFormationDropdownChange = (e: any) => {
    const { name, value } = e.target;
    _setActualSelectedFormation(value);
  };

  // REMOVE MEMBER TO SELECTED MEMBERS
  const removeFormation = (key: number) => {
    // console.log("KEY : ", key);
    // console.log("REMOVED ID : ", selectedMembers[key]);

    selectedFormations.splice(key, 1);
    _setSelectedFormations([...selectedFormations]);

    formationsIdsTable.splice(key, 1);
    _setFormationsIdsTable([...formationsIdsTable]);

    // console.log("FINAL MEMBER ID TABLE : ", membersIdsTable);
    // console.log("FINAL SELECTED MEMBERS : ", selectedMembers);
  };

  // ADD MEMBER TO SELECTED MEMBERS FOR CREATION
  const addLinkToSelectedFormations = (e: any) => {
    // CHECK if actualSelected is already inside the selectedMembers
    // console.log("ADDED ID : ", actualSelectedMemberId);

    onGetFormation(actualSelectedFormation)
      .then((response) => {
        _setSelectedFormations([...selectedFormations, response]);
        _setFormationsIdsTable([
          ...formationsIdsTable,
          actualSelectedFormation,
        ]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // GROUP SUBMIT
  const handleAccessGroupSubmit = (e: React.FormEvent) => {
    _setIsAccessPopupOpen(false);
    _setSelectedFormations([]);
    _setFormationsIdsTable([]);
    _setActualSelectedFormation(0);
    e.preventDefault();

    if (idUpdateGroup) {
      onGetGroup(idUpdateGroup).then((response) => {
        onUpdateGroup({
          id: idUpdateGroup,
          name: response.name,
          members: response.members,
          formations: formationsIdsTable,
        })
          .then(() => {
            _setIsAccessPopupOpen(false);
            _setSelectedFormations([]);
            _setFormationsIdsTable([]);
            _setActualSelectedFormation(0);
            onGetAllGroups()
              .then((response: any) => {
                _setGroups(response);
              })
              .catch((error: any) => alert(error));
            //alert(t("groups.accessAdded", { ns: "settingsPage" }));
          })
          .catch((error) => alert(error));
      });
    }
  };

  useEffect(() => {
    _setActualSelectedMemberId(0);
    _setGroupFormValues(initialGroupsFormState);
    _setSelectedMembers([]);
    _setMembersIdsTable([]);
  }, [isCreationGroupsPopupOpen]);

  useEffect(() => {
    onGetAllGroups()
      .then((response: any) => {
        _setGroups(response);
      })
      .catch((error: any) => alert(error));

    onGetAllMembers()
      .then((response: any) => {
        _setAllMembers(response);
      })
      .catch((error: any) => alert(error));

    onGetAllFormations()
      .then((response: any) => {
        _setAllFormations(response);
      })
      .catch((error: any) => alert(error));
  }, []);

  // USE EFFECT FOR CLICK OUTSIDE OF GROUP POPUP
  useEffect(() => {
    function handleClickGroupsOutside(event: any) {
      if (
        refCreationGroupsPopup.current &&
        !refCreationGroupsPopup.current.contains(event.target)
      ) {
        _setIsCreationGroupsPopupOpen(false);
        _setIsUpdateGroupsPopupOpen(false);
        _setGroupFormValues(initialGroupsFormState);
        _setActualSelectedMemberId(0);
        _setMembersIdsTable([]);
        _setSelectedMembers([]);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickGroupsOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickGroupsOutside);
    };
  }, [refCreationGroupsPopup]);

  // USE EFFECT FOR CLICK OUTSIDE OF GROUP POPUP
  useEffect(() => {
    function handleClickAccessGroupsOutside(event: any) {
      if (
        refAccessGroupsPopup.current &&
        !refAccessGroupsPopup.current.contains(event.target)
      ) {
        // _setAccessGroupFormValues(initialAccessGroupFormState);
        _setIsAccessPopupOpen(false);
        // _setIdUpdateGroup(undefined);
        _setFormationsIdsTable([]);
        _setSelectedFormations([]);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickAccessGroupsOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickAccessGroupsOutside);
    };
  }, [refAccessGroupsPopup]);

  // HANDLE DELETE GROUP
  const handleDeleteGroup = (id: number, name: string) => {
    if (
      confirm(t("groups.confirmDelete", { ns: "settingsPage" }) + name + " ? ")
    ) {
      _setOperationPopupOpen(false);
      onDeleteGroup(id)
        .then((response) => {
          onGetAllGroups()
            .then((response: any) => {
              _setGroups(response);
              _setOperationPopupOpen(true);
            })
            .catch((error: any) => alert(error));
        })
        .catch((error) => {
          console.log(error);
        });
      // _setIsOperationsOpen(false);
    }
  };

  //onChange FOR SEARCH INPUT
  const handleChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    // console.log("SEARCH INPUT CHANGED ", value);

    if (value === "") {
      _setNoResultsSentence("");
      onGetAllGroups().then((response) => {
        _setGroups(response);
      });
    } else {
      onGetSearchGroups(value)
        .then((response) => {
          // console.log(response);
          if (response.length === 0) {
            _setNoResultsSentence(t("noResults", { ns: "search" }).toString());
            _setGroups(response);
          } else {
            _setGroups(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <SettingsGroupsStyle>
      <SearchAndAddContainer
        onChange={handleChangeSearchInput}
        onClick={() => {
          _setActualSelectedMemberId(0);
          _setGroupFormValues(initialGroupsFormState);
          _setSelectedMembers([]);
          _setMembersIdsTable([]);
          _setIsCreationGroupsPopupOpen(true);
        }}
        showBtOptions={false}
      />
      {/* HEADER OF GROUPS */}
      <div
        className="mx-28 my-4"
        style={{ borderColor: LIGHT_GREY, borderBottomWidth: 1 }}
      >
        <div
          className="flex basis-12 items-center pt-3 pb-6"
          style={{
            color: GREY,
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          {/*<div className="h-full basis-1/12 flex items-center justify-center">
            <CheckboxCustom className="" type="checkbox"></CheckboxCustom>
        </div>*/}
          <p className="basis-3/12">
            {t("groups.name", { ns: "settingsPage" })}
          </p>
          <p className="basis-3/12">
            {t("groups.nbMembers", { ns: "settingsPage" })}
          </p>
          <p className="basis-3/12">
            {t("groups.nbFormations", { ns: "settingsPage" })}
          </p>
          <p className="basis-5/12 flex justify-center">
            {t("groups.activity", { ns: "settingsPage" })}
          </p>
        </div>
      </div>

      <div className="overflow-scroll" style={{ height: "68vh" }}>
        {/* LIST OF GROUPS */}
        {groups &&
          groups.map((group, key) => {
            return (
              <GroupLine
                popupOperations={isOperationPopupOpen}
                key={key}
                group={group}
                numberOfMembers={group.members.length}
                numberOfFormations={(group.formations || []).length}
                onEdit={() => {
                  getGroupValues(group.id);
                  _setIdUpdateGroup(group.id);
                  // console.log("ID UPDATE GROUP", idUpdateGroup);
                  _setIsUpdateGroupsPopupOpen(true);
                }}
                onDelete={() => {
                  handleDeleteGroup(group.id, group.name);
                }}
                onAccess={() => {
                  getGroupValues(group.id);
                  _setIdUpdateGroup(group.id);
                  // console.log("ID UPDATE GROUP", idUpdateGroup);
                  _setIsAccessPopupOpen(true);
                }}
              />
            );
          })}
        {groups && groups.length < 1 && (
          <NoContentSentence>
            {t("groups.noGroups", { ns: "settingsPage" })}
          </NoContentSentence>
        )}
      </div>

      {/* FORM CREATION FORM GROUPS */}
      <CreationPopupContent
        style={{
          display:
            isCreationGroupsPopupOpen || isUpdateGroupsPopupOpen
              ? "block"
              : "none",
        }}
      >
        <div
          className="bg-white max-w-fit mx-auto my-36 py-12 px-16"
          ref={refCreationGroupsPopup}
        >
          <form onSubmit={handleGroupSubmit}>
            <h3>
              {isUpdateGroupsPopupOpen &&
                t("form.nameGroupsUpdate", { ns: "settingsPage" })}
              {isCreationGroupsPopupOpen &&
                t("form.nameGroupsCreation", { ns: "settingsPage" })}
            </h3>

            {/* NAME */}
            <InputWithSubtitle>
              <label htmlFor="name" className="mt-4 mb-1">
                {t("form.placeholders.name", {
                  ns: "settingsPage",
                })}
              </label>
              <FormInput
                type="text"
                id="name"
                name="name"
                placeholder={
                  "" + t("form.placeholders.name", { ns: "settingsPage" })
                }
                required
                onChange={handleGroupChange}
                defaultValue={groupFormValues.name}
              />
            </InputWithSubtitle>

            {/* MEMBERS */}
            <InputWithSubtitle>
              <label htmlFor="members" className="mt-4 mb-1">
                {t("form.placeholders.members", {
                  ns: "settingsPage",
                })}
              </label>
              <div className="flex items-center">
                <select
                  id="members"
                  name="members"
                  onChange={handleMemberDropdownChange}
                  defaultValue={""}
                >
                  <option value={""}></option>
                  {allMembers &&
                    allMembers
                      .filter(
                        (f) => !selectedMembers.find((fs) => fs.id === f.id)
                      )
                      .map((oneMember, key) => {
                        return (
                          <option key={key} value={oneMember.id}>
                            {oneMember.firstName} {oneMember.lastName}
                          </option>
                        );
                      })}
                </select>
                <div
                  onClick={
                    actualSelectedMemberId
                      ? addMemberToSelectedMembers
                      : undefined
                  }
                  className="m-3"
                  style={{ cursor: actualSelectedMemberId ? "pointer" : "" }}
                >
                  <IoAddCircle
                    color={actualSelectedMemberId ? BLUE : GREY}
                    size={24}
                  />
                </div>
              </div>
            </InputWithSubtitle>

            {/* SELECTED MEMBERS */}
            <div
              className="my-4 flex gap-2 flex-wrap overflow-scroll overflow-visible	"
              style={{ maxWidth: "450px", maxHeight: "125px" }}
            >
              {selectedMembers && selectedMembers.length < 1 && (
                <p className="text-center">
                  {t("form.labels.noMember", { ns: "settingsPage" })}
                </p>
              )}
              {selectedMembers &&
                selectedMembers.map((memberId, key) => {
                  // console.log(membersIdsTable[key]);
                  return (
                    <MemberPill
                      key={key}
                      member={memberId}
                      onClick={() => {
                        removeMemberToSelectedMembers(key);
                      }}
                    />
                  );
                })}
            </div>

            {/* BUTTONS */}
            <div className="mt-4 flex justify-center">
              <Button
                disabled={membersIdsTable.length < 1}
                text={
                  isUpdateGroupsPopupOpen
                    ? t("form.buttonUpdate", { ns: "settingsPage" })
                    : t("form.button", { ns: "settingsPage" })
                }
              />
            </div>
            <CrossBtn
              className="cursor-pointer mt-4 mr-4"
              onClick={() => {
                _setIsCreationGroupsPopupOpen(false);
                _setIsUpdateGroupsPopupOpen(false);
                _setGroupFormValues(initialGroupsFormState);
                _setSelectedMembers([]);
                _setMembersIdsTable([]);
              }}
            >
              <ImCross size={20} />
            </CrossBtn>
          </form>
        </div>
      </CreationPopupContent>

      {/* ACCESS FORM */}
      <CreationPopupContent
        style={{
          display: isAccessPopupOpen ? "block" : "none",
        }}
      >
        <div
          className="bg-white max-w-fit mx-auto my-36 py-12 px-16"
          ref={refAccessGroupsPopup}
        >
          <form onSubmit={handleAccessGroupSubmit}>
            <h3>{t("form.addAccess", { ns: "settingsPage" })}</h3>

            {/* LINKS FORMATIONS */}
            <InputWithSubtitle>
              <label htmlFor="links" className="mt-4 mb-1">
                {t("form.placeholders.links", {
                  ns: "settingsPage",
                })}
              </label>
              <div className="flex items-center">
                <select
                  id="links"
                  name="links"
                  onChange={handleAccessFormationDropdownChange}
                  defaultValue={""}
                >
                  <option value={""}></option>
                  {allFormations &&
                    allFormations
                      .filter(
                        (f) => !selectedFormations.find((fs) => fs.id === f.id)
                      )
                      .map((oneFormation, key) => {
                        return (
                          <option key={key} value={oneFormation.id}>
                            {oneFormation.title}
                          </option>
                        );
                      })}
                </select>
                <div
                  onClick={
                    actualSelectedFormation
                      ? addLinkToSelectedFormations
                      : undefined
                  }
                  className="m-3"
                  style={{
                    cursor: actualSelectedFormation ? "pointer" : "",
                  }}
                >
                  <IoAddCircle
                    color={actualSelectedFormation ? BLUE : GREY}
                    size={24}
                  />
                </div>
              </div>
            </InputWithSubtitle>

            {/* SELECTED FORMATIONS */}
            <div
              className="my-4 flex gap-2 flex-wrap overflow-scroll overflow-visible	"
              style={{ maxWidth: "450px", maxHeight: "125px" }}
            >
              {selectedFormations && selectedFormations.length < 1 && (
                <p className="text-center">Aucun liens.</p>
              )}
              {selectedFormations &&
                selectedFormations.map((formation, key) => {
                  return (
                    <LinkPill
                      key={key}
                      formation={formation}
                      onClick={() => {
                        removeFormation(key);
                      }}
                    />
                  );
                })}
            </div>

            {/* BUTTONS */}
            <div className="mt-4 flex justify-center">
              <Button
                disabled={selectedFormations.length < 1}
                text={t("form.addAccess", { ns: "settingsPage" })}
              />
            </div>
            <CrossBtn
              className="cursor-pointer mt-4 mr-4"
              onClick={() => {
                _setIsAccessPopupOpen(false);
              }}
            >
              <ImCross size={20} />
            </CrossBtn>
          </form>
        </div>
      </CreationPopupContent>
    </SettingsGroupsStyle>
  );
};

export default SettingsGroups;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const SettingsGroupsStyle = styled.div`
  width: 100%;
`;

const CheckboxCustom = styled.input`
  width: 16px;
  height: 16px;
  border: 1px solid black;
  background-color: white;
`;

const NoContentSentence = styled.div`
  width: 100%;
  text-align: center;
  color: ${GREY};
`;

const CreationPopupContent = styled.div`
  z-index: 20;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(186, 186, 186, 0.666);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > div {
    box-shadow: 0px 15px 35px 0px rgba(60, 66, 87, 0.08),
      0px 5px 15px 0px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    position: relative;
  }

  & > div > form > h3 {
    font-size: 24px;
    font-weight: bold;
  }
`;

const InputWithSubtitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > div > select {
    padding: 10px 12px 10px 16px;
    border-radius: 4px;
    background: ${WHITE};
    border-radius: 5px;
    border: 1px solid ${LIGHT_GREY};
    width: 400px;
  }
`;

const FormInput = styled.input`
  display: flex;
  padding: 10px 12px 10px 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  background: ${WHITE};
  border-radius: 5px;
  border: 1px solid ${LIGHT_GREY};
  width: 400px;
`;

const CrossBtn = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  color: ${LIGHT_GREY};

  :hover {
    color: ${GREY};
  }
`;
