import React from "react";
import styled from "styled-components";
import { BLACK, WHITE } from "../constants/cts_colors";
import { MOBILE_WIDTH } from "../constants/cts_sizes";
import { Link } from "react-router-dom";
import { getPageUrl } from "../locales/i18n";
import PATH from "../constants/cts_routes";
const PassButton = ({
  text,
  onClick,
}: {
  text: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}) => {
  return (
    <PassButtonStyles onClick={onClick}>
      <Link
        to={{
          pathname: getPageUrl(PATH.home),
        }}
      >
        {text}
      </Link>
    </PassButtonStyles>
  );
};

export default PassButton;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/
const PassButtonStyles = styled.button`
  color: ${BLACK};
  text-underline-offset: 0.15em;
  text-decoration: underline 0.1em rgba(0, 0, 0, 0);
  transition: all 250ms;

  @media (max-width: ${MOBILE_WIDTH}px) {
    color: ${WHITE};
  }

  :hover {
    text-decoration-color: rgba(0, 0, 0, 1);

    @media (max-width: ${MOBILE_WIDTH}px) {
      text-decoration-color: rgba(255, 255, 255, 1);
    }
  }
`;
