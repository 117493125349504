import React, { useState, useCallback, createContext } from "react";
import {
  onCreatePostApi,
  onDeletePostApi,
  onGetAllPodcastsApi,
  onGetAllResourcesApi,
  onGetAllPostsApi,
  onGetAllEventsApi,
  onGetPostApi,
  onUpdatePostApi,
  onGetRecentsPodcastsApi,
  onGetRecentsResourcesApi,
  onGetLastPostApi,
  joinPostApi,
  exitPostApi,
} from "../../api/post.api";
import { IPostsContext, IPostsInfos } from "../../interfaces/posts";

const PostsContext = createContext(null);

export function PostsProvider(props: any) {
  const [isLoading, _setIsLoading] = useState(false);

  const onCreatePost = useCallback(
    ({
      user_id,
      title,
      content,
      content_details,
      files,
      type,
      tags = [],
      date_to_publish,
      intra_company,
      event_date,
      nb_max_subscriptions,
      groupId,
    }: IPostsInfos) => {
      // if (!checkEmptyInput(content)) {
      //   return new Promise((resolve, reject) => {
      //     reject(t(`form.${EMPTY_POST_CONTENT}`, { ns: "errors" }));
      //   });
      // }

      // console.log("POST CTX ", intra_company);

      _setIsLoading(true);
      return onCreatePostApi({
        user_id,
        title,
        content,
        content_details,
        files,
        type,
        tags,
        date_to_publish,
        event_date,
        nb_max_subscriptions,
        groupId,
        intra_company,
      })
        .then((response: any) => {
          _setIsLoading(false);
          return response;
        })
        .catch((error: any) => {
          if (error.response) {
            throw new Error(error.response.data);
          } else {
            throw new Error(error.message);
          }
        })
        .then((response: any) => {
          _setIsLoading(false);
          return response;
        });
    },
    []
  );

  const onGetAllPosts = useCallback((groupId = null) => {
    _setIsLoading(true);
    return onGetAllPostsApi(groupId)
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      })
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      });
  }, []);

  const onGetAllResources = useCallback(() => {
    _setIsLoading(true);
    return onGetAllResourcesApi()
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      })
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      });
  }, []);

  const onGetAllPodcasts = useCallback(() => {
    _setIsLoading(true);
    return onGetAllPodcastsApi()
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      })
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      });
  }, []);

  const onGetAllEvents = useCallback(() => {
    _setIsLoading(true);
    return onGetAllEventsApi()
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      })
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      });
  }, []);

  const onGetRecentsPodcasts = useCallback(() => {
    _setIsLoading(true);
    return onGetRecentsPodcastsApi()
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      })
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      });
  }, []);

  const onGetRecentsResource = useCallback(() => {
    _setIsLoading(true);
    return onGetRecentsResourcesApi()
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      })
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      });
  }, []);

  const onGetPost = useCallback((id: number) => {
    _setIsLoading(true);
    return onGetPostApi(id)
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  const onGetLastPost = useCallback(() => {
    _setIsLoading(true);
    return onGetLastPostApi()
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  const joinPost = useCallback((postId: number) => {
    _setIsLoading(true);
    return joinPostApi(postId)
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  const exitPost = useCallback((postId: number) => {
    _setIsLoading(true);
    return exitPostApi(postId)
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  const onUpdatePost = useCallback(
    ({
      id,
      user_id,
      title,
      content,
      content_details,
      files,
      type,
      tags,
      date_to_publish,
      intra_company,
      nb_max_subscriptions,
      event_date,
    }: {
      id: number;
      user_id: number;
      title: string;
      content: string;
      content_details?: string;
      files: Array<any>;
      type: string;
      tags: Array<number>;
      date_to_publish: Date;
      intra_company: number;
      event_date?: Date;
      nb_max_subscriptions?: number;
    }) => {
      _setIsLoading(true);
      return onUpdatePostApi({
        id,
        user_id,
        title,
        content,
        content_details,
        files,
        type,
        tags,
        date_to_publish,
        intra_company,
        event_date,
        nb_max_subscriptions,
      })
        .then((response: any) => {
          _setIsLoading(false);
          return response;
        })
        .catch((error: any) => {
          if (error.response) {
            throw new Error(error.message.data);
          } else {
            throw new Error(error.message);
          }
        });
    },
    []
  );

  const onDeletePost = useCallback((id: number) => {
    _setIsLoading(true);
    return onDeletePostApi(id)
      .then((response: any) => {
        _setIsLoading(false);
        return response;
      })
      .catch((error: any) => {
        if (error.response) {
          throw new Error(error.message.data);
        } else {
          throw new Error(error.message);
        }
      });
  }, []);

  return (
    <PostsContext.Provider
      {...props}
      value={{
        isLoading,
        // function
        onCreatePost,
        onGetAllPosts,
        onGetLastPost,
        onGetRecentsPodcasts,
        onGetRecentsResource,
        onGetAllPodcasts,
        onGetAllEvents,
        onGetAllResources,
        onGetPost,
        onUpdatePost,
        onDeletePost,
        joinPost,
        exitPost,
      }}
    />
  );
}

export const usePosts = (): IPostsContext => {
  const context = React.useContext(PostsContext);
  if (!context) throw new Error("usePosts must be used in PostProvider");

  return context;
};
