import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { BLACK, BLUE, LIGHT_GREY, WHITE } from "../../constants/cts_colors";
import { IUserSettingInfos } from "../../interfaces/settings";
import { useSettings } from "../../common/contexts/settingsContext";

const AccountSettings = () => {
  const { t } = useTranslation();
  const [userSettings, _setUserSettings] = useState<IUserSettingInfos[]>([]);
  const { onGetMySettings, onToggleMySetting } = useSettings();

  // LOAD SETTINGS
  const loadSetting = useCallback(() => {
    onGetMySettings()
      .then((response) => _setUserSettings(response))
      .catch((error) => console.error(error));
  }, [onGetMySettings]);

  // GET THE SETTINGS
  useEffect(() => {
    loadSetting();
  }, [loadSetting]);

  const toggleUserSetting = (userSettingId: number) => {
    onToggleMySetting(userSettingId)
      .then(() => loadSetting())
      .catch((error) => console.error(error));
  };

  return (
    <Wrapper>
      <Setting>
        <SettingTitle>{t(`title`, { ns: "accountSettingsPage" })}</SettingTitle>
        {userSettings.length > 0 &&
          userSettings.map((userSetting) => (
            <SettingInfos key={"user-setting-" + userSetting.id}>
              <SettingName>
                {t(`settings.${userSetting.setting.name}.name`, {
                  ns: "accountSettingsPage",
                })}
              </SettingName>
              <SwitchButton>
                <input
                  type="checkbox"
                  checked={userSetting.isActive}
                  onChange={() => toggleUserSetting(userSetting.id)}
                />
                <span className="slider"></span>
              </SwitchButton>
            </SettingInfos>
          ))}
      </Setting>
    </Wrapper>
  );
};

export default AccountSettings;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div`
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Setting = styled.div`
  width: 85%;
  margin: 0px auto;
  background-color: ${WHITE};
  padding: 40px;
  border: 1px solid ${BLUE};
  border-radius: 8px;
`;

const SettingTitle = styled.h3`
  font-size: 24px;
  font-weight: 600;
  color: ${BLACK};
  margin-bottom: 56px;
`;

const SettingInfos = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
`;

const SettingName = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${BLACK};
`;

const SwitchButton = styled.label`
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: ${BLUE};
    }

    &:focus + .slider {
      box-shadow: 0 0 1px ${BLUE};
    }

    &:checked + .slider:before {
      -webkit-transform: translateX(24px);
      -ms-transform: translateX(24px);
      transform: translateX(24px);
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${LIGHT_GREY};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;

    &:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 2px;
      bottom: 2px;
      background-color: ${WHITE};
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }
  }
`;
