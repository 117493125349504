import React, { ChangeEventHandler, ReactElement } from "react";
import Input from "./Input";
import styled from "styled-components";
import { BLUE } from "../../constants/cts_colors";

const InputWithLabel = ({
  label,
  type,
  placeholder,
  icon,
  name,
  value,
  disabled,
  error,
  onChange,
  onClickIcon,
}: {
  label: string;
  type?: string;
  placeholder: string;
  icon?: ReactElement;
  name?: string;
  value?: string | number;
  disabled?: boolean;
  error?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onClickIcon?: Function;
}) => {
  return (
    <Container>
      <Label>{label}</Label>
      <Input
        type={type}
        icon={icon}
        placeholder={placeholder}
        onChange={onChange}
        name={name}
        value={value}
        onClickIcon={onClickIcon}
        disabled={disabled}
        error={error}
      />
    </Container>
  );
};

export default InputWithLabel;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled.label`
  font-size: 16px;
  font-weight: bold;
  color: ${BLUE};
`;
