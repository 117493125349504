export function beautifulDate(d: Date | string) {
  d = new Date(d);

  return `${getDayString(d)} ${d.getDate()} ${getMonthString(
    d
  )} ${d.getFullYear()} à ${dateToHoursString(d, "h")}`;
}

export function fullDateString(d: Date, lang: string = "fr") {
  d = new Date(d);

  if (lang === "en") {
    return `${getMonthString(d, lang)} 
    ${getDayString(d, lang)} 
    ${d.getDate()}  
    ${d.getFullYear()} • ${dateToHoursString(d, ":", lang)}`;
  }

  return `${getDayString(d)} ${d.getDate()} ${getMonthString(
    d
  )} ${d.getFullYear()} • ${dateToHoursString(d, "h", lang)}`;
}

export function getDayString(date: Date, lang: string = "fr") {
  switch (date.getDay()) {
    case 1:
      return lang === "fr" ? "Lundi" : "Monday";
    case 2:
      return lang === "fr" ? "Mardi" : "Thuesday";
    case 3:
      return lang === "fr" ? "Mercredi" : "Wednesday";
    case 4:
      return lang === "fr" ? "Jeudi" : "Thursday";
    case 5:
      return lang === "fr" ? "Vendredi" : "Friday";
    case 6:
      return lang === "fr" ? "Samedi" : "Saturday";
    case 0:
      return lang === "fr" ? "Dimanche" : "Sunday";
  }
  return;
}

export function dateToHoursString(
  d: Date,
  separator = ":",
  lang: string = "fr"
) {
  d = new Date(d);

  if (lang === "en") {
    return `${(d.getHours() > 12
      ? d.getHours() - 12 + ""
      : d.getHours() + ""
    ).padStart(2, "0")}${separator}${(d.getMinutes() + "").padStart(2, "0")} ${
      d.getHours() > 12 ? "PM" : "AM"
    }`;
  }

  return `${(d.getHours() + "").padStart(2, "0")}${separator}${(
    d.getMinutes() + ""
  ).padStart(2, "0")}`;
}

/**
 * Recupération du mois en texte d'une date
 * @param date
 * @returns
 */
export function getMonthString(date: Date | string, lang: string = "fr") {
  if (typeof date === "string") {
    date = new Date(date);
  }

  let frenchMonths = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  let englishMonths = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (lang === "fr" ? frenchMonths : englishMonths)[date.getMonth()];
}
