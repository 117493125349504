import { instanceAxios } from "../utils/axios-api";

export const onCreatePostApi = ({
  user_id,
  title,
  content,
  content_details,
  files,
  type,
  tags,
  date_to_publish,
  intra_company,
  event_date,
  nb_max_subscriptions,
  groupId,
}: {
  user_id: number;
  title: string;
  content: string;
  content_details?: string;
  files: Array<File>;
  type: string;
  tags: Array<number>;
  date_to_publish: Date;
  intra_company: number;
  event_date?: Date;
  nb_max_subscriptions?: number;
  groupId?: number;
}) => {
  const formData = new FormData();
  formData.append("user_id", user_id.toString());
  formData.append("title", title || "");
  formData.append("content", content || "");
  formData.append("content_details", content_details || "");
  formData.append(
    "nb_max_subscriptions",
    nb_max_subscriptions ? nb_max_subscriptions + "" : ""
  );
  formData.append("type", type || "");
  formData.append(
    "date_to_publish",
    (date_to_publish || new Date()).toString()
  );
  formData.append("event_date", (event_date || new Date()).toString());
  formData.append("groupId", groupId ? groupId + "" : "");

  (files || []).map((file) => {
    formData.append("files", file);
  });

  (tags || []).map((tag: any) => {
    formData.append("tags", tag);
  });

  if (intra_company) formData.append("intra_company", intra_company.toString());

  return instanceAxios
    .post("/posts/create-post", formData)
    .then((response) => (response.data ? response.data : null));
};

export const onGetAllPostsApi = (groupId = null) => {
  return instanceAxios
    .post(`/posts/get-all-posts`, {
      groupId,
    })
    .then((response) => (response.data ? response.data.data : null));
};

export const onGetAllEventsApi = () => {
  return instanceAxios
    .get(`/posts/get-all-events`)
    .then((response) => (response.data ? response.data.data : null));
};

export const onGetAllPodcastsApi = () => {
  return instanceAxios
    .get(`/posts/get-all-podcasts`)
    .then((response) => (response.data ? response.data.data : null));
};

export const onGetRecentsPodcastsApi = () => {
  return instanceAxios
    .get(`/posts/get-recents-podcasts`)
    .then((response) => (response.data ? response.data.data : null));
};

export const onGetRecentsResourcesApi = () => {
  return instanceAxios
    .get(`/posts/get-recents-resources`)
    .then((response) => (response.data ? response.data.data : null));
};

export const onGetAllResourcesApi = () => {
  return instanceAxios
    .get(`/posts/get-all-resources`)
    .then((response) => (response.data ? response.data.data : null));
};

export const onGetPostApi = (id: number) => {
  return instanceAxios
    .get(`/posts/unique/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

export const onGetLastPostApi = () => {
  return instanceAxios
    .get(`/posts/get-last-post`)
    .then((response) => (response.data ? response.data.data : null));
};

export const onDeletePostApi = (id: number) => {
  return instanceAxios
    .put(`/posts/delete-post/${id}`)
    .then((response) => (response.data ? response.data.data : null));
};

export const onUpdatePostApi = ({
  id,
  user_id,
  title,
  content,
  content_details,
  files,
  type,
  tags,
  date_to_publish,
  intra_company,
  event_date,
  nb_max_subscriptions,
}: {
  id: number;
  user_id: number;
  title: string;
  content: string;
  content_details?: string;
  files: Array<any>;
  type: string;
  tags: Array<number>;
  date_to_publish: Date;
  intra_company: number;
  event_date?: Date;
  nb_max_subscriptions?: number;
}) => {
  const formData = new FormData();
  formData.append("id", id.toString());
  formData.append("user_id", user_id.toString());
  formData.append("title", title || "");
  formData.append("content", content || "");
  formData.append("content_details", content_details || "");
  formData.append(
    "nb_max_subscriptions",
    nb_max_subscriptions ? nb_max_subscriptions + "" : ""
  );
  formData.append("type", type || "");
  formData.append(
    "date_to_publish",
    (date_to_publish || new Date()).toString()
  );
  formData.append("event_date", (event_date || new Date()).toString());

  (files || []).map((file) => {
    formData.append("files", file.id ? JSON.stringify(file) : file);
  });

  (tags || []).map((tag: any) => {
    formData.append("tags", tag);
  });

  if (intra_company) formData.append("intra_company", intra_company.toString());

  return instanceAxios
    .put("/posts/update-post/" + id, formData)
    .then((response) => (response.data ? response.data.data : null));
};

export const joinPostApi = (postId: number) => {
  return instanceAxios
    .post(`/posts/join`, { postId })
    .then((response) => (response.data ? response.data.data : null));
};

export const exitPostApi = (postId: number) => {
  return instanceAxios
    .post(`/posts/exit`, { postId })
    .then((response) => (response.data ? response.data.data : null));
};
