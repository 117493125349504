import styled from "styled-components";
import { useIa } from "../../common/contexts/iaContext";
import Button from "../../components/Button";
import Input from "../../components/form/Input";
import { useState } from "react";

const IaTrainingNewUserComponent = () => {
  const { iaUser, onAiSignIn } = useIa();
  const [email, setEmail] = useState("");

  const onSent = () => {
    if (!email) {
      alert("vous devez saisir un email !");
      return;
    }
    onAiSignIn(email);
  };

  return (
    <Wrapper>
      <div>
        <h1>Bonjour,</h1>
        <p>Merci de saisir un email :</p>
        <Input
          placeholder="Votre email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <Button text="Envoyer" onClick={onSent} />
      </div>
    </Wrapper>
  );
};

export default IaTrainingNewUserComponent;

/*//////////////////////////////////////////////////////////////////////////
/////////////////////////////// S T Y L E  /////////////////////////////////
//////////////////////////////////////////////////////////////////////////*/

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  > div {
    width: 400px;
  }
`;
